import { css } from '@emotion/react';

export const DashboardTopBarStyles = () =>
  css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
    '.dashboardName': {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      ':hover': { cursor: 'text', textDecoration: 'underline' },
    },
  });

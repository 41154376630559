import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import Box from '../Box';
import Typography from '../Typography';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

type TPieChartDataPoint = {
  label: string;
  value: number;
  color?: string;
};
interface IPieChartProps {
  data: TPieChartDataPoint[];
  title?: string;
  width?: string | number;
  height?: string | number;
  isMoney?: boolean;
  showLegends?: boolean;
  otherOptions?: Omit<ApexOptions, 'title'>;
}

const formatValue = (value: number, isMoney: boolean) => {
  if (isMoney)
    return (
      '$' +
      value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  else return value.toFixed(2);
};

const PieChart: React.FC<IPieChartProps> = ({
  data,
  width = '100%',
  height = '100%',
  title,
  isMoney = true,
  showLegends = true,
  otherOptions,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [chartData, setChartData] = React.useState<{
    options: ApexOptions;
    series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  }>({
    options: {},
    series: [],
  });

  useEffect(() => {
    const getOptionsAndSeries = () => {
      const labels = data.map(item => item.label);
      const chartSeries = data.length > 0 ? data.map(item => item.value) : [0];

      const totalValue = data.reduce((sum, item) => sum + item.value, 0);

      const defaultColors = Object.keys(theme.colors)
        .filter(key => key.includes('darker') || key.includes('semantic'))
        .map(key => theme.colors[key as keyof typeof theme.colors]);

      const colors = data.map((item, index) => {
        if (item.color) return item.color;
        else {
          const absolutIndex =
            index < defaultColors.length
              ? index
              : index % (defaultColors.length - 1);
          return defaultColors[absolutIndex];
        }
      });

      const chartOptions: ApexOptions = {
        labels: labels,
        noData: {
          text: t('loggedIn.dashboardsModule.dataSummaryWidget.noData'),
          style: {
            color: theme.colors.textBlack,
            fontSize: '16px',
            fontFamily: 'Roboto',
          },
          align: 'center',
          verticalAlign: 'middle',
        },

        chart: {
          type: 'donut',
        },
        legend:
          showLegends && data.length > 0
            ? {
                position: 'right',
                markers: {
                  size: 6,
                },
                offsetY: -25,
                offsetX: -30,
                formatter: (seriesName: any, { seriesIndex: index }: any) => {
                  const value = data[index]?.value || 0;
                  const percentage =
                    (data[index]?.value / totalValue) * 100 || 0;
                  return `
              <span style="font-weight: 600; margin-left: 0.5rem;">${seriesName}</span> 
              ${formatValue(value, isMoney)} (${percentage.toFixed(2)}%)
            `;
                },
                fontSize: '16px',
                fontFamily: 'Roboto',
                itemMargin: {
                  vertical: 13,
                  horizontal: 0,
                },
              }
            : { show: false },
        plotOptions: {
          pie: {
            donut: {
              size: '70%',

              labels: {
                show: true,
                name: {
                  show: true,
                  fontWeight: 'bold',
                  fontSize: '16px',
                  fontFamily: 'Roboto',
                },
                total: {
                  show: true,
                  formatter: () => formatValue(totalValue, isMoney),
                  fontSize: '16px',
                  fontFamily: 'Roboto',
                },
                value: {
                  show: true,
                  fontWeight: 'bold',
                  fontSize: '16px',
                  fontFamily: 'Roboto',
                  offsetY: -3,
                  formatter: val => formatValue(Number(val), isMoney),
                },
              },
              background: '#fcfcfc',
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: value => {
              const percentage = ((value / totalValue) * 100).toFixed(2);
              return `${formatValue(value, isMoney)} (${percentage}%)`;
            },
          },
        },
        colors: colors,
      };

      return {
        options: chartOptions,
        series: chartSeries,
      };
    };

    const { options, series } = getOptionsAndSeries();
    setChartData({
      options,
      series,
    });
  }, [data, isMoney, showLegends, theme, t]);

  return (
    <Box
      csx={{
        width,
        height,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}>
      <Box csx={{ flexGrow: 0 }}>
        <Typography color="textBlue" fontWeight="bold" variant="subtitle">
          {title}
        </Typography>
      </Box>
      <Box csx={{ flexGrow: 1 }}>
        <Chart
          options={chartData.options}
          series={chartData.series}
          height={height}
          width={width}
          type="donut"
          {...otherOptions}
        />
      </Box>
    </Box>
  );
};

export default PieChart;

import { RootState } from '../store';

export const selectCurrentDashboard = (state: RootState) =>
  state.home.currentDashboard;
export const selectCurrentEditDashboard = (state: RootState) =>
  state.home.currentEditDashboard;
export const selectDashboardBottomSheetOpen = (state: RootState) =>
  state.home.currentEditDashboard?.isBottomSheetOpen ?? false;
export const selectWidgets = (state: RootState) =>
  state.home.currentEditDashboard?.selectedDashboard.widgets ?? {};

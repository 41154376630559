import { Theme, useTheme } from '@emotion/react';
import { MassUpdateValues } from '@westondev/tableturn-core';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import ReactDOM from 'react-dom';
import Box from '../Box';
import Button from '../Button';
import Icon from '../Icon';
import Input from '../Input';
import ToolTip from '../ToolTip';
import Typography from '../Typography';
import {
  colorPickerContainerStyles,
  colorPickerLabelStyles,
  colorPickerStyles,
  hexInputStyles,
  PickerAndButtonContainerStyles,
} from './styles';
interface IColorPicker {
  color: string;
  onChange?: (color: string) => void;
  label?: string;
  isDisabled?: boolean;
  showHexInput?: boolean;
  onReset?: () => void;
  info?: string;
  showNoChanges?: boolean;
}

const ColorPicker = ({
  color: _color,
  onChange,
  label,
  isDisabled,
  showHexInput,
  onReset,
  info = label || 'TOOLTIP_INFO',
  showNoChanges,
}: IColorPicker) => {
  const theme = useTheme();
  const [isActive, setIsActive] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const [showHover, setShowHover] = useState(false);
  const [newTimeout, setNewTimeout] = useState<NodeJS.Timeout | null>(null);

  const isMassUpdateNoChanges = _color === MassUpdateValues.NO_CHANGES;

  const color = isMassUpdateNoChanges ? '#ffffff' : _color;

  const handleColorChange = (colorResult: ColorResult) => {
    const newColor = colorResult.hex;
    onChange && onChange(newColor);
  };
  const handleOpenOnClick = () => {
    if (isActive) return;
    setIsActive(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;
    if (!newColor.startsWith('#')) {
      onChange && onChange(`#${newColor}`);
      return;
    }
    onChange && onChange(newColor);
  };

  const handlePopUp = () => {
    if (newTimeout) clearTimeout(newTimeout);
    setNewTimeout(
      setTimeout(() => {
        setShowHover(true);
      }, 500),
    );
  };

  return (
    <Box>
      {label && (
        <Box
          csx={{
            display: 'flex',
          }}>
          {info && (
            <div>
              <ToolTip
                showHover={showHover}
                content={info}
                tooltipCsx={{ maxWidth: '250px' }}>
                <div
                  style={{
                    height: '20px',
                  }}
                />
              </ToolTip>
            </div>
          )}
          <Typography
            csx={colorPickerLabelStyles}
            onMouseOver={handlePopUp}
            onMouseOut={() => {
              if (newTimeout) clearTimeout(newTimeout);
              setShowHover(false);
            }}>
            {label}
          </Typography>{' '}
        </Box>
      )}
      <Box
        csx={colorPickerStyles(theme, color, isActive, isMassUpdateNoChanges)}
        onClick={!isDisabled ? handleOpenOnClick : () => null}>
        {isActive &&
          ReactDOM.createPortal(
            <Box
              csx={colorPickerContainerStyles}
              onClick={() => setIsActive(false)}>
              <Box
                csx={PickerAndButtonContainerStyles}
                ref={boxRef}
                onClick={e => e.stopPropagation()}>
                <SketchPicker
                  disableAlpha
                  presetColors={[]}
                  styles={{
                    default: {
                      picker: {
                        width: '100%',
                        maxWidth: '380px',
                      },
                    },
                    disableAlpha: {
                      hue: { height: '15px' },
                      color: { height: '15px' },
                      picker: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        padding: '0px',
                      },
                      saturation: {
                        height: '50px',
                      },
                    },
                  }}
                  color={theme.colors[color as keyof Theme['colors']] || color}
                  onChange={handleColorChange}
                />
                {onReset && (
                  <Box
                    csx={{
                      marginTop: '10px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: '10px',
                    }}>
                    {showNoChanges && (
                      <Button
                        csx={{ width: '100px' }}
                        variant="secondary"
                        onClick={() => {
                          onChange?.(MassUpdateValues.NO_CHANGES);
                          setIsActive(false);
                        }}>
                        {t('commonTexts.noChanges')}
                      </Button>
                    )}
                    <Button
                      title="Reset"
                      csx={{ width: '100px' }}
                      variant="secondary"
                      icon={<Icon name="FaUndo" size="18px" />}
                      iconPosition="left"
                      onClick={() => {
                        onReset();
                        setIsActive(false);
                      }}>
                      {t('commonButtons.reset')}
                    </Button>
                    <Button
                      title="Reset"
                      csx={{ width: '100px' }}
                      variant="primary"
                      onClick={() => {
                        setIsActive(false);
                      }}>
                      {t('commonButtons.done')}
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>,
            document.body,
          )}
      </Box>
      {showHexInput && (
        <Box csx={hexInputStyles}>
          <Input
            placeholder=" "
            value={
              isMassUpdateNoChanges
                ? ''
                : theme.colors[color as keyof Theme['colors']] || color
            }
            onChange={handleInputChange}
            disabled={isDisabled}
          />
        </Box>
      )}
    </Box>
  );
};

export default ColorPicker;

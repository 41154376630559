import { layoutGridStyles } from './styles';

interface ILayoutGrid {
  height: number;
}

const LayoutGrid = ({ height }: ILayoutGrid) => {
  // Redux
  const SQUARE_SIZE_BIG = 100;
  return (
    <>
      <div css={layoutGridStyles(SQUARE_SIZE_BIG, height)} />
    </>
  );
};

export default LayoutGrid;

import { actionCreatorsRegisterWeb } from '@app/state';
import { useAppDispatch } from '@app/state/store';
import {
  IPrintJobFilterParams,
  TPrinterJobRow,
  usePrevious,
} from '@westondev/tableturn-core';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';

const useGetPrinterJobs = (
  printerId: number,
  filterParams: IPrintJobFilterParams,
) => {
  const dispatch = useAppDispatch();
  const [printerJobs, setPrinterJobs] = useState<TPrinterJobRow[]>([]);
  const [_refresh, setRefresh] = useState(0);
  const [page, setPage] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const prevFilterParams = usePrevious(filterParams);
  const prevRefresh = usePrevious(_refresh);

  const canLoadMore = useMemo(
    () => !(totalPages <= page + 1 || !hasFetched || isLoading),
    [totalPages, page, hasFetched, isLoading],
  );

  const fetch = async (loadMore = false) => {
    const paramsHasChanged = !isEqual(filterParams, prevFilterParams);

    const hasRefreshed = prevRefresh !== _refresh;

    if (
      !hasRefreshed &&
      (isLoading ||
        isLoadingMore ||
        (loadMore && !canLoadMore) ||
        (!paramsHasChanged && !loadMore))
    ) {
      return;
    }

    if (!loadMore) {
      setIsLoading(true);
    } else {
      setIsLoadingMore(true);
    }

    const response = await dispatch(
      actionCreatorsRegisterWeb.getPrintJobInfoWeb(
        filterParams,
        printerJobs,
        paramsHasChanged || hasRefreshed ? 0 : page + 1,
        hasRefreshed ? false : loadMore || !paramsHasChanged,
      ),
    );
    if (!loadMore) {
      setIsLoading(false);
    } else {
      setIsLoadingMore(false);
    }
    setHasFetched(true);
    setPage(response.currentPage);
    setTotalPages(response.totalPages);
    setCount(response.total);
    setPrinterJobs(response.printjobs);
  };

  const refresh = useCallback(() => {
    setRefresh(_refresh + 1);
  }, [_refresh]);

  const loadMore = () => fetch(true);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, printerId, _refresh, filterParams]);

  return {
    printerJobs,
    isLoading,
    isLoadingMore,
    refresh,
    canLoadMore,
    totalPages,
    count,
    setCount,
    fetch,
    loadMore,
  };
};

export default useGetPrinterJobs;

import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import OptionsModal from '@app/components/common/OptionsModal';
import Typography from '@app/components/common/Typography';
import { actionCreatorsRegisterWeb } from '@app/state';
import {
  selectHasLoggedInUserAccessToChangePrinterStatus,
  selectHasLoggedInUserAccessToReAssignBackupPrinter,
} from '@app/state/users/usersSelectors';
import { useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  EPrinterStatus,
  IPrinterListElement,
  printerListElementOptionsFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PrinterStatusTag from '../../Settings/DevicesAndLicenses/Devices/PrintersTable/PrinterStatusTag';

const PrinterListElement = ({
  t,
  id,
  name,
  status,
  backupPrinter: backupPrinterId,
  siblings,
  onViewJobsClick,
}: WithTranslation & IPrinterListElement & { onViewJobsClick: () => void }) => {
  // Redux
  const { replaceBackUpPrinterWeb, pauseResumePrinterWeb } = bindActionCreators(
    actionCreatorsRegisterWeb,
    useDispatch(),
  );

  const canReAssignBackupPrinter = useSelector(
    selectHasLoggedInUserAccessToReAssignBackupPrinter,
  );

  const canChangePrinterStatus = useSelector(
    selectHasLoggedInUserAccessToChangePrinterStatus,
  );

  // Local state
  const dropdownData = useMemo(
    () => printerListElementOptionsFactory(siblings, id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [siblings, id, t],
  );

  const isActive = status === EPrinterStatus.ACTIVE;
  const isPaused = status === EPrinterStatus.PAUSED;

  const theme = useTheme();
  const redColor = theme.colors.lightestRed;
  const backgroundColor = !isActive ? redColor : 'transparent';

  const borderColor = theme.colors.lighterGrey;

  const handleOnChange = (newBackupPrinterId: number | null) => {
    replaceBackUpPrinterWeb(id, newBackupPrinterId);
  };

  const handlePauseResume = () => {
    pauseResumePrinterWeb(id, isPaused ? 'resume' : 'pause');
  };

  return (
    <Grid
      rowGap={15}
      csx={{
        padding: '15px',
        backgroundColor,
        borderColor,
      }}>
      <Grid.Item>
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Typography variant="body" fontWeight="medium">
            {name}
          </Typography>
          <PrinterStatusTag status={status} />
        </Box>
      </Grid.Item>
      <Grid.Item
        csx={{
          flexDirection: 'row',
          alignItems: 'flex-end',
          width: '100%',
          gap: '10px',
        }}>
        <Box csx={{ width: 'inherit' }}>
          <Typography csx={{ marginBottom: '5px' }}>
            {t('navBar.common.printerCenter.backupPrinter.title')}
          </Typography>
          <OptionsModal
            noElementMessage={t('navBar.common.printerCenter.noMorePrinters')}
            options={dropdownData}
            placeholder={t(
              'navBar.common.printerCenter.selectBackupPrinterPlaceholder',
            )}
            title={t('navBar.common.printerCenter.selectBackupPrinter')}
            value={backupPrinterId}
            buttonProps={{
              csx: { justifyContent: 'space-between' },
              disabled: !canReAssignBackupPrinter,
            }}
            onChange={handleOnChange}
          />
        </Box>

        {(isPaused || isActive) && (
          <Button
            onClick={handlePauseResume}
            csx={{ maxWidth: '110px' }}
            disabled={!canChangePrinterStatus}
            variant={isPaused ? 'success' : 'danger'}
            icon={
              isPaused ? (
                <Icon name="MdPlayArrow" color="textWhite" />
              ) : (
                <Icon name="MdPause" color="textWhite" />
              )
            }>
            {isPaused
              ? t('navBar.common.printerCenter.printer.resume')
              : t('navBar.common.printerCenter.printer.pause')}
          </Button>
        )}
        <Button
          csx={{ maxWidth: '140px' }}
          variant="primary"
          onClick={onViewJobsClick}
          icon={
            <Box style={{ paddingBottom: 2, position: 'relative' }}>
              <Icon name="MdPrint" color="white" />
              <Icon
                name="MdVisibility"
                size="15px"
                csx={{
                  position: 'absolute',
                  right: -3,
                  bottom: 3,
                  backgroundColor: theme.colors.persistentDarkerBlue,
                  borderRadius: '50%',
                }}
                color="white"
              />
            </Box>
          }>
          {t('navBar.common.printerCenter.printer.viewJobs')}
        </Button>
      </Grid.Item>
    </Grid>
  );
};

export default PrinterListElement;

import Grid from '@app/components/common/Grid';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import {
  areSettingsMasterModeSelector,
  settingsDifferencesSelector,
} from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import { useTheme } from '@emotion/react';
import {
  CoreRootState,
  IHoursOfOperation,
  TChange,
  diffGeneralSettingsFactory,
} from '@westondev/tableturn-core';
import some from 'lodash/some';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import RenderChange from '../common/RenderChange';
import HoursOfOperationTableDiff from './HoursOfOperationTableDiff';

const COLOR_FIELDS = ['backgroundColor', 'textColor'];

const IMAGE_FIELDS = ['digitalLogoUrl', 'printLogoUrl'];

const GeneralSettingsDiffModal = ({ t }: WithTranslation) => {
  // Redux
  const differences = useSelector(settingsDifferencesSelector);
  const isMasterMode = useSelector(areSettingsMasterModeSelector);

  const theme = useTheme();

  const defaultColor = {
    [COLOR_FIELDS[0]]: theme.colors.semanticBlue,
    [COLOR_FIELDS[1]]: theme.colors.textWhite,
  };

  const formattedDifferences = useMemo(() => {
    const diffObject = diffGeneralSettingsFactory(
      differences,
      store.getState() as unknown as CoreRootState,
    );

    return diffObject;
  }, [differences]);

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    if (
      ['timeZone', 'clockInMode', 'pinpadLayout', 'allowUserToBeLoggedIntoMultipleTablets', 'endOfBusinessDay'].includes(
        field,
      )
    ) {
      return textValue;
    }
    return _formatValue(value, field);
  };

  const renderChange = (change: TChange) => {
    if (!change.field) return null;
    return (
      <RenderChange
        change={change}
        formatValue={formatValue}
        colorFields={COLOR_FIELDS}
        defaultColor={defaultColor}
        isImage={IMAGE_FIELDS.includes(change.field)}
      />
    );
  };

  const renderHoursOfOperationTable = (times: IHoursOfOperation) => {
    return (
      <HoursOfOperationTableDiff
        menuTypeRules={times}
        formattedDifferences={formattedDifferences}
      />
    );
  };

  return (
    <>
      {formattedDifferences && (
        <>
          {formattedDifferences.information.length > 0 && (
            <>
              <HeaderDiffModal
                name={t(
                  `app.modals.diffModal.sectionTitles.settings.${
                    isMasterMode
                      ? 'organizationInformation'
                      : 'locationInformation'
                  }`,
                )}
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.information.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`generalSettings_information_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
          {formattedDifferences.general.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.settings.general')}
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.general.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`generalSettings_general_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}

          {(formattedDifferences.hoursOfOperation.changes.length > 0 ||
            some(
              formattedDifferences.hoursOfOperation.hoursOfOperation,
              day => day.length > 0,
            )) && (
            <>
              <HeaderDiffModal
                name={t(
                  'app.modals.diffModal.sectionTitles.settings.hoursOfOperation',
                )}
                color="black"
              />
              {formattedDifferences.hoursOfOperation.changes.length > 0 && (
                <Grid columnGap={20} rowGap={10}>
                  {formattedDifferences.hoursOfOperation.changes.map(change => (
                    <Grid.Item
                      mb={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={`generalSettings_hoursOfOperation_changes_${change.field}`}>
                      {renderChange(change)}
                    </Grid.Item>
                  ))}
                </Grid>
              )}
              {some(
                formattedDifferences.hoursOfOperation.hoursOfOperation,
                day => day.length > 0,
              ) &&
                renderHoursOfOperationTable(
                  formattedDifferences.hoursOfOperation.hoursOfOperation,
                )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default GeneralSettingsDiffModal;

import { usersSelectors } from '@westondev/tableturn-core';

export const {
  selectLoggedInUser,
  selectLoggedInOrganizationUserId,
  selectRecentOrders,
  selectLoggedInRoleId,
  selectLoggedInSettingsAccessLocations,
  selectUsers,
  selectActiveUsers,
  selectLoggedInRole,
  selectHasLoggedInUserAccessToMasterMenu,
  selectHasLoggedInUserAccessToMasterSettings,
  selectHasLoggedInUserAccessToViewPrinterCenter,
  selectHasLoggedInUserAccessToChangePrinterStatus,
  selectHasLoggedInUserAccessToReAssignBackupPrinter,
  selectHasLoggedInUserAccessToDeletePrintJobs,
  selectHasLoggedInUserAccessToTransferPrintJobs,
} = usersSelectors;

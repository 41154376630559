import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Input from '@app/components/common/Input';
import Modal from '@app/components/common/Modal';
import useRootSelector from '@app/hooks/useRootSelector';
import { saveReportsChangesOnSaveClickWeb } from '@app/state/reports/reportsWebActions';
import { useAppDispatch } from '@app/state/store';
import { MQ_MAX_MEDIUM } from '@app/theme/types';
import { actionCreatorsReports } from '@westondev/tableturn-core';
import { useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';

const SaveQuickReport = ({ t }: WithTranslation) => {
  const dispatch = useAppDispatch();
  const setValues = bindActionCreators(
    actionCreatorsReports.handleReportsChange,
    dispatch,
  );
  const currentName = useRootSelector(state => {
    const _stateName = state.reports.changeData?.data?.name;
    return _stateName ?? '';
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        variant="primary"
        csx={{
          width: 174,
          [MQ_MAX_MEDIUM]: {
            width: 60,
            '> span.icon.icon-left': {
              paddingRight: '0px',
              position: 'relative',
            },
          },
        }}
        icon={<Icon name="MdSave" />}>
        Save Report
      </Button>

      <Modal
        size={400}
        isActive={isModalOpen}
        onModalClose={() => setIsModalOpen(false)}
        title="Save Report"
        footer={
          <Button
            onClick={() => {
              dispatch(saveReportsChangesOnSaveClickWeb(true));
              setIsModalOpen(false);
            }}
            variant="primary"
            csx={{
              width: 100,
              [MQ_MAX_MEDIUM]: {
                width: 60,
                '> span.icon.icon-left': {
                  paddingRight: '0px',
                  position: 'relative',
                },
              },
            }}>
            Save
          </Button>
        }>
        <Input
          required
          value={currentName}
          onChange={e =>
            setValues(_values => ({
              ..._values,
              name: e.currentTarget.value,
            }))
          }
          label={t('reports.fields.name')}
          placeholder="Quick Report"
        />
      </Modal>
    </>
  );
};

export default SaveQuickReport;

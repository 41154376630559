import useOutsideClick from '@app/hooks/useOutsideClick';
import { actionCreatorsApp } from '@app/state';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Box from '../../Box';
import Button from '../../Button';
import Icon from '../../Icon';
import { labelStyles } from '../../Input/styles';
import ToolTip from '../../ToolTip';
import Typography from '../../Typography';
import DropdownButton from '../DropdownButton/DropdownButton';
import {
  dropDownContainerStyles,
  dropDownMenuStyles,
  dropdownMenuContentStyles,
} from '../styles';
import { IDropdownItem } from '../types';
import { selectedOptionsStyles } from './styles';
import { TMultiSelectDropdown } from './types';

const MultiSelectDropdown = ({
  placeholder,
  value,
  data,
  returnItemOnChange,
  onChange,
  isDisabled = false,
  label,
  emptyLabel,
  required,
  hideRequiredSymbol,
  info = label || 'TOOLTIP_INFO',
  csx,
  inputContainerCsx: inputContainerProps,
  customTextColor,
  error = false,
  onActive,
  isLoading = false,
  isEditable = true,
  noTooltip,
}: TMultiSelectDropdown) => {
  const dismissOnSelect = false;
  isDisabled = isDisabled || isLoading;

  //Redux
  const updatePortals = bindActionCreators(
    actionCreatorsApp.updatePortals,
    useDispatch(),
  );
  // Local state
  const childrenRef = useRef<HTMLDivElement>(null);
  const dropDownMenuRef = useRef<HTMLDivElement>(null);
  const dropDownContentRef = useRef<HTMLDivElement>(null);
  const [childrenRect, setChildrenRect] = useState<DOMRect | undefined>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<
    Record<number, IDropdownItem>
  >({});
  const [showHover, setShowHover] = useState(false);
  const [newTimeout, setNewTimeout] = useState<NodeJS.Timeout | null>(null);

  useOutsideClick(
    [childrenRef, dropDownMenuRef],
    true,
    (isExpandedValue: boolean) => {
      setIsExpanded(isExpandedValue);
      handleRemovePortal();
    },
  );

  const getDropdownPosition = () => {
    if (childrenRect && dropDownMenuRef.current) {
      const dropDownMenuRect = dropDownMenuRef.current.getBoundingClientRect();
      const dropdownMenuPosition =
        window.scrollY + childrenRect.y + childrenRect.height;
      const isOutsideScreen =
        dropdownMenuPosition + dropDownMenuRect.height >
        window.scrollY + window.innerHeight;

      return {
        width: childrenRect.width,
        transform: `translate(${childrenRect.x}px, ${
          isOutsideScreen
            ? window.scrollY + childrenRect.y - dropDownMenuRect.height
            : dropdownMenuPosition
        }px)`,
      };
    }
  };

  const unselectedOptions = useMemo(() => {
    return data.map(section =>
      section.filter(item => !selectedOptions[item.value]),
    );
  }, [data, selectedOptions]);

  const dropdownLabel = useMemo(() => {
    const [head, ...tail] = [...Object.values(selectedOptions)];
    return `${head ? head.label : ''} ${
      tail.length ? `& ${tail.length} More` : head ? '' : placeholder
    }`;
  }, [placeholder, selectedOptions]);

  useEffect(() => {
    if (isNil(value) || !Array.isArray(value)) return;

    const selectedItems = data
      .flat()
      .filter(item => value.includes(item.value));
    if (selectedItems.length > 0) {
      setSelectedOptions(
        selectedItems.reduce(
          (acc, item) => ({ ...acc, [item.value]: item }),
          {},
        ),
      );
    } else {
      setSelectedOptions({});
    }
  }, [data, value]);

  useEffect(() => {
    if (!Array.isArray(value)) return;
    const selectedItems = data
      .flat()
      .filter(item => value.includes(item.value));
    if (selectedItems.length > 0 && isExpanded) {
      setSelectedOptions(
        selectedItems.reduce(
          (acc, item) => ({ ...acc, [item.value]: item }),
          {},
        ),
      );
      const selectedIndices = selectedItems.map(item =>
        data.flat().findIndex(i => i.value === item.value),
      );
      dropDownContentRef.current &&
        dropDownContentRef.current.scrollTo({
          top: Math.min(...selectedIndices) * 50,
          behavior: 'instant',
        });
    }
  }, [data, isExpanded, value]);

  useLayoutEffect(() => {
    if (childrenRef.current) {
      const initialChildrenRect = childrenRef.current.getBoundingClientRect();
      setChildrenRect(initialChildrenRect);
    }
  }, [isExpanded]);

  useEffect(() => {
    const handleGenerateNewChildrenRect = () => {
      if (dropDownMenuRef.current && childrenRef.current) {
        const newChildrenRect = childrenRef.current.getBoundingClientRect();
        setChildrenRect(newChildrenRect);
      }
    };
    const handleResize = () => {
      handleGenerateNewChildrenRect();
    };
    const handleWheel = (e: WheelEvent | TouchEvent) => {
      const target = e.target as HTMLElement;
      if (!target.closest('#portal-dropdown')) {
        dropDownMenuRef.current && isExpanded && setIsExpanded(false);
      }
    };
    window.addEventListener('resize', handleResize);
    window.addEventListener('wheel', handleWheel);
    window.addEventListener('touchmove', handleWheel);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('touchmove', handleWheel);
    };
  }, [isExpanded]);

  const handleOnPress = (onPress: () => void) => {
    if (dismissOnSelect) {
      setIsExpanded(false);
      handleRemovePortal();
    }
    onPress();
  };

  const handleSelectOption = (optionItem: IDropdownItem) => {
    if (dismissOnSelect) {
      setIsExpanded(false);
      handleRemovePortal();
    }
    const isValueSelected = !!selectedOptions[optionItem.value];
    isNil(value) &&
      setSelectedOptions(_selectedOptions => {
        const _newSelectedOptions = { ..._selectedOptions };
        if (isValueSelected) {
          delete _newSelectedOptions[optionItem.value];
        } else {
          _newSelectedOptions[optionItem.value] = optionItem;
        }
        return _newSelectedOptions;
      });
    if (onChange) {
      const _selectedOptions = { ...selectedOptions };
      if (isValueSelected) {
        delete _selectedOptions[optionItem.value];
      } else {
        _selectedOptions[optionItem.value] = optionItem;
      }
      const newValues = Object.values(_selectedOptions);
      if (returnItemOnChange) {
        onChange(newValues as any);
      } else {
        onChange(newValues.map(item => item.value) as any);
      }
    }
  };
  const handleOpenDropDown = () => {
    if (isEditable) {
      setIsExpanded(!isExpanded);
      if (!isExpanded) updatePortals('dropdown', 'add');
      else handleRemovePortal();
    }

    onActive && onActive();
  };

  const handleRemovePortal = () => {
    updatePortals('dropdown', 'remove');
  };

  const handlePopUp = () => {
    if (newTimeout) clearTimeout(newTimeout);
    setNewTimeout(
      setTimeout(() => {
        setShowHover(true);
      }, 500),
    );
  };

  return (
    <Box csx={[dropDownContainerStyles, csx]}>
      {(label || emptyLabel) && (
        <label
          onMouseOver={handlePopUp}
          onMouseOut={() => {
            if (newTimeout) clearTimeout(newTimeout);
            setShowHover(false);
          }}
          css={labelStyles}
          className={`${isDisabled ? 'disabled' : ''}`}>
          {info && !noTooltip && (
            <div className="infoButton">
              <ToolTip
                showHover={showHover}
                content={info}
                direction="top-right"
                isDisabled={isDisabled}>
                <div />
              </ToolTip>
            </div>
          )}
          {!emptyLabel ? label : ''}
          {required && !hideRequiredSymbol && (
            <span className="required">*</span>
          )}
        </label>
      )}
      <div ref={childrenRef}>
        <DropdownButton
          isDisabled={isDisabled}
          isExpanded={isExpanded}
          isLoading={isLoading}
          handleOpenDropDown={handleOpenDropDown}
          hasSelectedOption={!isEmpty(selectedOptions)}
          selectedOptionLabel={dropdownLabel}
          selectedOptionColor={customTextColor}
          placeholder={placeholder}
          // customText={customText}
          customTextColor={customTextColor}
          error={error}
          // loadingText={loadingText}
          inputContainerProps={inputContainerProps}
        />
      </div>
      {isExpanded &&
        createPortal(
          <div
            id="portal-dropdown"
            ref={dropDownMenuRef}
            css={[dropDownMenuStyles, getDropdownPosition()]}>
            {!isEmpty(selectedOptions) && (
              <Box csx={selectedOptionsStyles}>
                {Object.values(selectedOptions).map((item, index) => (
                  <Button
                    key={index}
                    variant="secondary"
                    csx={{
                      height: '30px',
                    }}
                    icon={
                      <Box
                        csx={{
                          justifyContent: 'center',
                          alignItems: 'flex-end',
                          height: '18px',
                          marginRight: '-5px',
                        }}>
                        <Icon name="MdClear" color="semanticBlue" size="15px" />
                      </Box>
                    }
                    className="selected"
                    onClick={() => handleSelectOption(item)}>
                    <Typography color="semanticBlue" variant="caption">
                      {item.label}
                    </Typography>
                  </Button>
                ))}
              </Box>
            )}
            <Box ref={dropDownContentRef} csx={dropdownMenuContentStyles}>
              {unselectedOptions.map((section, index) => (
                <ul key={`section${index}`}>
                  {section.map((item, itemIndex) => (
                    <li
                      key={`item${itemIndex}`}
                      className={`${
                        selectedOptions[item.value] ? 'active' : ''
                      } ${item.isDisabled ? 'disabled' : ''}`}
                      onClick={() => {
                        if (item.isDisabled) return;

                        if (item.onPress) handleOnPress(item.onPress);
                        else handleSelectOption(item);
                      }}>
                      <Typography {...item.textProps}>{item.label}</Typography>
                    </li>
                  ))}
                </ul>
              ))}
            </Box>
          </div>,
          document.body,
        )}
    </Box>
  );
};

export default MultiSelectDropdown;

import { produce } from 'immer';
import { Reducer } from 'redux';
import {
  ActionTypes,
  HOME_INITIAL_STATE,
  IHomeStore,
  SET_CURRENT_DASHBOARD,
  SET_CURRENT_EDIT_DASHBOARD,
  SET_DASHBOARD_BOTTOM_SHEET_OPEN,
  SET_CURRENT_EDIT_DASHBOARD_LAYOUT,
  SET_CURRENT_EDIT_SELECTED_DASHBOARD,
  SET_CURRENT_EDIT_DASHBOARD_WIDGET,
} from './types';

export const homeReducer: Reducer<IHomeStore, ActionTypes> = produce(
  (state = HOME_INITIAL_STATE, action) => {
    switch (action.type) {
      case SET_CURRENT_DASHBOARD:
        state.currentDashboard = action.payload;
        break;
      case SET_CURRENT_EDIT_DASHBOARD:
        state.currentEditDashboard = action.payload;
        break;
      case SET_CURRENT_EDIT_DASHBOARD_LAYOUT:
        {
          if (!state.currentEditDashboard) return state;
          state.currentEditDashboard.selectedDashboard.layout = action.payload;
        }
        break;
      case SET_CURRENT_EDIT_SELECTED_DASHBOARD:
        {
          if (!state.currentEditDashboard) return state;
          state.currentEditDashboard.selectedDashboard = {
            ...state.currentEditDashboard.selectedDashboard,
            ...action.payload,
          };
        }
        break;
      case SET_CURRENT_EDIT_DASHBOARD_WIDGET:
        {
          if (!state.currentEditDashboard) return state;
          state.currentEditDashboard.selectedDashboard.widgets = action.payload;
        }
        break;
      case SET_DASHBOARD_BOTTOM_SHEET_OPEN:
        {
          if (!state.currentEditDashboard) return state;
          state.currentEditDashboard.isBottomSheetOpen = action.payload;
        }
        break;
      default:
        return state;
    }
  },
);

import Box from '@app/components/common/Box';
import React, { useEffect, useRef, useState } from 'react';
import Button from '@app/components/common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { bottomSheetHeaderStyles, newElementContainer } from './styles';
import { AnimatePresence } from 'framer-motion';
import { AnimatedOpacityContainer } from '@app/components/common/Animated/AnimatedOpacityContainer';
import { GridStackWidget } from 'gridstack';
import WidgetCreateOrEditModal from '../WidgetCreateOrEditModal/WidgetCreateOrEditModal';
import { selectDashboardBottomSheetOpen } from '@app/state/selectors/homeSelectors';
import { setDashboardBottomSheetOpen } from '@app/state/home/actions';
import { EWidgetTypes, IWidget, TWidgetTypes } from '@app/state/home/types';

interface IDashboardBottomSheet {
  onCreateNewElement: ({
    element,
    widget,
  }: {
    element: GridStackWidget;
    widget: IWidget;
  }) => void;
}
const DashboardBottomSheet = ({
  onCreateNewElement,
}: IDashboardBottomSheet) => {
  // Redux
  const dispatch = useDispatch();
  const isBottomSheetOpen = useSelector(selectDashboardBottomSheetOpen);

  // Local state
  const bottomSheetRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const [tab, setTab] = useState<'widget' | null>(null);
  const [modalState, setModalState] = useState<{
    isActive: boolean;
    widgetType: TWidgetTypes | null;
  }>({
    isActive: false,
    widgetType: null,
  });

  useEffect(() => {
    if (!tab && isBottomSheetOpen) setTab('widget');
  }, [isBottomSheetOpen, tab]);

  const handleCreateElement = (widgetType: TWidgetTypes) => {
    setModalState({
      isActive: true,
      widgetType,
    });
  };

  const handleCreateWidget = (newWidget: IWidget) => {
    switch (newWidget.type) {
      case EWidgetTypes.BAR_CHART:
        onCreateNewElement({
          element: {
            id: '0',
            w: 6,
            h: 3,
          },
          widget: newWidget,
        });
        break;
      case EWidgetTypes.PIE_CHART:
        onCreateNewElement({
          element: {
            id: '0',
            w: 4,
            h: 3,
          },
          widget: newWidget,
        });
        break;
      case EWidgetTypes.DATA_TABLE:
        onCreateNewElement({
          element: {
            id: '0',
            w: 3,
            h: 3,
          },
          widget: newWidget,
        });
        break;
      default:
        break;
    }
  };

  const WIDGET_TYPES = {
    [EWidgetTypes.BAR_CHART]: {
      type: EWidgetTypes.BAR_CHART,
      name: 'Bar Chart',
      icon: <Icon name="MdBarChart" color="semanticBlue" />,
    },
    [EWidgetTypes.PIE_CHART]: {
      type: EWidgetTypes.PIE_CHART,
      name: 'Pie Chart',
      icon: <Icon name="MdPieChart" color="semanticBlue" />,
    },
    [EWidgetTypes.DATA_TABLE]: {
      type: EWidgetTypes.DATA_TABLE,
      name: 'Summary Table',
      icon: <Icon name="MdSubject" color="semanticBlue" />,
    },
  };

  return (
    <>
      <AnimatePresence>
        <Box
          ref={bottomSheetRef}
          csx={{
            width: '100%',
            zIndex: 1,
            boxShadow: theme.shadows[1],
            backgroundColor: theme.colors.white,
          }}>
          <Box
            csx={bottomSheetHeaderStyles}
            onClick={() => {
              dispatch(setDashboardBottomSheetOpen(!isBottomSheetOpen));
              setTab(null);
            }}>
            <Box className="tabsContainer">
              <Button
                variant={tab === 'widget' ? 'active' : 'transparent'}
                icon={<Icon name="MdWidgets" />}
                onClick={() => {
                  setTab('widget');
                  if (!isBottomSheetOpen) {
                    dispatch(setDashboardBottomSheetOpen(true));
                  }
                }}>
                Widgets
              </Button>
            </Box>

            {isBottomSheetOpen && (
              <Button
                variant="icon"
                onClick={() => {
                  dispatch(setDashboardBottomSheetOpen(false));
                  setTab(null);
                }}
                csx={{ minWidth: '50px' }}
                icon={<Icon name="MdClear" csx={{ rotate: '90deg' }} />}
              />
            )}
          </Box>

          {tab === 'widget' && (
            <AnimatedOpacityContainer>
              <Box csx={newElementContainer} className="widgetsArea">
                {Object.values(WIDGET_TYPES).map(widget => (
                  <Box
                    key={`widget-${widget.type}`}
                    className="grid-stack-item newElement">
                    <Box className="objectsList grid-stack-item-content">
                      <DraggableButton
                        id={widget.type}
                        onClick={() => handleCreateElement(widget.type)}>
                        {widget.icon}
                        <Typography color="semanticBlue" fontWeight="medium">
                          {widget.name}
                        </Typography>
                      </DraggableButton>
                    </Box>
                  </Box>
                ))}
              </Box>
            </AnimatedOpacityContainer>
          )}
        </Box>
      </AnimatePresence>

      <WidgetCreateOrEditModal
        widgetType={modalState.widgetType}
        isActive={modalState.isActive}
        onModalClose={() =>
          setModalState({
            isActive: false,
            widgetType: null,
          })
        }
        onCreateWidget={newWidget => {
          if (modalState.widgetType) handleCreateWidget(newWidget);
        }}
        mode="create"
      />
    </>
  );
};

const DraggableButton = ({
  id,
  children,
  onClick,
}: {
  id: string;
  children: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <Box
      id={`${id}-draggable`}
      csx={theme => ({
        cursor: 'pointer',
        borderRadius: '10px',
        backgroundColor: theme.colors.lighterBlue,
        paddingInline: '10px',
        minWidth: '90px',
        height: '65px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        userSelect: 'none',
      })}
      onClick={onClick}>
      {children}
    </Box>
  );
};

export default DashboardBottomSheet;

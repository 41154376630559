import {
  DATA_TYPES,
  FORM_DATA_INITIAL_STATE,
} from '@app/components/LoggedIn/Home/DashboardCreator/types';
import {
  EDashboardModes,
  IWidget,
  TDashboardModes,
  TWidgetTypes,
} from '@app/state/home/types';

export const getWidgetInitialFormData = (
  mode: TDashboardModes,
  widgetType: TWidgetTypes | null,
  currentData: IWidget | undefined,
) => {
  let formData = {} as {
    title: string;
    parameters: Record<string, any>;
  };
  if (mode === EDashboardModes.EDIT && currentData) {
    formData = {
      title: currentData.title,
      parameters: currentData.parameters,
    };
  } else {
    if (widgetType === 'pieChart') {
      formData = {
        title: 'Transactions By Type',
        parameters: {
          dataType: 'transactions',
          xLabel: '',
          yLabel: '',
          slices: [
            { label: 'Card', color: '#348DFC' },
            { label: 'Gift Card', color: '#FFA500' },
            { label: 'Cash', color: '#9C20E9' },
            { label: 'House', color: '#008000' },
          ],
        },
      };
    } else if (widgetType === 'barChart') {
      formData = {
        ...FORM_DATA_INITIAL_STATE,
        title: 'Net Sales',
      };
    } else if (widgetType === 'dataTable') {
      formData = {
        title: 'Revenue Summary',
        parameters: {
          dataType: 'revenueSummary',
        },
      };
    }
  }
  return formData;
};

export const getInitialDataByDataType = (dataType: number) => {
  let formData = {} as {
    title: string;
    parameters: Record<string, any>;
  };

  if (
    dataType === 11 ||
    dataType === 12 ||
    dataType === 13 ||
    dataType === 14 ||
    dataType === 15 ||
    dataType === 16
  ) {
    const title =
      dataType === 11
        ? 'Net Sales'
        : dataType === 12
        ? 'Total Revenue'
        : dataType === 13
        ? 'Total Discounts'
        : dataType === 14
        ? 'Total Specials'
        : dataType === 15
        ? 'Tips'
        : dataType === 16
        ? 'Voided Items'
        : '';
    formData = {
      title: title,
      parameters: {
        dataType: DATA_TYPES[dataType],
        xLabel: '',
        yLabel: '',
        color: '#348DFC',
      },
    };
  } else if (dataType === 21) {
    formData = {
      title: 'Transactions',
      parameters: {
        dataType: 'transactions',
        slices: [
          { label: 'Card', color: '#348DFC' },
          { label: 'Gift Card', color: '#FFA500' },
          { label: 'Cash', color: '#9C20E9' },
          { label: 'House', color: '#008000' },
        ],
      },
    };
  } else if (dataType === 22) {
    formData = {
      title: 'Credit Card Type',
      parameters: {
        dataType: 'creditCardType',
        slices: [
          { label: 'Amex', color: '#348DFC' },
          { label: 'Discover', color: '#FFA500' },
          { label: 'Mastercard', color: '#9C20E9' },
          { label: 'Visa', color: '#008000' },
        ],
      },
    };
  } else if (dataType === 23) {
    formData = {
      title: 'Gross Sales By Reporting Group',
      parameters: {
        dataType: 'grossSalesGroup',
        slices: [
          { label: 'RG 1', color: '#348DFC' },
          { label: 'RG 2', color: '#FFA500' },
          { label: 'RG 3', color: '#9C20E9' },
          { label: 'RG 4', color: '#008000' },
        ],
      },
    };
  } else if (dataType === 24) {
    formData = {
      title: 'Net Sales By Role',
      parameters: {
        dataType: 'netSalesByRole',
        slices: [
          { label: 'Role 1', color: '#348DFC' },
          { label: 'Role 2', color: '#FFA500' },
          { label: 'Role 3', color: '#9C20E9' },
          { label: 'Role 4', color: '#008000' },
        ],
      },
    };
  } else if (dataType === 31) {
    formData = {
      title: 'Revenue Summary',
      parameters: {
        dataType: 'revenueSummary',
      },
    };
  } else return FORM_DATA_INITIAL_STATE;

  return formData;
};

export const DEMO_DATA = {
  barChart: [
    {
      xValue: '2025-03-19 13:00:01 +0000 UTC',
      yValue: '50.06',
      moreData: [
        {
          label: 'Number of Orders',
          value: 2,
        },
        {
          label: 'Start',
          value: '2025-03-19 13:00:01 +0000 UTC',
        },
        {
          label: 'End',
          value: '2025-03-26 13:00:01 +0000 UTC',
        },
      ],
    },
    {
      xValue: '2025-03-26 14:00:01 +0000 UTC',
      yValue: '100.62',
      moreData: [
        {
          label: 'Number of Orders',
          value: 1,
        },
        {
          label: 'Start',
          value: '2025-03-26 13:00:01 +0000 UTC',
        },
        {
          label: 'End',
          value: '2025-04-02 13:00:01 +0000 UTC',
        },
      ],
    },
    {
      xValue: '2025-04-02 15:00:01 +0000 UTC',
      yValue: '172.83',
      moreData: [
        {
          label: 'Number of Orders',
          value: 6,
        },
        {
          label: 'Start',
          value: '2025-04-02 13:00:01 +0000 UTC',
        },
        {
          label: 'End',
          value: '2025-04-09 13:00:01 +0000 UTC',
        },
      ],
    },
    {
      xValue: '2025-04-09 19:00:01 +0000 UTC',
      yValue: '30.25',
      moreData: [
        {
          label: 'Number of Orders',
          value: 1,
        },
        {
          label: 'Start',
          value: '2025-04-09 13:00:01 +0000 UTC',
        },
        {
          label: 'End',
          value: '2025-04-16 13:00:01 +0000 UTC',
        },
      ],
    },
  ],
  dataTable: [
    {
      label: 'Net Sales',
      value: 10,
    },
    {
      label: 'Service Charge to Restaurant',
      value: 20,
    },
    {
      label: 'Taxes',
      value: 30,
    },
    {
      label: 'Tips & Service Charge to Tips',
      value: 40,
    },
    {
      label: 'Total Revenue',
      value: 100,
    },
  ],
};

import Card from '@app/components/common/Card';
import Checkbox from '@app/components/common/Checkbox';
import Dropdown from '@app/components/common/Dropdown';
import FontsDropdown from '@app/components/common/Dropdown/FontsDropdown/FontsDropdown';
import Grid from '@app/components/common/Grid';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  ELEMENT_SPACING_OPTIONS,
  IGuestCheckSettings,
  KITCHEN_TICKET_FONT_SIZE_OPTIONS,
  KITCHEN_TICKET_FONT_WEIGHT_OPTIONS,
  SettingsSectionId,
  actionCreatorsSettings,
  elementSpacingOptionsFactory,
  kitchenTicketFontSizeOptionsFactory,
  kitchenTicketFontWeightOptionsFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const SECTION_ID = SettingsSectionId.TICKET_DISPLAY;
const getMemoizedItemData =
  makeSelectSettingsData<IGuestCheckSettings>(SECTION_ID);

const CheckDisplay = ({ t }: WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedItemData);

  const elementSpacingOptions = useMemo(
    () => elementSpacingOptionsFactory(),
    [],
  );

  const kitchenTicketFontSizeOptions = useMemo(
    () => kitchenTicketFontSizeOptionsFactory(t),
    [t],
  );

  const fontSizeValue = useMemo(
    () => KITCHEN_TICKET_FONT_SIZE_OPTIONS.indexOf(data?.fontSize) + 1,
    [data?.fontSize],
  );

  const fontWeightValue = useMemo(
    () => KITCHEN_TICKET_FONT_WEIGHT_OPTIONS.indexOf(data?.fontWeight) + 1,
    [data?.fontWeight],
  );

  const kitchenTicketFontWeightOptions = useMemo(
    () => kitchenTicketFontWeightOptionsFactory(t),
    [t],
  );

  return (
    <Card.SubCard
      title={t('loggedIn.settingsModule.guestCheckSettings.checkDisplay.title')}
      csx={{ height: '100%' }}>
      <Grid rowGap={15} columnGap={15}>
        <Grid.Item lg={12} xl={6}>
          <Checkbox
            label={t(
              'loggedIn.settingsModule.guestCheckSettings.checkDisplay.showLogo',
            )}
            checked={data ? data.showLogo : false}
            onChange={showLogo =>
              setValue<IGuestCheckSettings>({ showLogo }, SECTION_ID)
            }
          />
        </Grid.Item>
        <Grid.Item lg={12} xl={6}>
          <Checkbox
            label={t(
              'loggedIn.settingsModule.guestCheckSettings.checkDisplay.showItemUnitPrice',
            )}
            checked={data ? data.showItemUnitPrice : false}
            onChange={showItemUnitPrice =>
              setValue<IGuestCheckSettings>({ showItemUnitPrice }, SECTION_ID)
            }
          />
        </Grid.Item>
        <Grid.Item lg={12} xl={6}>
          <Dropdown
            label={t(
              'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.fontWeight.label',
            )}
            placeholder={t(
              'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.fontSize.label',
            )}
            data={[kitchenTicketFontWeightOptions]}
            value={fontWeightValue}
            onChange={newFontWeight =>
              setValue<IGuestCheckSettings>(
                {
                  fontWeight:
                    KITCHEN_TICKET_FONT_WEIGHT_OPTIONS[newFontWeight - 1],
                },
                SECTION_ID,
              )
            }
          />
        </Grid.Item>
        <Grid.Item lg={12} xl={6}>
          <FontsDropdown
          optionsWidth={300}
            label={t(
              'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.fontSize.label',
            )}
            placeholder={t(
              'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.fontSize.label',
            )}
            data={[kitchenTicketFontSizeOptions]}
            value={fontSizeValue}
            onChange={newFontSize =>
              setValue<IGuestCheckSettings>(
                { fontSize: KITCHEN_TICKET_FONT_SIZE_OPTIONS[newFontSize - 1] },
                SECTION_ID,
              )
            }
          />
        </Grid.Item>
      </Grid>
      <Grid rowGap={15} columnGap={15} csx={{ marginTop: 15 }}>
        <Grid.Item lg={12} xl={6}>
          <Dropdown
            label={t(
              'settingsModule.kitchenTicketSettings.ticketDisplay.elementSpacing.label',
            )}
            placeholder={t(
              'settingsModule.kitchenTicketSettings.ticketDisplay.elementSpacing.label',
            )}
            data={[elementSpacingOptions]}
            value={ELEMENT_SPACING_OPTIONS.indexOf(data?.elementSpacing) + 1}
            onChange={newHeaderElementSpacing =>
              setValue<IGuestCheckSettings>(
                {
                  elementSpacing:
                    ELEMENT_SPACING_OPTIONS[newHeaderElementSpacing - 1],
                },
                SECTION_ID,
              )
            }
          />
        </Grid.Item>
      </Grid>
    </Card.SubCard>
  );
};

export default CheckDisplay;

import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { IItemTableRow, truncateString } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { getIPairArray } from '../helpers';

interface ITaxColumn extends WithTranslation {
  value: IItemTableRow['categoriesSubcategories'];
  truncateLength?: number;
  rows?: number;
}

const TaxColumn = ({ value, t, truncateLength = 10, rows }: ITaxColumn) => {
  const { array, isOverflow, isNull, arrayToRender } = getIPairArray(
    value,
    rows,
  );
  return (
    <Box csx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      {isNull || array.length === 0 ? (
        <Typography
          csx={{
            fontStyle: isNull ? 'italic' : undefined,
          }}>
          {isNull
            ? t('menuScreen.itemsMain.table.varies')
            : t('menuScreen.itemsMain.table.noTaxes')}
        </Typography>
      ) : (
        arrayToRender.map((tax, index) => (
          <Typography
            component="div"
            key={`tax-${index}`}
            csx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}>
            <Typography fontWeight="bold">
              {truncateString(tax.text, truncateLength)}
            </Typography>
            <Typography>
              &nbsp;({tax.description})
              {isOverflow && index === arrayToRender.length - 1 && '...'}
            </Typography>
          </Typography>
        ))
      )}
    </Box>
  );
};

export default TaxColumn;

import { BREAKPOINTS } from '@app/theme/themes';
import { ParseKeys } from 'i18next';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import Box from '../Box';
import Button from '../Button';
import Icon from '../Icon';
import SubTabButton from './SubTabButton';
import {
  SCROLL_BUTTON_WIDTH,
  TAB_SEPARATION,
  TAB_WIDTH,
  TAB_WIDTH_MOBILE,
  scrollButtonStyles,
  tabStyles,
  tabsStyles,
} from './styles';
import { ITab, ITabs } from './types';

const Tabs = ({
  t,
  data,
  onSelectedTabChange,
  activeTab,
  activateFirstTabIfNoActiveTab = false,
  csx,
  disabledTabs,
}: ITabs) => {
  const [activeSubTab, setActiveSubTab] = useState<{
    tabId: number;
    subTab: ITab;
  } | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const debounce = useRef<NodeJS.Timeout | null>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [wasDragging, setWasDragging] = useState(false);

  useEffect(() => {
    if (data.length && activateFirstTabIfNoActiveTab)
      onSelectedTabChange && onSelectedTabChange(data[0].id, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, activateFirstTabIfNoActiveTab]);

  useEffect(() => {
    if (data.length && activeTab) {
      let subTab = data
        .find(tab => tab.id === activeTab.tabId)
        ?.subTabs?.find(
          tab =>
            tab.id === activeTab.subTabId ||
            tab.subTabs?.find(_subTab => _subTab.id === activeTab.subTabId),
        );
      if (subTab?.subTabs) {
        subTab = subTab.subTabs.find(
          _subTab => _subTab.id === activeTab.subTabId,
        );
      }
      if (subTab) setActiveSubTab({ tabId: activeTab.tabId, subTab: subTab });
      else setActiveSubTab(null);
    }
  }, [activeTab, data]);

  useEffect(() => {
    const handleResize = () => {
      handleShowIcon();
      handleScrollToActiveTab('update');
    };

    const handleLoad = () => {
      handleShowIcon();
    };

    handleLoad();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    return handleScrollToActiveTab('initialize');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleScrollToActiveTab = (mode: 'initialize' | 'update') => {
    debounce.current && clearTimeout(debounce.current);
    debounce.current = setTimeout(
      () => {
        if (!activeTab || !containerRef.current) return;
        const windowWidth = window.innerWidth;

        let realTabWidth = TAB_WIDTH;
        if (windowWidth < parseInt(BREAKPOINTS['small'].min))
          realTabWidth = TAB_WIDTH_MOBILE;

        containerRef.current.scrollTo({
          left:
            activeTab.tabId * (realTabWidth + TAB_SEPARATION) -
            realTabWidth -
            TAB_SEPARATION -
            SCROLL_BUTTON_WIDTH,
          behavior: 'smooth',
        });
      },
      mode === 'initialize' ? 0 : 100,
    );
  };

  const handleShowIcon = () => {
    if (!containerRef.current) return;

    if (containerRef.current.scrollWidth > containerRef.current.offsetWidth) {
      if (containerRef.current.scrollLeft <= 0) setShowLeftArrow(false);
      else setShowLeftArrow(true);
      if (
        containerRef.current.scrollLeft >=
        containerRef.current.scrollWidth - containerRef.current.offsetWidth
      )
        setShowRightArrow(false);
      else setShowRightArrow(true);
    } else {
      setShowLeftArrow(false);
      setShowRightArrow(false);
    }

    debounce.current && clearTimeout(debounce.current);
  };

  const handleScrollTo = (direction: 'left' | 'right') => {
    if (containerRef.current) {
      if (direction === 'left') {
        containerRef.current.scrollTo({
          left: containerRef.current.scrollLeft - TAB_WIDTH * 3,
          behavior: 'smooth',
        });
      } else {
        containerRef.current.scrollTo({
          left: containerRef.current.scrollLeft + TAB_WIDTH * 3,
          behavior: 'smooth',
        });
      }
      handleShowIcon();
    }
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (isDragging && containerRef.current) {
      const clientX = e.clientX;
      const minToMove = 6;
      const delta = clientX - startPosition;
      containerRef.current.scrollLeft = scrollLeft - delta;

      if (
        clientX + minToMove < startPosition ||
        clientX - minToMove > startPosition
      ) {
        setWasDragging(true);
      }
    }
  };

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    if (containerRef.current) {
      setIsDragging(true);
      setStartPosition(e.clientX);
      setScrollLeft(containerRef.current.scrollLeft);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const handleOnMouseLeave = () => {
    setIsDragging(false);
    setWasDragging(false);
  };

  const handleOnClickTab = (tabId: number) => {
    if (!wasDragging) {
      onSelectedTabChange && onSelectedTabChange(tabId, null);
    } else setWasDragging(false);
  };

  const handleOnClickSubTab = (tabId: number, subTab: ITab) => {
    if (!wasDragging) {
      if (
        activeSubTab?.tabId !== tabId ||
        activeSubTab.subTab.id !== subTab.id
      ) {
        onSelectedTabChange && onSelectedTabChange(tabId, subTab.id);
      }
    } else setWasDragging(false);
  };

  return (
    <Box csx={{ position: 'relative' }}>
      <Box
        ref={containerRef}
        csx={[tabsStyles, { cursor: isDragging ? 'grabbing' : 'grab' }, csx]}
        onScroll={handleShowIcon}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleOnMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseDown={handleMouseDown}>
        {data.map(item => {
          const isDisabled = disabledTabs?.includes(item.id) || item.isDisabled;

          const isActive = activeTab?.tabId === item.id;

          return item.subTabs ? (
            <SubTabButton
              key={`tab-${item.id}`}
              item={item}
              isActive={isActive}
              isDragging={isDragging}
              activeSubTab={activeSubTab}
              isTooltipDisabled={wasDragging}
              setActiveSubTab={tab => handleOnClickSubTab(item.id, tab.subTab)}
              setIsTooltipDisabled={() => setWasDragging(false)}
              data-disabled={isDisabled}
            />
          ) : (
            <Button
              key={`tab-${item.id}`}
              csx={[
                tabStyles,
                isDragging ? { cursor: 'grabbing !important' } : {},
              ]}
              disabled={isDisabled}
              className={isActive ? 'active' : ''}
              iconPosition="right"
              onClick={() => {
                if (isDisabled) return;
                if (activeSubTab) setActiveSubTab(null);
                handleOnClickTab(item.id);
              }}
              data-disabled={isDisabled}
              {...item.buttonProps}
              variant={
                isActive ? 'active' : item.buttonProps?.variant || 'transparent'
              }>
              <Box
                csx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Box>{t(item.name as ParseKeys)}</Box>
                {isActive && (
                  <Box>
                    {item.description && t(item.description as ParseKeys)}
                  </Box>
                )}
              </Box>
            </Button>
          );
        })}
      </Box>
      {showLeftArrow && (
        <ScrollButton direction="left" onClick={handleScrollTo} />
      )}
      {showRightArrow && (
        <ScrollButton direction="right" onClick={handleScrollTo} />
      )}
    </Box>
  );
};
interface IScrollButton {
  direction: 'left' | 'right';
  onClick: (direction: 'left' | 'right') => void;
}
const ScrollButton = ({ direction, onClick }: IScrollButton) => {
  return (
    <Box
      onClick={() => onClick(direction)}
      csx={[
        scrollButtonStyles,
        { right: direction === 'right' ? 0 : 'unset' },
      ]}>
      <Box
        className="blur"
        csx={direction === 'left' ? { left: '-10px' } : { right: '-10px' }}
      />
      <Button variant="secondary" className="arrowIcon">
        <Icon
          name="MdChevronRight"
          color="persistentSemanticBlue"
          size="25px"
          csx={{
            marginTop: '2px',
            rotate: direction === 'left' ? '180deg' : '',
          }}
        />
      </Button>
    </Box>
  );
};

export default Tabs;

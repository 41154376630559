import { css } from '@emotion/react';

export const printerJobsStyles = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  gap: '10px',
  position: 'relative',
  overflowY: 'hidden',
  paddingBottom: '60px',
  zIndex: 0,
});

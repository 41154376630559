import { scrollBarStyles } from '@app/theme/commonStyles';
import { ThemeModes } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const dropDownContainerStyles = (theme: Theme) =>
  css({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '.active': {
      color: theme.colors.semanticBlue,
      fontWeight: theme.fontWeights.medium,
      border: `2px solid ${theme.colors.semanticBlue}`,
    },
    '.selected': {
      svg: {
        fill: `${theme.colors.black}`,
      },
    },
    '.error': {
      border: `2px solid ${theme.colors.persistentSemanticRed}`,
    },
    '.noSelected': {
      color: theme.colors.darkGrey,
    },
    '.infoButton': {
      display: 'flex',
    },
    'button:disabled': {
      p: {
        color: theme.colors.lightGrey,
      },
      svg: {
        fill: theme.colors.lightGrey,
      },
    },
  });

export const dropDownMenuStyles = (theme: Theme) =>
  css({
    position: 'absolute',
    zIndex: 99999,
    backgroundColor:
      theme.mode === ThemeModes.DARK
        ? theme.colors.lighterGrey
        : theme.colors.white,
    boxShadow: theme.shadows[2],
    inset: '0px auto auto 0px',
    borderRadius: '8px',
    overflow: 'hidden',
    '& ul': {
      listStyle: 'none',
      padding: '0',
      margin: '0',

      '& li': {
        height: '50px',
        paddingInline: '15px',
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.fontSizes.body,
        color: theme.colors.black,
        fontWeight: theme.fontWeights.regular,
        '&.inActive': {
          p: {
            color: theme.colors.semanticRed,
          },
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.colors.lighterRed,
            p: {
              color: theme.colors.semanticRed,
            },
            svg: {
              fill: theme.colors.semanticRed,
            },
          },
        },
        '&.active': {
          backgroundColor: theme.colors.semanticBlue,
          p: {
            color: theme.colors.textWhite,
          },
          svg: {
            fill: theme.colors.textWhite,
          },
          '&.inActive': {
            backgroundColor: theme.colors.lightestRed,
            p: {
              color: theme.colors.semanticRed,
            },
            svg: {
              fill: theme.colors.semanticRed,
            },
          },
        },
        '&.disabled': {
          p: {
            color: theme.colors.lightGrey,
          },
          '&:hover': {
            cursor: 'not-allowed',
            backgroundColor: 'transparent',
            p: {
              color: theme.colors.lightGrey,
            },
          },
        },
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: theme.colors.lighterBlue,
          p: {
            color: theme.colors.semanticBlue,
          },
          svg: {
            fill: theme.colors.semanticBlue,
          },
        },
      },
    },
  });

export const dropdownMenuContentStyles = (theme: Theme) =>
  css(scrollBarStyles(theme, false), {
    overflowY: 'auto',
    maxHeight: '200px',
  });

import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { printersSelector } from '@app/state/selectors/settingsSelectors';
import {
  IPrinterJobSelected,
  printerCenterFactory,
} from '@westondev/tableturn-core';
import { AnimatePresence, m, useAnimate, usePresence } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PrinterJobs from './PrinterJobs';
import PrinterListElement from './PrinterListElement';
import { PRINTER_JOBS_WIDTH, printerCenterStyles } from './styles';

interface IPrinterCenter extends WithTranslation {
  selectedPrinter: IPrinterJobSelected | null;
  setSelectedPrinter: (value: IPrinterJobSelected | null) => void;
  onCloseClick: () => void;
}
const PrinterCenter = ({
  t,
  onCloseClick,
  selectedPrinter,
  setSelectedPrinter,
}: IPrinterCenter) => {
  // Redux
  const printers = useSelector(printersSelector);

  // Local state
  const printersSections = useMemo(
    () => printerCenterFactory(printers),
    [printers],
  );
  const allPrinters = useMemo(
    () => printersSections.map(section => section.data).flat(),
    [printersSections],
  );

  return (
    <Box csx={printerCenterStyles(selectedPrinter ? true : false)}>
      <Box
        csx={theme => ({
          maxWidth: '100%',
          display: 'flex',
          alignItems: 'center',
          // justifyContent: 'space-between',
          gap: '10px',
          boxShadow: theme.shadows[1],
          padding: '15px',
          height: '75px',
          position: 'relative',
          flexGrow: 0,
          zIndex: 1,
        })}>
        <Box
          csx={{
            flex: '1',
            display: 'flex',
            alignItems: 'center',
          }}>
          {selectedPrinter ? (
            <Button
              className="goBackButton"
              variant="transparent"
              icon={<Icon name="MdArrowBack" />}
              csx={{
                padding: '10px',
              }}
              onClick={() => {
                setSelectedPrinter(null);
              }}>
              <span>{t('navBar.common.printerCenter.printers')}</span>
            </Button>
          ) : (
            <Button
              variant="icon"
              icon={<Icon name="MdClear" />}
              csx={{ position: 'absolute', left: '15px', marginTop: '2px' }}
              onClick={onCloseClick}
            />
          )}
        </Box>
        <Typography variant="subtitle" fontWeight="medium">
          {selectedPrinter
            ? `${t('navBar.common.printerCenter.printJobs.title')} - `
            : t('navBar.common.printerCenter.title')}
        </Typography>
        {!!selectedPrinter && (
          <Typography
            color="persistentSemanticBlue"
            variant="subtitle"
            fontWeight="medium">
            {selectedPrinter.printerName}
          </Typography>
        )}
        <Box csx={{ flex: '1', display: 'flex', justifyContent: 'flex-end' }}>
          {!selectedPrinter && (
            <Button
              csx={{ padding: '20px' }}
              onClick={() => {
                setSelectedPrinter({
                  printerId: 0,
                  printerName: 'All Jobs',
                });
              }}>
              View All Jobs
            </Button>
          )}
        </Box>
      </Box>
      <Box
        csx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflowY: selectedPrinter ? 'hidden' : 'auto',
        }}>
        <AnimatePresence>
          {!selectedPrinter ? (
            <AnimatedPrinterCenter>
              {printersSections.map(printerGroup => (
                <Box
                  key={`printerGroup-${printerGroup.id}`}
                  csx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}>
                  <Typography
                    variant="body"
                    fontWeight="medium"
                    csx={{ paddingInline: '15px', paddingTop: '15px' }}>
                    {printerGroup.name}
                  </Typography>
                  {printerGroup.data.map(printer => (
                    <PrinterListElement
                      key={printer.id}
                      siblings={allPrinters}
                      onViewJobsClick={() => {
                        setSelectedPrinter({
                          printerId: printer.id,
                          printerName: printer.name,
                        });
                      }}
                      {...printer}
                    />
                  ))}
                </Box>
              ))}
            </AnimatedPrinterCenter>
          ) : null}
        </AnimatePresence>
        <AnimatePresence>
          {selectedPrinter ? (
            <AnimatedPrinterJobs>
              <PrinterJobs selectedPrinter={selectedPrinter} />
            </AnimatedPrinterJobs>
          ) : null}
        </AnimatePresence>
      </Box>
    </Box>
  );
};

const AnimatedPrinterCenter = ({ children }: { children: React.ReactNode }) => {
  const [isPresent] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          {
            x: 0,
          },
          { duration: 0.1 },
        );
      };
      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          {
            x: -PRINTER_JOBS_WIDTH,
          },
          { duration: 0.1 },
        );
      };

      exitAnimation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent]);

  return <div ref={scope}>{children}</div>;
};

const AnimatedPrinterJobs = ({ children }: { children: React.ReactNode }) => {
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(scope.current, { x: 0 }, { duration: 0.1 });
      };
      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { x: PRINTER_JOBS_WIDTH },
          { duration: 0.1 },
        );
        safeToRemove();
      };

      exitAnimation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent]);

  return (
    <m.div
      ref={scope}
      css={{
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '100%',
        position: 'absolute',
        width: '100%',
        height: '100%',
        overflowY: 'auto',
      }}
      initial={{ x: 400 }}>
      {children}
    </m.div>
  );
};

export default PrinterCenter;

import {
  CORE_APP_INITIAL_STATE,
  generateTabletGeneratedId,
  REMOVE_TOAST_ELEMENT,
  SET_HIDE_REFRESH_TOAST,
  SET_IS_LOADING_MODAL,
  SET_SHOW_REFRESH_TOAST,
  SET_SHOW_SETTINGS_REFRESH_TOAST,
} from '@westondev/tableturn-core';
import { Reducer } from 'redux';
import {
  ActionTypes,
  IAppStore,
  SET_HIDE_TOAST,
  SET_IS_LOADING,
  SET_LOCATION_ID,
  SET_ORGANIZATION_ID,
  SET_PORTALS,
  SET_PULLING_DATA,
  SET_SHOW_CONFORMATION_MODAL,
  SET_SHOW_TOAST,
  SET_TOKEN,
  SET_USER_DETAILS,
  SET_ORGANIZATIONS,
  SET_VIRTUALIZED_NAVIGATION,
} from './types';
import { produce } from 'immer';

export { CONFIRMATION_MODAL_INITIAL_STATE } from '@westondev/tableturn-core';

const APP_INITIAL_STATE: IAppStore = {
  ...CORE_APP_INITIAL_STATE,
  userDetails: {
    userId: 0,
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailVerified: false,
    phoneVerified: false,
  },
  organizations: [],
  portals: [],
  organizationId: 0,
  locationId: 0,
  virtualizedNavigation: {
    breadcrumb: [],
    index: 0,
    initialIndex: 0,
  },
};

const appReducer: Reducer<IAppStore, ActionTypes> = produce(
  (state = APP_INITIAL_STATE, action) => {
    switch (action.type) {
      case SET_SHOW_TOAST:
        {
          const id = generateTabletGeneratedId();
          state.toast = { ...action.payload, isActive: true };
          state.toastManager.push({ ...action.payload, isActive: true, id });
        }
        break;
      case REMOVE_TOAST_ELEMENT:
        state.toastManager = state.toastManager.filter(
          toast => toast.id !== action.payload,
        );
        break;
      case SET_HIDE_TOAST:
        state.toast = { ...state.toast, isActive: false };
        break;
      case SET_PULLING_DATA:
        state.isPullingData = action.payload;
        break;
      case SET_IS_LOADING:
        state.isLoading = action.payload;
        break;
      case SET_IS_LOADING_MODAL:
        state.isLoadingModal = action.payload;
        break;
      case SET_TOKEN:
        state.token = action.payload;
        break;
      case SET_USER_DETAILS:
        state.userDetails = action.payload;
        break;
      case SET_PORTALS:
        state.portals = action.payload;
        break;
      case SET_LOCATION_ID:
        state.locationId = action.payload;
        break;
      case SET_ORGANIZATION_ID:
        state.organizationId = action.payload;
        break;
      case SET_ORGANIZATIONS:
        state.organizations = action.payload;
        break;
      case SET_SHOW_CONFORMATION_MODAL:
        state.confirmationModal = action.payload;
        break;
      case SET_SHOW_REFRESH_TOAST:
      case SET_SHOW_SETTINGS_REFRESH_TOAST:
        state.refreshToast = { ...action.payload, isActive: true };
        break;
      case SET_HIDE_REFRESH_TOAST:
        state.refreshToast = { ...state.refreshToast, isActive: false };
        break;
      case SET_VIRTUALIZED_NAVIGATION:
        state.virtualizedNavigation = {
          ...state.virtualizedNavigation,
          ...action.payload,
        };
        break;
      default:
        return state;
    }
  },
);

export default appReducer;

import Box from '@app/components/common/Box';
import Collapsible from '@app/components/common/List/Collapsible';
import ListItem from '@app/components/common/List/ListItem';
import { ITab } from '@app/components/common/Tabs/types';
import {
  resetPathsByBucketNavigation,
  resetPathsByMainPath,
} from '@app/helpers/restoration';
import { bindActionCreators } from '@reduxjs/toolkit';
import { ParseKeys } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getPathWithOrgData } from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { BUCKET_PATH_INDEX, MODULE_PATH_INDEX } from '@app/constants';
import { checkForAllChangesAndNavigate } from '@app/state/app/actions';
import {
  TCollapsibleListsReports,
  TCollapsibleListsReportsState,
} from '../types';
import { REPORTS_URLS } from '@app/router/types';

const TABS_DATA: ITab[] = [
  {
    id: 1,
    key: 'quickReport',
    name: 'loggedIn.sideBar.reports.quickReport',
  },
  {
    id: 2,
    key: 'favorites',
    name: 'loggedIn.sideBar.reports.favorites',
  },
  {
    id: 3,
    key: 'saved',
    name: 'loggedIn.sideBar.reports.saved',
    subTabs: [
      {
        id: 30,
        key: 'itemComboSalesReports',
        name: 'loggedIn.sideBar.reports.itemComboSales',
      },
      {
        id: 31,
        key: 'salesOverviewReports',
        name: 'loggedIn.sideBar.reports.salesOverview',
      },
      {
        id: 32,
        key: 'endOfShiftReports',
        name: 'loggedIn.sideBar.reports.endOfShift',
      },
      {
        id: 33,
        key: 'creditCardBatchReports',
        name: 'loggedIn.sideBar.reports.creditCardBatch',
      },
      {
        id: 34,
        key: 'employeeSalesAndTipsReports',
        name: 'loggedIn.sideBar.reports.employeeSalesAndTips',
      },
      {
        id: 35,
        key: 'voidReasonsReports',
        name: 'loggedIn.sideBar.reports.voidReasons',
      },
      {
        id: 36,
        key: 'inventoryOnHandReports',
        name: 'loggedIn.sideBar.reports.inventoryOnHand',
      },
      {
        id: 37,
        key: 'creditBalancesReports',
        name: 'loggedIn.sideBar.reports.creditBalances',
      },
    ],
  },
];

const ReportsTabs = () => {
  // Redux
  const dispatch = useDispatch();
  const checkForChangesAndNavigate = bindActionCreators(
    checkForAllChangesAndNavigate,
    dispatch,
  );

  // Local state
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigateWithOrg();

  const isFavorite =
    new URLSearchParams(window.location.search).get('isFavorite') === 'true';

  const [collapsibleItems, setCollapsibleItems] =
    useState<TCollapsibleListsReportsState>({
      saved: false,
    });

  const [_activeTab, setSelectedTab] = useState<
    { tabId: number; subTabId: number | null } | undefined
  >();

  const activeTab = isFavorite ? { tabId: 2, subTabId: null } : _activeTab;

  const getNavigationPath = (tabId: number, subTabId: number | null) => {
    return `reports/${
      subTabId
        ? REPORTS_URLS[subTabId.toString() as keyof typeof REPORTS_URLS]
        : REPORTS_URLS[tabId.toString() as keyof typeof REPORTS_URLS]
    }`;
  };

  const currentPath = location.state?.currentPath || location.pathname;

  const handleSelectCurrentTab = (path: string) => {
    let pathIndex = Object.entries(REPORTS_URLS).find(
      ([, pathName]) => pathName === path,
    )?.[0];
    if (isNaN(Number(pathIndex))) {
      pathIndex = pathIndex = Object.entries(REPORTS_URLS).find(
        ([, pathName]) =>
          pathName === path.split('/')[0] + '/' + path.split('/')[1],
      )?.[0];
    }

    if (!pathIndex) return;

    if (path.split('/').length > 1) {
      const mainPath =
        Object.values(REPORTS_URLS).indexOf(path.split('/')[0]) + 1;

      setSelectedTab({ tabId: mainPath, subTabId: Number(pathIndex) });
      const keyToNavigate = path.split('/')[0];
      handleCloseTheOtherCollapsibleItems(keyToNavigate);
    } else {
      if (pathIndex?.[0] === '3') {
        setSelectedTab({ tabId: 3, subTabId: Number(pathIndex) });
      } else setSelectedTab({ tabId: Number(pathIndex), subTabId: null });
      handleCloseTheOtherCollapsibleItems(path);
    }
  };

  useEffect(() => {
    let path = location.pathname.replace(getPathWithOrgData('/reports/'), '');

    // if path ends with /[number] or /[number]/ then remove it
    if (path.match(/\/\d+\/?$/)) {
      path = path.replace(/\/\d+\/?$/, '');
    }

    handleSelectCurrentTab(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleOnSelectedTabChange = (
    tabId: number,
    subTabId: number | null,
    isSubSubTab: boolean,
    openInNewTab?: boolean,
  ) => {
    checkForChangesAndNavigate(() => {
      const newPath = getPathWithOrgData(getNavigationPath(tabId, subTabId));
      if (openInNewTab) return window.open(newPath, '_blank');

      setSelectedTab({ tabId, subTabId });
      const _currentPath = currentPath;

      if (currentPath !== newPath) {
        navigate(newPath);

        const currentModule = _currentPath.split('/')[MODULE_PATH_INDEX];
        resetPathsByBucketNavigation(
          currentModule,
          newPath.split('/')[BUCKET_PATH_INDEX],
          newPath.split('/')[5],
        );

        if (currentModule && newPath.includes(currentModule)) return;

        let keyToNavigate = newPath.split('/')[BUCKET_PATH_INDEX];
        if (isSubSubTab) {
          keyToNavigate = newPath.split('/')[5];
        }

        handleCloseTheOtherCollapsibleItems(keyToNavigate);
        resetPathsByMainPath('/reports/', newPath);
      } else {
        handleChangeCollapsibleItems(newPath as TCollapsibleListsReports);
      }
    });
  };

  const handleChangeCollapsibleItems = (
    key: TCollapsibleListsReports,
    forceToggle?: boolean,
  ) => {
    if ((key in collapsibleItems && !collapsibleItems[key]) || forceToggle)
      setCollapsibleItems(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const handleCloseTheOtherCollapsibleItems = (keyToNavigate: string) => {
    setCollapsibleItems(prev => {
      const newState = { ...prev };
      if (keyToNavigate !== 'favorites' && keyToNavigate !== 'quick-report') {
        newState.saved = true;
      } else {
        newState.saved = false;
      }
      return newState;
    });
  };

  return (
    <>
      {TABS_DATA.map(tab =>
        tab.subTabs ? (
          <Box key={tab.id} csx={{ marginLeft: '15px' }}>
            <Collapsible
              collapsibleKey={tab.key}
              csx={{
                gap: '5px',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '5px',
                paddingLeft: '15px',
              }}
              isExpanded={collapsibleItems[tab.key as TCollapsibleListsReports]}
              listItemProps={{
                isActive: activeTab?.tabId === tab.id,
                text: t(tab.name as ParseKeys),
                onClick: openInNewTab => {
                  if (tab.subTabs)
                    handleOnSelectedTabChange(
                      tab.id,
                      tab.subTabs[0].id,
                      false,
                      openInNewTab,
                    );
                },
                onIconRightClick: () => {
                  handleChangeCollapsibleItems(
                    tab.key as TCollapsibleListsReports,
                    true,
                  );
                },
                isLink: getPathWithOrgData(
                  getNavigationPath(tab.id, tab.subTabs[0].id),
                ),
              }}>
              {tab.subTabs.map(subTab =>
                subTab.subTabs ? (
                  <Collapsible
                    collapsibleKey={subTab.key}
                    csx={{
                      gap: '5px',
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '5px',
                      paddingLeft: '15px',
                    }}
                    key={tab.id}
                    isExpanded={
                      activeTab?.subTabId
                        ?.toString()
                        .includes(subTab.id.toString()) ||
                      collapsibleItems[subTab.key as TCollapsibleListsReports]
                    }
                    listItemProps={{
                      isActive: activeTab?.subTabId
                        ?.toString()
                        .includes(subTab.id.toString()),
                      text: t(subTab.name as ParseKeys),
                      onClick: openInNewTab => {
                        if (subTab.subTabs)
                          handleOnSelectedTabChange(
                            tab.id,
                            subTab.subTabs[0].id,
                            true,
                            openInNewTab,
                          );
                      },
                      onIconRightClick: () => {
                        if (
                          activeTab?.subTabId
                            ?.toString()
                            .includes(subTab.id.toString())
                        ) {
                          setSelectedTab({
                            tabId: tab.id,
                            subTabId: null,
                          });
                        } else {
                          const currentSubTabKey = currentPath
                            .split('/')
                            .at(-1) as string;

                          if (subTab?.subTabs) {
                            const subSubTabId = subTab.subTabs.find(
                              _subTab => _subTab.key === currentSubTabKey,
                            )?.id;

                            if (subSubTabId) {
                              setSelectedTab({
                                tabId: tab.id,
                                subTabId: subSubTabId,
                              });
                            } else {
                              handleChangeCollapsibleItems(
                                subTab.key as TCollapsibleListsReports,
                                true,
                              );
                            }
                          }
                        }
                      },
                      isLink: getPathWithOrgData(
                        getNavigationPath(tab.id, subTab.subTabs[0].id),
                      ),
                    }}>
                    {subTab.subTabs.map(subSubTab => (
                      <ListItem
                        key={`${tab.id}_subTab_${subTab.id}_subTab_${subSubTab.id}`}
                        isSubItem
                        isActive={activeTab?.subTabId === subSubTab.id}
                        text={t(subSubTab.name as ParseKeys)}
                        onClick={openInNewTab =>
                          handleOnSelectedTabChange(
                            tab.id,
                            subSubTab.id,
                            false,
                            openInNewTab,
                          )
                        }
                        isLink={getPathWithOrgData(
                          getNavigationPath(tab.id, subSubTab.id),
                        )}
                      />
                    ))}
                  </Collapsible>
                ) : (
                  <ListItem
                    key={`${tab.id}_subTab_${subTab.id}`}
                    isSubItem
                    isActive={activeTab?.subTabId === subTab.id}
                    text={t(subTab.name as ParseKeys)}
                    onClick={openInNewTab =>
                      handleOnSelectedTabChange(
                        tab.id,
                        subTab.id,
                        false,
                        openInNewTab,
                      )
                    }
                    isLink={getPathWithOrgData(
                      getNavigationPath(tab.id, subTab.id),
                    )}
                  />
                ),
              )}
            </Collapsible>
          </Box>
        ) : (
          <Box key={tab.id} csx={{ paddingLeft: '15px' }}>
            <ListItem
              isSubItem
              isActive={activeTab?.tabId === tab.id}
              text={t(tab.name as ParseKeys)}
              onClick={openInNewTab =>
                handleOnSelectedTabChange(tab.id, null, false, openInNewTab)
              }
              isLink={getPathWithOrgData(getNavigationPath(tab.id, null))}
            />
          </Box>
        ),
      )}
    </>
  );
};

export default ReportsTabs;

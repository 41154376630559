import { TCsx } from '@emotion/react';
import { ReactNode } from 'react';
import { IActionOption } from '../../ActionButtons/types';

export enum EAccordionSectionOptionType {
  ACTION_BUTTON = 'actionButton',
  ELLIPSIS = 'ellipsis',
}
export interface IAccordionSection {
  id?: string;
  index?: number;
  info?: string;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  children?: ReactNode;
  disabledLayer?: ReactNode;
  isExpanded?: boolean;
  onToggleExpanded?: (index: number) => void;
  expandedHeight?: string;
  required?: boolean;
  collapsedHeaderComponent?: ReactNode;
  titleTextContainerCsx?: TCsx;
  titleContainerCsx?: TCsx;
  isExpandable?: boolean;
  variant?: 'default' | 'danger';
  hasError?: boolean;
  optionType?: EAccordionSectionOptionType;
  options?: IActionOption[];
  optionsDisabled?: boolean;
  isTitleUpperCase?: boolean;
  onOptionsClickDisabled?: () => void;
  noPadding?: boolean;
  isEditButtonDisabled?: boolean;
  onEditAction?: () => void;
  onEditClickDisabled?: () => void;
}

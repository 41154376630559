import { BREAKPOINTS } from '@app/theme/themes';
import { useEffect, useState } from 'react';

const useWindowSize = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < parseFloat(BREAKPOINTS['mobile'].max)) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobile };
};

export default useWindowSize;

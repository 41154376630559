import DotsDisabled from '@app/assets/icons/dots-disabled.svg?react';
import Icon from '../..';
import { IIcon } from '../../Icon';
import { useTheme } from '@emotion/react';
import Box from '@app/components/common/Box';

const ThreeDots = ({
  size = '32px',
  color = 'persistentDarkerBlue',
  isDisabled = false,
  csx,
}: IIcon & { isDisabled?: boolean }) => {
  const theme = useTheme();

  return (
    <Box csx={csx}>
      {isDisabled ? (
        <DotsDisabled
          css={[
            {
              width: size,
              height: size,
              marginTop: '2px',
            },
          ]}
          color={theme.colors.lightGrey}
        />
      ) : (
        <Icon name="MdMoreHoriz" size={size} color={color} />
      )}
    </Box>
  );
};

export default ThreeDots;

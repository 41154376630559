import { IDashboardLayout } from '@westondev/tableturn-core';
import { GridStackWidget } from 'gridstack';

export type TWidgetTypes = 'barChart' | 'pieChart' | 'dataTable';

export enum EWidgetTypes {
  BAR_CHART = 'barChart',
  PIE_CHART = 'pieChart',
  DATA_TABLE = 'dataTable',
}

export type TDashboardModes = 'edit' | 'view' | 'preview' | 'create';

export enum EDashboardModes {
  EDIT = 'edit',
  VIEW = 'view',
  PREVIEW = 'preview',
  CREATE = 'create',
}
export interface IWidget {
  id: string;
  title: string;
  type: TWidgetTypes;
  parameters: Record<string, any>;
}

export interface IWidgets {
  [key: string]: IWidget | null;
}

export interface IDashboard {
  id: number | string;
  name: string;
  layout: IDashboardLayout;
  widgets: IWidgets;
}

export interface IDashboardGridStackElement
  extends Omit<GridStackWidget, 'id'> {
  id: string | number;
}

export interface ICurrentEditDashboard {
  selectedDashboard: IDashboard;
  isBottomSheetOpen: boolean;
  selectedElements: string[];
}

export interface IHomeStore {
  currentDashboard: IDashboard | null;
  currentEditDashboard: ICurrentEditDashboard | null;
}

export const HOME_INITIAL_STATE: IHomeStore = {
  currentDashboard: null,
  currentEditDashboard: null,
};

export const SET_CURRENT_DASHBOARD = 'SET_CURRENT_DASHBOARD';
export const SET_CURRENT_EDIT_DASHBOARD = 'SET_CURRENT_EDIT_DASHBOARD';
export const SET_DASHBOARD_BOTTOM_SHEET_OPEN =
  'SET_DASHBOARD_BOTTOM_SHEET_OPEN';
export const SET_CURRENT_EDIT_DASHBOARD_LAYOUT =
  'SET_CURRENT_EDIT_DASHBOARD_LAYOUT';
export const SET_CURRENT_EDIT_DASHBOARD_WIDGET =
  'SET_CURRENT_EDIT_DASHBOARD_WIDGET';
export const SET_CURRENT_EDIT_SELECTED_DASHBOARD =
  'SET_CURRENT_EDIT_SELECTED_DASHBOARD';

//Action creator types
export type ActionTypes =
  | {
      type: typeof SET_CURRENT_DASHBOARD;
      payload: IDashboard | null;
    }
  | {
      type: typeof SET_CURRENT_EDIT_DASHBOARD;
      payload: ICurrentEditDashboard | null;
    }
  | {
      type: typeof SET_DASHBOARD_BOTTOM_SHEET_OPEN;
      payload: boolean;
    }
  | {
      type: typeof SET_CURRENT_EDIT_SELECTED_DASHBOARD;
      payload: IDashboard;
    }
  | {
      type: typeof SET_CURRENT_EDIT_DASHBOARD_LAYOUT;
      payload: IDashboardLayout;
    }
  | {
      type: typeof SET_CURRENT_EDIT_DASHBOARD_WIDGET;
      payload: IWidgets;
    };

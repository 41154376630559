import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import { WithTranslation } from 'react-i18next';
import DashboardsSubsection from './DashboardsSubsection';

const WebHomeScreen = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  return (
    <AccordionSection
      title={t('settingsModule.roleDetailsSettings.webHomeScreen.title')}
      {...props}>
      <DashboardsSubsection />
    </AccordionSection>
  );
};

export default WebHomeScreen;

import { baseURL } from '@app/helpers/apiCall';
import { createSocket } from '@app/helpers/socket';
import { actionCreatorsWeb } from '@app/state';
import {
  selectLocationId,
  selectOrganizationId,
  selectToken,
} from '@app/state/selectors/appSelectors';
import {
  actionCreatorsApp,
  RESET_GLOBAL_STATE,
} from '@westondev/tableturn-core';
import React, { useCallback, useEffect, useRef } from 'react';
import { ProviderProps, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createBroadCastChannel } from '@app/helpers/broadcast';
import { switchOrganization } from '@app/state/app/actions';
import router from '@app/router/router';

interface IListenerComponent {
  children: React.ReactElement | React.ReactElement[];
  store: ProviderProps['store'];
}

const WS_CLOSE_CODES = {
  CLOSED_BY_APP: 1000,
  CLOSED_BY_SERVER: 1001,
};

const generateAndSetTabKey = () => {
  const _tabKey = Math.random().toString(36).substr(2, 9);
  const isTabKey = sessionStorage.getItem('tabKey');
  if (isTabKey) return isTabKey;
  sessionStorage.setItem('tabKey', _tabKey);
  return _tabKey;
};

const ListenerComponent = ({ children, store }: IListenerComponent) => {
  const { setIsWSActive } = bindActionCreators(
    actionCreatorsApp,
    useDispatch(),
  );

  const socketRef = useRef<ReturnType<typeof createSocket>>();

  const { webSocketRouterWeb } = bindActionCreators(
    actionCreatorsWeb,
    useDispatch(),
  );
  const authToken = useSelector(selectToken);
  const orgId = useSelector(selectOrganizationId);
  const locationId = useSelector(selectLocationId);

  const tabKey = generateAndSetTabKey();

  const connectWS = useCallback(
    (prevWebSocket?: WebSocket) => {
      if (!authToken || !orgId || !locationId) {
        return;
      }

      if (
        prevWebSocket &&
        prevWebSocket?.url ===
          `${baseURL.replace(
            'https://',
            'wss://',
          )}/s/organization/${orgId}/location/${locationId}/ws`
      ) {
        prevWebSocket.close(WS_CLOSE_CODES.CLOSED_BY_APP);
      }
      const socket = createSocket(authToken, orgId, locationId);
      socket.binaryType = 'blob';
      socketRef.current = socket;

      let shouldDisplayWSAlert = false;

      socket.onopen = () => {
        console.info('Socket connected successfully');
        setIsWSActive(true);
        shouldDisplayWSAlert = true;
      };

      socket.onerror = error => {
        console.info('WS ERROR: ', error);
        socket.close();
      };
      socket.onmessage = async event => {
        if (event.data instanceof Blob) {
          try {
            const text = await event.data.text();
            const eventData = JSON.parse(text);

            if (eventData.isCompressed) {
              // Create a blob from the base64 data
              const binaryString = atob(eventData.data);
              const bytes = new Uint8Array(binaryString.length);
              for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
              }
              const blob = new Blob([bytes]);

              // Use DecompressionStream
              const ds = new DecompressionStream('gzip');
              const decompressedStream = blob.stream().pipeThrough(ds);
              const decompressedBlob = await new Response(
                decompressedStream,
              ).blob();
              const decompressedText = await decompressedBlob.text();

              webSocketRouterWeb(JSON.parse(decompressedText));
            } else {
              webSocketRouterWeb(eventData.data);
            }
          } catch (error) {
            console.error('Error processing WebSocket message:', error);
          }
        } else {
          webSocketRouterWeb(JSON.parse(event.data));
        }
      };

      socket.onclose = ev => {
        //Cancel previous request to connect so there's only 1 request active
        prevWebSocket && prevWebSocket.close();

        shouldDisplayWSAlert && setIsWSActive(false);

        if (ev.code === WS_CLOSE_CODES.CLOSED_BY_APP) {
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          console.info('ws closed by app component unmount');
          return;
        }
        // Connection failed
        console.info('ws closed by server');

        console.info('ws attempting reconnect...');

        setTimeout(() => {
          connectWS(socket);
        }, 1000);
      };

      return socket;
    },
    [authToken, locationId, orgId, setIsWSActive, webSocketRouterWeb],
  );

  useEffect(() => {
    if (authToken && orgId && locationId) {
      const socket = connectWS();

      return () => {
        if (!socket) {
          return;
        }
        socketRef.current = undefined;
        socket.close(WS_CLOSE_CODES.CLOSED_BY_APP);
      };
    }
  }, [authToken, orgId, locationId, connectWS]);

  useEffect(() => {
    const broadcastChannel = createBroadCastChannel('auth');
    const broadcastOrgChannel = createBroadCastChannel(
      `/${orgId}/${locationId}/organization`,
    );

    const handleOnMessage = (event: any) => {
      switch (event.action) {
        case 'logout':
          localStorage.clear();
          store.dispatch({ type: RESET_GLOBAL_STATE });

          if (socketRef.current) {
            socketRef.current.close(WS_CLOSE_CODES.CLOSED_BY_APP);
          }
          broadcastChannel.close();

          if (window.location.pathname.includes('/org-invite')) return;
          window.location.replace('/auth/sign-in');
          break;
        case 'login':
        case 'refresh':
          if (
            event.action === 'login' &&
            window.location.pathname.includes('/org-invite')
          )
            return;
          if (event.redirect) window.location.replace(event.redirect);
          else window.location.reload();
          break;
        case 'switchOrg':
          if (socketRef.current && event.tabKey === tabKey) {
            socketRef.current.close(WS_CLOSE_CODES.CLOSED_BY_APP);

            store.dispatch(switchOrganization() as any);
            localStorage.removeItem(`orgData-${orgId}-${locationId}`);
            router.navigate('/my-organizations', {
              state: { isAutoSelectOrgDisabled: true },
            });
          }
          break;

        default:
          break;
      }
    };

    broadcastChannel.addEventListener('message', handleOnMessage);
    broadcastOrgChannel.addEventListener('message', handleOnMessage);

    return () => {
      broadcastChannel.removeEventListener('message', handleOnMessage);
      broadcastOrgChannel.removeEventListener('message', handleOnMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, locationId]);

  return <>{children}</>;
};

export default ListenerComponent;

import React from 'react';
import Box from '../Box';
import Typography from '../Typography';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

type TDataItem = {
  label: string;
  value: number;
};

interface IDaTableProps {
  data: TDataItem[];
  isMoney?: boolean;
  title?: string;
  width?: string | number;
  height?: string | number;
}

const formatValue = (value: number, isMoney: boolean) => {
  if (!value) return '$0.00';
  if (isMoney)
    return (
      '$' +
      value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  else return value.toFixed(2);
};

const DataTable: React.FC<IDaTableProps> = ({
  data,
  isMoney = true,
  title,
  width = '100%',
  height = '100%',
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      csx={{
        width,
        height,
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box csx={{ flexGrow: 1 }}>
        <Typography color="textBlue" fontWeight="bold" variant="subtitle">
          {title}
        </Typography>
      </Box>
      <Box csx={{ flexGrow: 1 }}>
        {data.length > 0 ? (
          <>
            {data.map((item, index) => (
              <Box
                key={`data-summary-item-${index}-${item.label.replace(
                  ' ',
                  '-',
                )}`}
                csx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '40px',
                  borderBottom:
                    index + 1 < data.length
                      ? `1px solid ${theme.colors.lighterGrey}`
                      : 'none',
                }}>
                <Typography
                  fontWeight={index + 1 < data.length ? 'regular' : 'bold'}>
                  {item.label}
                </Typography>
                <Typography
                  fontWeight={index + 1 < data.length ? 'regular' : 'bold'}>
                  {formatValue(item.value, isMoney)}
                </Typography>
              </Box>
            ))}
          </>
        ) : (
          <Typography align="center">
            {t('loggedIn.dashboardsModule.dataSummaryWidget.noData')}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DataTable;

import Checkbox from '@app/components/common/Checkbox';
import Dropdown from '@app/components/common/Dropdown';
import FontsDropdown from '@app/components/common/Dropdown/FontsDropdown/FontsDropdown';
import Grid from '@app/components/common/Grid';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import {
  ELEMENT_SPACING_OPTIONS,
  IGuestReceiptSettings,
  KITCHEN_TICKET_FONT_SIZE_OPTIONS,
  KITCHEN_TICKET_FONT_WEIGHT_OPTIONS,
  SettingsSectionId,
  actionCreatorsSettings,
  elementSpacingOptionsFactory,
  kitchenTicketFontSizeOptionsFactory,
  kitchenTicketFontWeightOptionsFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const dropDownSizes = {
  mb: 12,
  sm: 6,
  lg: 3,
};

const SECTION_ID = SettingsSectionId.TICKET_DISPLAY;
const getMemoizedSettingsData =
  makeSelectSettingsData<IGuestReceiptSettings>(SECTION_ID);

const CheckDisplay = ({ t }: WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedSettingsData);

  const kitchenTicketFontSizeOptions = useMemo(
    () => kitchenTicketFontSizeOptionsFactory(t),
    [t],
  );

  const fontSizeValue = useMemo(
    () => KITCHEN_TICKET_FONT_SIZE_OPTIONS.indexOf(data?.fontSize) + 1,
    [data?.fontSize],
  );

  const fontWeightValue = useMemo(
    () => KITCHEN_TICKET_FONT_WEIGHT_OPTIONS.indexOf(data?.fontWeight) + 1,
    [data?.fontWeight],
  );

  const elementSpacingOptions = useMemo(
    () => elementSpacingOptionsFactory(),
    [],
  );

  const kitchenTicketFontWeightOptions = useMemo(
    () => kitchenTicketFontWeightOptionsFactory(t),
    [t],
  );

  return (
    <Grid columnGap={25} rowGap={25}>
      <Grid.Item mb={12}>
        <Grid columnGap={25} rowGap={25}>
          <Grid.Item mb={12} sm={6} md={4} lg={3}>
            <Checkbox
              label={t(
                'loggedIn.settingsModule.guestCheckSettings.checkDisplay.showLogo',
              )}
              checked={data ? data.showLogo : false}
              onChange={showLogo =>
                setValue<IGuestReceiptSettings>({ showLogo }, SECTION_ID)
              }
            />
          </Grid.Item>
          <Grid.Item mb={12} sm={6} md={4} lg={3}>
            <Checkbox
              label={t(
                'settingsModule.guestReceiptSettings.checkDisplay.showCheckTotals',
              )}
              checked={data ? data.showCheckTotals : false}
              onChange={showCheckTotals =>
                setValue<IGuestReceiptSettings>({ showCheckTotals }, SECTION_ID)
              }
            />
          </Grid.Item>
          <Grid.Item mb={12} sm={6} md={4} lg={3}>
            <Checkbox
              label={t(
                'settingsModule.guestReceiptSettings.checkDisplay.printMerchantCopy',
              )}
              checked={data ? data.printMerchantCopy : false}
              onChange={printMerchantCopy =>
                setValue<IGuestReceiptSettings>(
                  { printMerchantCopy },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
        </Grid>
      </Grid.Item>

      <Grid.Item {...dropDownSizes}>
        <FontsDropdown
          label={t(
            'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.fontSize.label',
          )}
          placeholder={t(
            'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.fontSize.label',
          )}
          data={[kitchenTicketFontSizeOptions]}
          value={fontSizeValue}
          onChange={newFontSize =>
            setValue<IGuestReceiptSettings>(
              {
                fontSize: KITCHEN_TICKET_FONT_SIZE_OPTIONS[newFontSize - 1],
              },
              SECTION_ID,
            )
          }
        />
      </Grid.Item>
      <Grid.Item {...dropDownSizes}>
        <Dropdown
          label={t(
            'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.fontWeight.label',
          )}
          placeholder={t(
            'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.fontWeight.label',
          )}
          data={[kitchenTicketFontWeightOptions]}
          value={fontWeightValue}
          onChange={newFontWeight =>
            setValue<IGuestReceiptSettings>(
              {
                fontWeight:
                  KITCHEN_TICKET_FONT_WEIGHT_OPTIONS[newFontWeight - 1],
              },
              SECTION_ID,
            )
          }
        />
      </Grid.Item>
      <Grid.Item {...dropDownSizes}>
        <Dropdown
          label={t(
            'settingsModule.kitchenTicketSettings.ticketDisplay.elementSpacing.label',
          )}
          placeholder={t(
            'settingsModule.kitchenTicketSettings.ticketDisplay.elementSpacing.label',
          )}
          data={[elementSpacingOptions]}
          value={ELEMENT_SPACING_OPTIONS.indexOf(data?.spaceBetweenItems) + 1}
          onChange={newSpaceBetweenItems =>
            setValue<IGuestReceiptSettings>(
              {
                spaceBetweenItems:
                  ELEMENT_SPACING_OPTIONS[newSpaceBetweenItems - 1],
              },
              SECTION_ID,
            )
          }
        />
      </Grid.Item>
    </Grid>
  );
};

export default CheckDisplay;

import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import MoneyInput from '@app/components/common/MoneyInput';
import Switch from '@app/components/common/Switch';
import { makeSelectSettingsData } from '@app/state/selectors/settingsSelectors';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import {
  actionCreatorsSettings,
  DUAL_PRICING_LIST_PRICE_OPTIONS,
  EDualPricingListPrice,
  IPaymentsSettings,
  paymentsDualPricingListPriceOptionsFactory,
  SettingsSectionId,
} from '@westondev/tableturn-core/';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

type IDualPricing = IAccordionSection & WithTranslation;

const gridItemSizing = {
  mb: 12,
  sm: 6,
  md: 6,
  lg: 4,
  xl: 3,
};

const SECTION_ID = SettingsSectionId.DUAL_PRICING;
const getMemoizedItemData =
  makeSelectSettingsData<IPaymentsSettings>(SECTION_ID);

const DualPricing = ({ t, ...props }: IDualPricing) => {
  const dispatch = useDispatch();

  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );

  const { data } = useSelector(getMemoizedItemData);

  const listPriceOptions = useMemo(
    () => paymentsDualPricingListPriceOptionsFactory(t),
    [t],
  );

  const listPriceDropdownValue = useMemo(
    () => DUAL_PRICING_LIST_PRICE_OPTIONS.indexOf(data?.listPrice) + 1,
    [data?.listPrice],
  );

  const activeSwitch = (
    <Switch
      checked={data ? data.dualPricing || false : false}
      showDefaultLabel
      onChange={isActive => {
        setValue(
          {
            dualPricing: isActive,
          },
          SECTION_ID,
        );
      }}
    />
  );

  return (
    <AccordionSection
      title={t('settingsModule.paymentsSettings.dualPricing.title')}
      titleTextContainerCsx={{ minWidth: '150px' }}
      collapsedHeaderComponent={
        <Box
          csx={[
            {
              display: 'flex',
              height: '100%',
              gap: '10px',
            },
          ]}>
          <Divider direction="vertical" />
          {activeSwitch}
        </Box>
      }
      {...props}>
      {activeSwitch}
      <Divider csx={{ marginBlock: '20px' }} />
      <Grid rowGap={20} columnGap={20} csx={{ alignItems: 'flex-start' }}>
        <Grid.Item {...gridItemSizing} csx={displayFlexEndColumnStyles}>
          <Dropdown
            label={t(
              'settingsModule.paymentsSettings.dualPricing.listPrice.label',
            )}
            placeholder={t(
              'settingsModule.paymentsSettings.dualPricing.listPrice.label',
            )}
            info={t(
              'settingsModule.paymentsSettings.dualPricing.listPrice.label',
            )}
            data={[listPriceOptions]}
            value={listPriceDropdownValue || 0}
            onChange={listPrice => {
              setValue(
                {
                  listPrice: DUAL_PRICING_LIST_PRICE_OPTIONS[listPrice - 1],
                },
                SECTION_ID,
              );
            }}
            isDisabled={!data?.dualPricing}
          />
        </Grid.Item>
        <Grid.Item
          {...gridItemSizing}
          csx={[displayFlexEndColumnStyles, { paddingTop: 14 }]}>
          <MoneyInput
            placeholder={t(
              `settingsModule.paymentsSettings.dualPricing.price.${data?.listPrice}`,
            )}
            label={t(
              `settingsModule.paymentsSettings.dualPricing.price.${data?.listPrice}`,
            )}
            info={t(
              `settingsModule.paymentsSettings.dualPricing.price.${data?.listPrice}`,
            )}
            prefix={data?.listPrice === EDualPricingListPrice.CASH ? '+' : '-'}
            suffix="%"
            disabled={!data?.dualPricing}
            value={data?.cardCashPrice || 0}
            precision={0}
            onValueChange={value =>
              setValue({ cardCashPrice: value }, SECTION_ID)
            }
          />
        </Grid.Item>
      </Grid>
    </AccordionSection>
  );
};

export default DualPricing;

import Card from '@app/components/common/Card';
import CardsContainer from '@app/components/common/CardsContainer';
import SelectionModal from '@app/components/common/SelectionModal';
import { useDashboardsSection } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';

const DashboardsSubsection = ({ t }: WithTranslation) => {
  const {
    isEditMode,
    setEditMode,
    openAddExistingDashboard,
    setOpenAddExistingDashboard,
    handleDefaultDashboardSelection,
    handleOnDisassociate,
    handleOnAssociate,
    dashboardStringIds,
    roleDashboards,
    defaultDashboardId,
  } = useDashboardsSection();

  return (
    <>
      <SelectionModal
        type="dashboards"
        onAssociate={handleOnAssociate}
        detailsScreenProps={{
          currentRelationsIds: dashboardStringIds,
          wantedEntity: 'dashboards',
        }}
        onModalClose={() => setOpenAddExistingDashboard(false)}
        active={openAddExistingDashboard}
        allowAssociateMultiple
        showDescriptionPanel={false}
        btnSuccessText={t('commonButtons.associate')}
        showSelectedButtons={true}
      />
      <Card.SubCardOptions
        title={t('menuScreen.common.settings.dashboards.plural')}
        subtitle={t(
          'settingsModule.roleDetailsSettings.webHomeScreen.description',
        )}
        onEditMode={roleDashboards.length ? setEditMode : undefined}
        actionOptions={[
          {
            text: t('components.actionButtons.addExistingDashboard'),
            icon: false,
            handler: () => setOpenAddExistingDashboard(true),
          },
        ]}>
        <CardsContainer
          data={roleDashboards}
          noElementsMessage={t(
            'settingsModule.roleDetailsSettings.webHomeScreen.dashboards.emptyCardsMessage',
          )}
          renderItem={dashboard => {
            return (
              <Card.Item
                title={dashboard.title}
                isActive
                showRemoveIcon={isEditMode}
                isSelected={dashboard.id === defaultDashboardId}
                onClick={() => {
                  handleDefaultDashboardSelection(dashboard.id);
                }}
                onRemoveClick={() => handleOnDisassociate(dashboard.id)}
              />
            );
          }}
        />
      </Card.SubCardOptions>
    </>
  );
};

export default DashboardsSubsection;

import { webErrorCodesDispatcher } from '@app/helpers/apiCall';
import {
  IPrintJobFilterParams,
  TPrintJobInfoResponse,
  TPrinterJobRow,
  TTransferPrintJobData,
  actionCreatorsPrinterCenter,
} from '@westondev/tableturn-core';
import {
  selectLocationId,
  selectOrganizationId,
} from '../selectors/appSelectors';
import { AppThunk } from '../store';

export const getPrinterJobsWeb =
  (printerId: number): AppThunk<Promise<TPrinterJobRow[]>> =>
  async (dispatch, getState) => {
    const state = getState();
    const organizationId = selectOrganizationId(state);
    const locationId = selectLocationId(state);

    return dispatch(
      actionCreatorsPrinterCenter.getPrinterJobs(
        printerId,
        organizationId,
        locationId,
        null,
        webErrorCodesDispatcher,
      ) as unknown as AppThunk<Promise<TPrinterJobRow[]>>,
    );
  };

export const replaceBackUpPrinterWeb =
  (id: number, newId: number | null): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const organizationId = selectOrganizationId(state);
    const locationId = selectLocationId(state);

    dispatch(
      actionCreatorsPrinterCenter.replaceBackupPrinter(
        id,
        newId,
        organizationId,
        locationId,
        null,
        webErrorCodesDispatcher,
      ) as unknown as AppThunk,
    );
  };

export const pauseResumePrinterWeb =
  (id: number, action: 'pause' | 'resume'): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const organizationId = selectOrganizationId(state);
    const locationId = selectLocationId(state);

    dispatch(
      actionCreatorsPrinterCenter.pauseResumePrinter(
        id,
        action,
        organizationId,
        locationId,
        null,
        webErrorCodesDispatcher,
      ) as unknown as AppThunk,
    );
  };

export const deletePrinterJobsWeb =
  (
    printjobsIds: string[],
    onSuccess: () => void,
    onError: () => void,
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const organizationId = selectOrganizationId(state);
    const locationId = selectLocationId(state);

    dispatch(
      actionCreatorsPrinterCenter.deletePrinterJobs(
        printjobsIds,
        onSuccess,
        onError,
        organizationId,
        locationId,
        null,
        webErrorCodesDispatcher,
      ) as unknown as AppThunk,
    );
  };

export const getPrintJobInfoWeb = (
  filterParams: IPrintJobFilterParams,
  currentData: TPrinterJobRow[] = [],
  page = 0,
  append = true,
): AppThunk<Promise<TPrintJobInfoResponse>> => {
  return (dispatch, getState) => {
    const state = getState();
    const organizationId = selectOrganizationId(state);
    const locationId = selectLocationId(state);

    return dispatch(
      actionCreatorsPrinterCenter.getPrintJobInfo(
        filterParams,
        currentData,
        organizationId,
        locationId,
        null,
        webErrorCodesDispatcher,
        page,
        append,
      ) as unknown as AppThunk<Promise<TPrintJobInfoResponse>>,
    );
  };
};

export const transferPrinterJobsWeb =
  (
    data: TTransferPrintJobData,
    onSuccess: () => void,
    onError: () => void,
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const organizationId = selectOrganizationId(state);
    const locationId = selectLocationId(state);

    dispatch(
      actionCreatorsPrinterCenter.transferPrinterJobs(
        data,
        onSuccess,
        onError,
        organizationId,
        locationId,
        null,
        webErrorCodesDispatcher,
      ) as unknown as AppThunk,
    );
  };

export const reprintPrinterJobsWeb =
  (
    data: TTransferPrintJobData,
    onSuccess: () => void,
    onError: () => void,
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const organizationId = selectOrganizationId(state);
    const locationId = selectLocationId(state);

    dispatch(
      actionCreatorsPrinterCenter.reprintPrinterJobs(
        data,
        onSuccess,
        onError,
        organizationId,
        locationId,
        null,
        webErrorCodesDispatcher,
      ) as unknown as AppThunk,
    );
  };

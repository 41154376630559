import { MQ_MIN_MEDIUM } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const SIDEBAR_WIDTH = '250px';

export const sideBarStyles = (theme: Theme) =>
  css({
    width: '250px',
    boxShadow: theme.shadows[1],
    backgroundColor: theme.colors.white,
    zIndex: 999,
    overflowY: 'auto',
    '.options': {
      paddingInline: '12px',
    },
    position: 'fixed',
    height: '100%',
    transform: 'translateX(-250px) translateZ(0px)',
    [MQ_MIN_MEDIUM]: {
      position: 'relative',
      transform: 'translateX(0px) translateZ(0px) !important',
    },
  });

export const locationContainerStyles = () =>
  css({
    height: '70px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingInline: '12px',
  });

export const sideBarOverlayStyles = () =>
  css({
    backgroundColor: 'black',
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: 998,
    [MQ_MIN_MEDIUM]: {
      display: 'none !important',
    },
  });

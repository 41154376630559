import { ReactNode } from 'react';
import Typography from '../../Typography';

interface IActionHeader {
  children: ReactNode;
}
const ActionHeader = ({ children }: IActionHeader) => {
  return (
    <Typography
      color="semanticBlue"
      variant="body"
      csx={{ marginBlock: '5px' }}>
      {children}
    </Typography>
  );
};

export default ActionHeader;

import { usePresence, useAnimate, m } from 'framer-motion';
import { useEffect } from 'react';

export const AnimatedOpacityContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          { opacity: 1 },
          {
            duration: 0.1,
          },
        );
      };
      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { opacity: 0 },
          {
            duration: 0,
          },
        );
        safeToRemove();
      };
      exitAnimation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent]);

  return (
    <m.div ref={scope} style={{ zIndex: 3 }} initial={{ opacity: 0 }}>
      {children}
    </m.div>
  );
};

import useOutsideClick from '@app/hooks/useOutsideClick';
import { actionCreatorsApp } from '@app/state';
import { isNil } from 'lodash';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Box from '../../Box';
import {
  captionStyles,
  emptyCaptionStyles,
  errorCaptionStyles,
  labelStyles,
} from '../../Input/styles';
import OptionsModal from '../../OptionsModal';
import ToolTip from '../../ToolTip';
import Typography from '../../Typography';
import DropdownButton from '../DropdownButton/DropdownButton';
import {
  dropDownContainerStyles,
  dropDownMenuStyles,
  dropdownMenuContentStyles,
} from '../styles';
import { IDropdownItem } from '../types';
import {
  ELabelVariants,
  TOnChangeItem,
  TOnChangeValue,
  TSingleSelectDropdown,
} from './types';
import { IDropdownItemCore } from '@westondev/tableturn-core';

const SingleSelectDropdown = ({
  placeholder,
  value,
  data,
  dismissOnSelect = true,
  returnItemOnChange,
  onChange,
  isDisabled = false,
  label,
  labelVariant = 'outside',
  emptyLabel,
  required,
  hideRequiredSymbol,
  info = label || 'TOOLTIP_INFO',
  csx,
  inputContainerCsx: inputContainerProps,
  customText = null,
  customTextColor,
  error = false,
  onActive,
  isLoading = false,
  loadingText = 'Loading',
  caption,
  showCaptionSpace,
  isEditable = true,
  showOptionsModal = false,
  noTooltip,
}: TSingleSelectDropdown) => {
  isDisabled = isDisabled || isLoading;
  //Redux
  const updatePortals = bindActionCreators(
    actionCreatorsApp.updatePortals,
    useDispatch(),
  );
  // Local state
  const childrenRef = useRef<HTMLDivElement>(null);
  const dropDownMenuRef = useRef<HTMLDivElement>(null);
  const dropDownContentRef = useRef<HTMLDivElement>(null);
  const [childrenRect, setChildrenRect] = useState<DOMRect | undefined>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IDropdownItem>({
    label: '',
    value: -1,
  });
  const [showHover, setShowHover] = useState(false);
  const [newTimeout, setNewTimeout] = useState<NodeJS.Timeout | null>(null);

  useOutsideClick(
    [childrenRef, dropDownMenuRef],
    true,
    (isExpandedValue: boolean) => {
      setIsExpanded(isExpandedValue);
      handleRemovePortal();
    },
  );

  const getDropdownPosition = () => {
    if (childrenRect && dropDownMenuRef.current) {
      const dropDownMenuRect = dropDownMenuRef.current.getBoundingClientRect();
      const dropdownMenuPosition =
        window.scrollY + childrenRect.y + childrenRect.height;
      const isOutsideScreen =
        dropdownMenuPosition + dropDownMenuRect.height >
        window.scrollY + window.innerHeight;

      return {
        width: childrenRect.width,
        transform: `translate(${childrenRect.x}px, ${
          isOutsideScreen
            ? window.scrollY + childrenRect.y - dropDownMenuRect.height
            : dropdownMenuPosition
        }px)`,
      };
    }
  };

  useEffect(() => {
    if (isNil(value)) return;

    const [selectedItem] = data.flat().filter(item => item.value === value);
    if (selectedItem) {
      setSelectedOption(selectedItem);
    } else {
      setSelectedOption({
        label: '',
        value: -1,
      });
    }
  }, [data, value]);

  useEffect(() => {
    const [selectedItem] = data.flat().filter(item => item.value === value);
    if (selectedItem && isExpanded) {
      setSelectedOption(selectedItem);
      const selectedIndex = data.flat().findIndex(item => item.value === value);

      dropDownContentRef.current &&
        dropDownContentRef.current.scrollTo({
          top: selectedIndex * 50,
          behavior: 'instant',
        });
    }
  }, [data, isExpanded, value]);

  useLayoutEffect(() => {
    if (childrenRef.current) {
      const initialChildrenRect = childrenRef.current.getBoundingClientRect();
      setChildrenRect(initialChildrenRect);
    }
  }, [isExpanded]);

  useEffect(() => {
    const handleGenerateNewChildrenRect = () => {
      if (dropDownMenuRef.current && childrenRef.current) {
        const newChildrenRect = childrenRef.current.getBoundingClientRect();
        setChildrenRect(newChildrenRect);
      }
    };
    const handleResize = () => {
      handleGenerateNewChildrenRect();
    };
    const handleWheel = (e: WheelEvent | TouchEvent) => {
      const target = e.target as HTMLElement;
      if (!target.closest('#portal-dropdown')) {
        dropDownMenuRef.current && isExpanded && setIsExpanded(false);
      }
    };
    window.addEventListener('resize', handleResize);
    window.addEventListener('wheel', handleWheel);
    window.addEventListener('touchmove', handleWheel);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('touchmove', handleWheel);
    };
  }, [isExpanded]);

  const handleOnPress = (onPress: () => void) => {
    if (dismissOnSelect) {
      setIsExpanded(false);
      handleRemovePortal();
    }
    onPress();
  };

  const handleSelectOption = (optionItem: IDropdownItemCore) => {
    if (dismissOnSelect) {
      setIsExpanded(false);
      handleRemovePortal();
    }
    isNil(value) && setSelectedOption(optionItem);
    if (onChange) {
      if (returnItemOnChange) {
        (onChange as TOnChangeItem)(optionItem);
      } else {
        (onChange as TOnChangeValue)(optionItem.value);
      }
    }
  };
  const handleOpenDropDown = () => {
    if (isEditable) {
      setIsExpanded(!isExpanded);
      if (!isExpanded) updatePortals('dropdown', 'add');
      else handleRemovePortal();
    }

    onActive && onActive();
  };

  const handleRemovePortal = () => {
    updatePortals('dropdown', 'remove');
  };

  const handlePopUp = () => {
    if (newTimeout) clearTimeout(newTimeout);
    setNewTimeout(
      setTimeout(() => {
        setShowHover(true);
      }, 500),
    );
  };

  return (
    <Box csx={[dropDownContainerStyles, csx]}>
      {(label || emptyLabel) && labelVariant !== ELabelVariants.INSIDE && (
        <label
          onMouseOver={handlePopUp}
          onMouseOut={() => {
            if (newTimeout) clearTimeout(newTimeout);
            setShowHover(false);
          }}
          css={labelStyles}
          className={`${isDisabled ? 'disabled' : ''}`}>
          {info && !noTooltip && (
            <div className="infoButton">
              <ToolTip
                content={info}
                showHover={showHover}
                direction="top-right"
                isDisabled={isDisabled}>
                <div />
              </ToolTip>
            </div>
          )}
          {!emptyLabel ? label : ''}
          {required && !hideRequiredSymbol && (
            <span className="required">*</span>
          )}
        </label>
      )}
      <div ref={childrenRef}>
        <DropdownButton
          isDisabled={isDisabled}
          isExpanded={isExpanded}
          isLoading={isLoading}
          handleOpenDropDown={handleOpenDropDown}
          hasSelectedOption={selectedOption.label !== ''}
          selectedOptionLabel={selectedOption.label}
          selectedOptionColor={selectedOption.textProps?.color}
          placeholder={placeholder}
          customText={customText}
          customTextColor={customTextColor}
          loadingText={loadingText}
          error={error}
          label={labelVariant === ELabelVariants.INSIDE ? label : ''}
          inputContainerProps={inputContainerProps}
        />
        {(!caption && !error && showCaptionSpace) ||
        (!caption && error && showCaptionSpace) ? (
          <span css={emptyCaptionStyles}></span>
        ) : (
          (caption || (error && caption)) && (
            <Typography csx={error ? errorCaptionStyles : captionStyles}>
              {caption || error}
            </Typography>
          )
        )}
      </div>
      {isExpanded &&
        (showOptionsModal ? (
          <OptionsModal
            title={placeholder}
            options={data.flat(1)}
            onChange={async (dropDownItemValue: number | null) => {
              (onChange as (value: number) => void)(dropDownItemValue || 0);
              setIsExpanded(false);
            }}
            isActiveByDefault={isExpanded}
            hideTriggerButton
            onCancel={() => setIsExpanded(false)}
            onOutsidePress={() => setIsExpanded(false)}
            value={value}
            isSearchable
          />
        ) : (
          createPortal(
            <div
              id="portal-dropdown"
              ref={dropDownMenuRef}
              css={[dropDownMenuStyles, getDropdownPosition()]}>
              <Box ref={dropDownContentRef} csx={dropdownMenuContentStyles}>
                {data.map((section, index) => (
                  <ul
                    key={`section${index}`}
                    css={{ borderTop: '1px solid #E0E0E0' }}>
                    {section.map((item, itemIndex) => (
                      <li
                        key={`item${itemIndex}`}
                        className={`${
                          selectedOption.value === item.value ? 'active' : ''
                        } ${item.inActive ? 'inActive' : ''} ${
                          item.isDisabled ? 'disabled' : ''
                        }`}
                        onClick={() => {
                          if (item.isDisabled) return;

                          if (item.onPress) handleOnPress(item.onPress);
                          else handleSelectOption(item);
                        }}>
                        {item.icon && (
                          <Box csx={{ marginRight: '8px', marginTop: '2px' }}>
                            {item.icon}
                          </Box>
                        )}
                        <Typography {...item.textProps}>
                          {item.label}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                ))}
              </Box>
            </div>,
            document.body,
          )
        ))}
    </Box>
  );
};

export default SingleSelectDropdown;

import LocationAndRoleSelectorModal from '@app/components/LoggedIn/MyOrganizations/LocationAndRoleSelectorModal';
import { skeletonStyles } from '@app/components/LoggedIn/MyOrganizations/OrganizationCard/styles';
import NavBar from '@app/components/LoggedIn/NavBar';
import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Skeleton from '@app/components/common/Skeleton';
import Typography from '@app/components/common/Typography';
import useUserOrganizations from '@app/hooks/useUserOrganizations';
import { IUserOrganization } from '@app/state/app/types';
import { useEffect, useLayoutEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import OrganizationCard from '../../../components/LoggedIn/MyOrganizations/OrganizationCard';
import {
  myOrganizationsContainerStyles,
  myOrganizationsStyles,
  organizationsStyles,
} from './styles';
import { useLocation } from 'react-router-dom';

const MyOrganizations = ({ t }: WithTranslation) => {
  const [isRoleSelectorModalActive, setIsRoleSelectorModalActive] =
    useState<IUserOrganization | null>(null);
  const isAutoSelectOrgDisabled = useLocation().state?.isAutoSelectOrgDisabled;
  const { organizations, isLoading } = useUserOrganizations();
  const handleOrganizationCardClick = (orgData: IUserOrganization) => {
    setIsRoleSelectorModalActive(orgData);
  };
  const handleCreateOrganization = () => {
    console.info('Create organization clicked');
  };

  useLayoutEffect(() => {
    document.title = 'My Organizations';
  }, []);

  useEffect(() => {
    if (!isAutoSelectOrgDisabled && organizations.length === 1) {
      handleOrganizationCardClick(organizations[0]);
    }
  }, [isAutoSelectOrgDisabled, organizations]);

  return (
    <>
      <LocationAndRoleSelectorModal
        isActive={isRoleSelectorModalActive ? true : false}
        onModalClose={() => {
          setIsRoleSelectorModalActive(null);
        }}
        organizationData={isRoleSelectorModalActive}
      />

      <Box csx={myOrganizationsContainerStyles}>
        <NavBar />
        <Box csx={myOrganizationsStyles}>
          <Typography variant="heading" fontWeight="medium" align="center">
            {t('loggedIn.myOrganizations.title')}
          </Typography>
          <Typography
            align="center"
            variant="subtitle"
            csx={{ marginTop: '5px' }}>
            {t('loggedIn.myOrganizations.description')}
          </Typography>
          <Divider csx={{ marginBlock: '30px' }} />
          <Box
            csx={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Box
              csx={{
                width: '100%',
                maxWidth: '900px',
              }}>
              <Box csx={[organizationsStyles]}>
                {isLoading ? (
                  Array.from({ length: 3 }).map((_, index) => (
                    <Skeleton key={`skeleton_${index}`} csx={skeletonStyles} />
                  ))
                ) : organizations.length > 0 ? (
                  <>
                    {organizations.map((organization, index) => (
                      <OrganizationCard
                        key={`organization_${organization.id}`}
                        id={organization.id}
                        index={index + 1}
                        name={organization.name}
                        image={organization.digitalLogoUrl}
                        onClick={() =>
                          handleOrganizationCardClick(organization)
                        }
                      />
                    ))}
                    <OrganizationCard
                      type="create"
                      onClick={handleCreateOrganization}
                    />
                  </>
                ) : (
                  <Box>
                    <Typography
                      variant="subtitle"
                      fontWeight="medium"
                      align="center"
                      color="darkGrey"
                      csx={{ marginBottom: '15px' }}>
                      {t('loggedIn.myOrganizations.noOrganizations')}
                    </Typography>
                    <OrganizationCard onClick={handleCreateOrganization} />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MyOrganizations;

import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import LoadingModal from '@app/components/common/LoadingModal';
import NoElementsFound from '@app/components/common/NoElementsFound';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import PrepStationsSelectionModal from '@app/components/common/SelectionModal/PrepStationsSelectionModal';
import PrintersSelectionModal from '@app/components/common/SelectionModal/PrintersSelectionModal';
import Spinner from '@app/components/common/Spinner';
import Table from '@app/components/common/Table';
import { ETableModes } from '@app/components/common/Table/types';
import Typography from '@app/components/common/Typography';
import { getDateFromUTC, getTimeFromUTC } from '@app/helpers/time/time';
import useGetPrinterJobs from '@app/hooks/useGetPrinterJobs';
import { actionCreatorsRegisterWeb } from '@app/state';
import {
  selectHasLoggedInUserAccessToDeletePrintJobs,
  selectHasLoggedInUserAccessToTransferPrintJobs,
} from '@app/state/users/usersSelectors';
import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { MQ_MAX_SMALL } from '@app/theme/types';
import { bindActionCreators } from '@reduxjs/toolkit';
import { RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import {
  EPrinterJobStatus,
  EPrinterJobType,
  IPrintJobFilterParams,
  IPrinterJobSelected,
  TPrinterJobRow,
  actionCreatorsApp,
  actionCreatorsPrinterCenter,
  concatStringsArrayWithAndText,
} from '@westondev/tableturn-core';
import { useCallback, useMemo, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PrintJobStatusFilter from './PrintJobStatusFilter';
import PrintJobStatusLabel from './PrintJobStatusLabel';
import { printerJobsStyles } from './styles';

const PRINT_JOBS_FILTER_PARAMS_INITIAL_STATE: IPrintJobFilterParams = {
  status: [EPrinterJobStatus.PENDING, EPrinterJobStatus.ERROR],
};

const PRINTER_SELECTION_TYPES: EPrinterJobType[] = [
  EPrinterJobType.CHECK,
  EPrinterJobType.RECEIPT,
];

const PRINTER_LOCATION_SELECTION_TYPES: EPrinterJobType[] = [
  EPrinterJobType.KITCHEN,
  EPrinterJobType.FIRE,
];

enum PrintJobsActions {
  TRANSFER = 'transfer',
  REPRINT = 'reprint',
}

interface IPrinterJobs extends WithTranslation {
  selectedPrinter: IPrinterJobSelected;
}
const PrinterJobs = ({ t, selectedPrinter }: IPrinterJobs) => {
  // Redux
  const dispatch = useDispatch();
  const {
    deletePrinterJobsWeb,
    transferPrinterJobsWeb,
    reprintPrinterJobsWeb,
  } = bindActionCreators(actionCreatorsRegisterWeb, dispatch);
  const { setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    dispatch,
  );

  const clearPrinterErrorsForPrintJobs = bindActionCreators(
    actionCreatorsPrinterCenter.clearPrinterErrorsForPrintJobs,
    dispatch,
  );

  const showDeleteButton = useSelector(
    selectHasLoggedInUserAccessToDeletePrintJobs,
  );
  const showTransferButton = useSelector(
    selectHasLoggedInUserAccessToTransferPrintJobs,
  );

  const hideActionButtons = !showDeleteButton && !showTransferButton;

  // Local state
  const columnHelper = createColumnHelper<TPrinterJobRow>();

  const [selectedJobs, setSelectedJobs] = useState<TPrinterJobRow[]>([]);
  const [tableKey, setTableKey] = useState(0);
  const [filterParams, setFilterParams] = useState<IPrintJobFilterParams>(
    PRINT_JOBS_FILTER_PARAMS_INITIAL_STATE,
  );
  const [prepStationsModalActive, setPrepStationsModalActive] = useState(false);
  const [isPrinterModalOpen, setIsPrinterModalOpen] = useState(false);
  const [printerId, setPrinterId] = useState(0);
  const filterParamsForHook = useMemo(
    () =>
      selectedPrinter.printerId
        ? {
            ...filterParams,
            printerId: selectedPrinter.printerId,
          }
        : filterParams,
    [selectedPrinter.printerId, filterParams],
  );

  const {
    printerJobs,
    isLoading,
    refresh,
    count,
    setCount,
    isLoadingMore,
    loadMore,
  } = useGetPrinterJobs(selectedPrinter.printerId, filterParamsForHook);

  const isCompleteFocused = useMemo(() => {
    if (filterParams.status === EPrinterJobStatus.COMPLETE) {
      return true;
    }

    if (
      Array.isArray(filterParams.status) &&
      filterParams.status.includes(EPrinterJobStatus.COMPLETE)
    ) {
      return true;
    }

    return false;
  }, [filterParams.status]);

  const uniqueSelectedTypes = useMemo(
    () => Array.from(new Set(selectedJobs.map(row => row.type))),
    [selectedJobs],
  );

  const idsArray = useMemo(
    () => selectedJobs.map(row => row.id),
    [selectedJobs],
  );

  const typesForPrinterTitle = useMemo(
    () =>
      uniqueSelectedTypes
        .filter(type => PRINTER_SELECTION_TYPES.includes(type))
        .map(type =>
          t(`navBar.common.printerCenter.printJobs.jobType.${type}`),
        ),
    [uniqueSelectedTypes, t],
  );

  const typesForPrinterLocationTitle = useMemo(
    () =>
      uniqueSelectedTypes
        .filter(type => PRINTER_LOCATION_SELECTION_TYPES.includes(type))
        .map(type =>
          t(`navBar.common.printerCenter.printJobs.jobType.${type}`),
        ),
    [uniqueSelectedTypes, t],
  );

  const actionValue: PrintJobsActions = useMemo(
    () =>
      isCompleteFocused ? PrintJobsActions.REPRINT : PrintJobsActions.TRANSFER,
    [isCompleteFocused],
  );

  const printerTitle = useMemo(
    () =>
      typesForPrinterTitle.length === 0
        ? ''
        : t('navBar.common.printerCenter.printJobs.transferReprint.printer', {
            types: concatStringsArrayWithAndText(typesForPrinterTitle),
          }),
    [typesForPrinterTitle, t],
  );

  const printerLocationTitle = useMemo(
    () =>
      typesForPrinterLocationTitle.length === 0
        ? ''
        : t(
            'navBar.common.printerCenter.printJobs.transferReprint.printerLocation',
            {
              types: concatStringsArrayWithAndText(
                typesForPrinterLocationTitle,
              ),
            },
          ),
    [typesForPrinterLocationTitle, t],
  );

  const showPrinterModal = useMemo(
    () => selectedJobs.some(row => PRINTER_SELECTION_TYPES.includes(row.type)),
    [selectedJobs],
  );

  const showPrinterLocationModal = useMemo(
    () =>
      selectedJobs.some(row =>
        PRINTER_LOCATION_SELECTION_TYPES.includes(row.type),
      ),
    [selectedJobs],
  );

  const COLUMNS = useMemo(() => {
    const columnsArray = [
      columnHelper.accessor('timePlaced', {
        header: t('navBar.common.printerCenter.printJobs.timePlaced'),
        cell: info => (
          <Typography align="center">
            {`${getDateFromUTC(info.getValue())} ${getTimeFromUTC(
              info.getValue(),
              false,
              true,
            )}`}
          </Typography>
        ),
        minSize: 160,
      }),
      columnHelper.accessor('status', {
        header: t('settingsModule.devices_licenses.printersTable.status'),
        cell: info => (
          <Box>
            <PrintJobStatusLabel status={info.getValue()} />
            {info.row.original.notConfirmed && <PrintJobNotConfirmedWarning />}
          </Box>
        ),
        minSize: 180,
      }),
      columnHelper.accessor('orderNumber', {
        header: t('navBar.common.printerCenter.printJobs.orderNumber'),
        cell: info => (
          <Typography align="center">
            {(info.row.original.tableName
              ? `${t('commonTexts.table')} ${info.row.original.tableName}`
              : undefined) ||
              info.row.original.orderName ||
              info.getValue()}
          </Typography>
        ),
        size: 100,
      }),
      columnHelper.accessor('printerName', {
        header: t('navBar.common.printerCenter.printJobs.printerName'),
        cell: info => (
          <Typography align="center">
            {info.getValue() || info.row.original.printedBy}
          </Typography>
        ),
        size: 160,
      }),
      columnHelper.accessor('printerLocationName', {
        header: t('navBar.common.printerCenter.printJobs.printerLocationName'),
        cell: info => (
          <Typography align="center">
            {info.getValue() ||
              t(
                `navBar.common.printerCenter.printJobs.jobType.${info.row.original.type}`,
              )}
            {info.row.original.printerLocationName &&
              info.row.original.type === EPrinterJobType.FIRE && (
                <Typography component="span" csx={{ fontStyle: 'italic' }}>
                  &nbsp;-&nbsp;
                  {t('navBar.common.printerCenter.printJobs.jobType.fire')}
                </Typography>
              )}
          </Typography>
        ),
        size: 160,
      }),
      columnHelper.accessor('tabletPlacedFrom', {
        header: t('navBar.common.printerCenter.printJobs.tabletPlacedFrom'),
        cell: info => <Typography align="center">{info.getValue()}</Typography>,
      }),
      columnHelper.accessor('userPlaced', {
        header: t('navBar.common.printerCenter.printJobs.userPlacedFrom'),
        cell: info => <Typography align="center">{info.getValue()}</Typography>,
      }),
    ];

    return columnsArray;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPrinter.printerId]);

  const openPrinterModal = () => setIsPrinterModalOpen(true);

  const closePrinterModal = () => setIsPrinterModalOpen(false);

  const openPrinterLocationModal = () => setPrepStationsModalActive(true);

  const closePrinterLocationModal = () => setPrepStationsModalActive(false);

  const closePrepStationsModal = () => setPrepStationsModalActive(false);

  const handlePrinterLocationOnAssociate = (buttons: IItemSelection[]) => {
    const printerLocationId = Number(buttons[0].id);

    closePrinterLocationModal();

    reprintTransferAction(printerId, printerLocationId);
  };

  const refreshPrinterJobs = useCallback(() => {
    setTableKey(tableKey + 1);
    refresh();
  }, [setTableKey, tableKey, refresh]);

  const handleOnDeleteClick = useCallback(() => {
    setShowConfirmationModal({
      active: true,
      type: 'delete',
      overrideTitle: true,
      title: t(
        'navBar.common.printerCenter.printJobs.deleteConfirmation.title',
      ),
      description: t(
        'navBar.common.printerCenter.printJobs.deleteConfirmation.description',
      ),
      btnSuccessText: t('commonButtons.delete'),

      onSuccess: () => {
        deletePrinterJobsWeb(
          idsArray,
          () => {
            clearPrinterErrorsForPrintJobs(idsArray);

            setSelectedJobs([]);

            refreshPrinterJobs();
          },
          () => null,
        );
      },
    });
  }, [
    setShowConfirmationModal,
    t,
    deletePrinterJobsWeb,
    clearPrinterErrorsForPrintJobs,
    refreshPrinterJobs,
    idsArray,
  ]);

  const handlePrinterOnAssociate = (buttons: IItemSelection[]) => {
    if (!showPrinterLocationModal) {
      closePrinterModal();
      return reprintTransferAction(Number(buttons[0].id));
    }
    setPrinterId(Number(buttons[0].id));
    closePrinterModal();
    openPrinterLocationModal();
  };

  const reprintTransferAction = useCallback(
    (reprintPrinterId?: number, reprintPrinterLocationId?: number) => {
      const resetValues = () => {
        clearPrinterErrorsForPrintJobs(idsArray);
        setSelectedJobs([]);
        refreshPrinterJobs();
        closePrepStationsModal();
      };

      if (actionValue === PrintJobsActions.REPRINT) {
        return reprintPrinterJobsWeb(
          {
            printjobsIds: idsArray,
            printerId: reprintPrinterId ? reprintPrinterId : undefined,
            printerLocationId: reprintPrinterLocationId
              ? reprintPrinterLocationId
              : undefined,
          },
          resetValues,
          () => null,
        );
      }

      transferPrinterJobsWeb(
        {
          printjobsIds: idsArray,
          printerId: reprintPrinterId ? reprintPrinterId : undefined,
          printerLocationId: reprintPrinterLocationId
            ? reprintPrinterLocationId
            : undefined,
        },
        resetValues,
        () => null,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionValue, idsArray, refreshPrinterJobs],
  );

  const transferReprintPrinterJobs = useCallback(() => {
    setPrinterId(0);
    if (showPrinterModal) {
      openPrinterModal();
      return;
    }

    if (showPrinterLocationModal) {
      openPrinterLocationModal();
      return;
    }
  }, [showPrinterModal, showPrinterLocationModal]);

  const handleRowSelectionChange = (selectedRowsObj: RowSelectionState) => {
    const selectedRowsData = Object.keys(selectedRowsObj).map(
      rowIndex => printerJobs[Number(rowIndex)],
    );
    setSelectedJobs(selectedRowsData);
  };

  return (
    <>
      <PrepStationsSelectionModal
        title={printerLocationTitle}
        onAssociate={handlePrinterLocationOnAssociate}
        active={prepStationsModalActive}
        onModalClose={closePrepStationsModal}
        prepStationIds={[]}
        btnSuccessText={t('commonButtons.push')}
        associateOnSelect
        showSelectedButtons={false}
      />
      <PrintersSelectionModal
        title={printerTitle}
        printerIds={[]}
        active={isPrinterModalOpen}
        onModalClose={closePrinterModal}
        onAssociate={handlePrinterOnAssociate}
      />
      <Box csx={printerJobsStyles}>
        <LoadingModal
          mode="local"
          isLoadingLocal={{ active: isLoading, message: 'Loading Print Jobs' }}
        />

        <Grid rowGap={10}>
          <Grid.Item
            csx={{
              paddingRight: hideActionButtons ? 0 : 10,
              [MQ_MAX_SMALL]: {
                paddingRight: 0,
              },
            }}
            mb={12}
            md={hideActionButtons ? 12 : 8}
            lg={hideActionButtons ? 12 : 9}>
            <PrintJobStatusFilter
              filterParams={filterParams}
              totalPrintJobs={count}
              onChange={newStatus => {
                setCount(0);
                setFilterParams(prev => ({ ...prev, status: newStatus }));
              }}
            />
          </Grid.Item>
          {!hideActionButtons && (
            <Grid.Item mb={12} md={4} lg={3}>
              <Grid
                csx={[{ alignItems: 'center', height: '100%' }]}
                columnGap={10}>
                {showDeleteButton && (
                  <Grid.Item mb={6} md={6}>
                    <Button
                      disabled={!selectedJobs.length}
                      csx={{ minWidth: '120px' }}
                      variant="danger"
                      icon={<Icon name="MdDeleteForever" />}
                      onClick={handleOnDeleteClick}>
                      {t('commonButtons.delete')}
                    </Button>
                  </Grid.Item>
                )}
                {showTransferButton && (
                  <Grid.Item mb={6} md={6}>
                    <Button
                      disabled={!selectedJobs.length}
                      csx={{ minWidth: '120px' }}
                      variant="success"
                      icon={
                        <Icon
                          name={isCompleteFocused ? 'MdPrint' : 'MdSyncAlt'}
                        />
                      }
                      onClick={transferReprintPrinterJobs}>
                      {isCompleteFocused
                        ? t('app.modals.rolesDiffModal.register.reprint')
                        : t('commonButtons.transfer')}
                    </Button>
                  </Grid.Item>
                )}
              </Grid>
            </Grid.Item>
          )}
        </Grid>
        <Box
          csx={{
            position: 'relative',
            flexGrow: 1,
            flexShrink: 1,
            overflowY: 'hidden',
            paddingBottom: 20,
          }}>
          <Table
            key={tableKey}
            cellCsx={{
              height: 60,
            }}
            columns={COLUMNS}
            mode={hideActionButtons ? ETableModes.BASIC : ETableModes.SELECTION}
            data={printerJobs}
            onEndReached={loadMore}
            scrollEnabled
            nestedScrollEnabled
            onRowSelectionChange={handleRowSelectionChange}
            align={{
              printerName: 'center',
              printerLocationName: 'center',
              timePlaced: 'center',
              orderNumber: 'center',
              tabletPlacedFrom: 'center',
              userPlaced: 'center',
              type: 'center',
            }}
            alignHeaders={{
              printerName: 'center',
              printerLocationName: 'center',
              timePlaced: 'center',
              orderNumber: 'center',
              tabletPlacedFrom: 'center',
              userPlaced: 'center',
              type: 'center',
            }}
            noDataComponent={
              <NoElementsFound
                csx={{ paddingBlock: '15px' }}
                icon={<Icon name="MdPrint" color="semanticGrey" />}
                text={t('navBar.common.printerCenter.printJobs.noPrinterJobs')}
              />
            }
          />
          {isLoadingMore && (
            <Box
              csx={{
                position: 'absolute',
                bottom: 10,
                left: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%',
                '& *': {
                  zIndex: 1,
                },
              }}>
              <Box
                csx={{
                  display: 'flex',
                  position: 'relative',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: 8,
                  minWidth: 200,
                  height: 45,
                  borderRadius: 50,
                  padding: 10,
                }}>
                <Box
                  csx={{
                    display: 'flex',
                    position: 'absolute',
                    backgroundColor: 'lightGrey',
                    borderRadius: 50,
                    flexDirection: 'row',
                    minWidth: 200,
                    height: 45,
                    opacity: 0.9,
                  }}
                />
                <Spinner size="30px" strokeWidth="7px" color="textBlack" />
                <Typography color="textBlack">
                  {t('navBar.common.printerCenter.printJobs.loadingPrintJobs')}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

const PrintJobNotConfirmedWarning = withTranslation()(
  ({ t }: WithTranslation) => (
    <Box csx={[displayFlexRowStyles, { gap: 3, marginLeft: -2 }]}>
      <Icon name="MdWarning" color="darkYellow" size="20px" />
      <Typography variant="caption2" csx={{ fontStyle: 'italic' }}>
        {t('navBar.common.printerCenter.printJobs.notConfirmedWarning')}
      </Typography>
    </Box>
  ),
);

export default PrinterJobs;

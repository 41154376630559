import GridStack from '@app/components/common/GridStack';
import { IGridStackRef } from '@app/components/common/GridStack/types';
import { GridStackWidget } from 'gridstack';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import WidgetCreateOrEditModal from '../WidgetCreateOrEditModal/WidgetCreateOrEditModal';
import { IWidget, TWidgetTypes } from '@app/state/home/types';
import { updateDashboardElements, updateWidget } from '@app/state/home/actions';
import { useAppDispatch } from '@app/state/store';
import { IDashboardLayout } from '@westondev/tableturn-core';

interface IDashboardGridStack {
  elements: GridStackWidget[];
  onEditPress: (element: GridStackWidget | null) => void;
  onRemoveElement: (elementId: string) => void;
  enableEdit?: boolean;
}

const DashboardGridStack = forwardRef(
  (
    { elements, enableEdit = false, onRemoveElement }: IDashboardGridStack,
    ref,
  ) => {
    // Redux
    const dispatch = useAppDispatch();
    // Local state
    const gridRef = useRef<IGridStackRef | null>(null);
    const [modalState, setModalState] = useState<{
      isActive: boolean;
      widgetType: TWidgetTypes | null;
      currentData?: IWidget;
    }>({
      isActive: false,
      widgetType: null,
      currentData: undefined,
    });

    const handleClickEditWidget = (element: GridStackWidget | null) => {
      console.info('Edit element', element);
      if (!element?.content) return;

      const widgetProps = (
        element.content as unknown as {
          props: {
            title: string;
            widgetId: string;
            parameters: Record<string, any>;
            widgetType: TWidgetTypes;
          };
        }
      ).props;

      const widgetData = {
        id: widgetProps.widgetId,
        title: widgetProps.title,
        parameters: widgetProps.parameters,
        type: widgetProps.widgetType,
      };
      setModalState({
        isActive: true,
        widgetType: widgetProps.widgetType,
        currentData: widgetData,
      });
    };

    useImperativeHandle(ref, () => ({
      addWidget: () => {},
    }));

    const handleUpdateWidget = (updatedWidget: IWidget) => {
      if (!modalState.widgetType) return;

      dispatch(updateWidget(updatedWidget));
    };

    const handleUpdateElements = (updatedElements: IDashboardLayout) => {
      dispatch(updateDashboardElements(updatedElements));
    };

    return (
      <>
        <GridStack
          ref={gridRef}
          elements={elements}
          onEditClick={handleClickEditWidget}
          enableEdit={enableEdit}
          onUpdateData={handleUpdateElements}
          onRemoveElement={onRemoveElement}
        />
        <WidgetCreateOrEditModal
          widgetType={modalState.widgetType}
          isActive={modalState.isActive}
          onModalClose={() =>
            setModalState({
              isActive: false,
              widgetType: null,
            })
          }
          onUpdatedWidget={handleUpdateWidget}
          mode="edit"
          currentData={modalState.currentData}
        />
      </>
    );
  },
);

DashboardGridStack.displayName = 'DashboardGridStack';

export default DashboardGridStack;

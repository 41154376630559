import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import CheckboxMassUpdate from '@app/components/common/CheckBoxMassUpdate';
import Grid from '@app/components/common/Grid';
import MoneyInput from '@app/components/common/MoneyInput';
import Typography from '@app/components/common/Typography';
import useRootSelector from '@app/hooks/useRootSelector';
import { store } from '@app/state/store';
import { OperationType, UpdateType } from '@app/types';
import {
  actionCreatorsMenu,
  formatMoney,
  ICombo,
  IComboMenuTypeVersion,
  IMassUpdate,
  IMenuTypeVersion,
  menuSelectors,
  SectionId,
  useFormError,
  useItemDualPricing,
} from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { useTranslation, WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import MassUpdatePrice from './MassUpdatePrice';
import PricingModal from './PricingModal';

const SUB_PRICES = ['onlinePrice', 'deliveryPrice', 'toGoPrice'];

const sectionId = SectionId.BASIC;

const gridItemSizing = {
  mb: 15,
  sm: 5,
  md: 5,
  xl: 3,
};

interface IPriceSubSection extends WithTranslation {
  menuTypeVersionId: number;
  itemData?: IMenuTypeVersion | IComboMenuTypeVersion | IMassUpdate;
  itemId: number;
  isCombo: boolean | undefined;
  isMassUpdate?: boolean;
}

const MASS_UPDATE_PRICE_BREAKPOINTS = {
  mb: 12,
  md: 6,
  lg: 3,
};

const MASS_UPDATE_PRICES_GAP = {
  md: 20,
  lg: 40,
  xl: 80,
};

const PricingSubsection = ({
  t,
  menuTypeVersionId,
  itemId,
  itemData,
  isCombo,
  isMassUpdate,
}: IPriceSubSection) => {
  // Redux
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );
  const locationIds = useRootSelector(menuSelectors.selectAllLocationIds);

  const [modalActive, setModalActive] = useState(false);
  const [itemName, setItemName] = useState('');

  const { dualPricing, basePriceLabel } = useItemDualPricing(
    itemData as IMenuTypeVersion,
  );

  const showDualPricingLabels = dualPricing && !isCombo && !isMassUpdate;

  const getSubsections = () => {
    if (isCombo) {
      return [
        {
          name: t('menuScreen.comboDetails.pricingSection.comboBasePrice'),
          info: 'Info',
          key: 'combo' as const,
        },
        {
          name: t('menuScreen.itemDetails.pricingSection.onlinePrice'),
          info: 'Info',
          key: 'online' as const,
        },
        {
          name: t('menuScreen.itemDetails.pricingSection.deliveryPrice'),
          info: 'Info',
          key: 'delivery' as const,
        },
        {
          name: t('menuScreen.itemDetails.pricingSection.toGoPrice'),
          info: 'Info',
          key: 'toGo' as const,
        },
      ];
    }

    return [
      {
        name: `${t('menuScreen.itemDetails.pricingSection.itemPrice')}${
          showDualPricingLabels ? ` (${basePriceLabel})` : ''
        }`,
        info: t('menuScreen.itemDetails.pricingSection.itemPricetoolTip'),
        key: 'item' as const,
      },
      {
        name: `${t('menuScreen.itemDetails.pricingSection.onlinePrice')}${
          showDualPricingLabels ? ` (${basePriceLabel})` : ''
        }`,
        info: t('menuScreen.itemDetails.pricingSection.onlinePricetoolTip'),
        key: 'online' as const,
      },
      {
        name: `${t('menuScreen.itemDetails.pricingSection.deliveryPrice')}${
          showDualPricingLabels ? ` (${basePriceLabel})` : ''
        }`,
        info: t('menuScreen.itemDetails.pricingSection.deliveryPricetoolTip'),
        key: 'delivery' as const,
      },
      {
        name: `${t('menuScreen.itemDetails.pricingSection.toGoPrice')}${
          showDualPricingLabels ? ` (${basePriceLabel})` : ''
        }`,
        info: t('menuScreen.itemDetails.pricingSection.toGoPricetoolTip'),
        key: 'toGo' as const,
      },
    ];
  };

  const SUB_SECTIONS = useMemo(
    () => getSubsections(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCombo, t],
  );

  const isMassUpdateProp = {
    isMassUpdate,
  };

  return (
    <>
      <PricingModal
        itemName={itemName ? itemName : ''}
        isActive={modalActive}
        onCloseModal={() => setModalActive(false)}
        itemId={itemId || 0}
        menuTypeId={menuTypeVersionId}
        locationIds={locationIds ?? []}
        isCombo={false}
      />
      <Card.SubCard title={t('menuScreen.itemDetails.pricingSection.title')}>
        <Grid rowGap={20} columnGap={30}>
          {!isMassUpdate && (
            <Grid.Item>
              <Grid
                rowGap={20}
                columnGap={20}
                columns={15}
                csx={{ alignItems: 'flex-start' }}>
                {SUB_SECTIONS.map(({ name, info, key }) => (
                  <PriceSubSection
                    key={key}
                    isCombo={isCombo}
                    name={name}
                    info={info}
                    keyPrefix={key}
                    menuTypeVersionId={menuTypeVersionId}
                    itemData={itemData as IMenuTypeVersion}
                  />
                ))}

                <Grid.Item {...gridItemSizing}>
                  <Box csx={{ paddingTop: '26px' }}>
                    <Button
                      variant="primary"
                      csx={{
                        width: '100%',
                      }}
                      onClick={() => {
                        const currentName =
                          (store.getState().menu.changeData?.data as ICombo)
                            ?.name ?? '';
                        setItemName(currentName);
                        setModalActive(true);
                      }}>
                      {t(
                        'menuScreen.itemDetails.pricingSection.allLocationsBtn',
                      )}
                    </Button>
                  </Box>
                </Grid.Item>
              </Grid>
            </Grid.Item>
          )}
          {isMassUpdate && (
            <Grid.Item>
              <Grid columnGap={MASS_UPDATE_PRICES_GAP} rowGap={20}>
                <Grid.Item {...MASS_UPDATE_PRICE_BREAKPOINTS}>
                  <MassUpdatePrice
                    data={(itemData as IMassUpdate).itemPrice}
                    title={t('menuScreen.itemDetails.pricingSection.itemPrice')}
                    onChange={(
                      updateType: UpdateType,
                      operation: OperationType | -1,
                      price: number,
                    ) => {
                      if (updateType === UpdateType.NONE)
                        setValue({ itemPrice: null }, sectionId);
                      else
                        setValue(
                          { itemPrice: { updateType, operation, price } },
                          sectionId,
                        );
                    }}
                  />
                </Grid.Item>
                <Grid.Item {...MASS_UPDATE_PRICE_BREAKPOINTS}>
                  <MassUpdatePrice
                    data={(itemData as IMassUpdate).onlinePrice}
                    title={t(
                      'menuScreen.itemDetails.pricingSection.onlinePrice',
                    )}
                    onChange={(
                      updateType: UpdateType,
                      operation: OperationType | -1,
                      price: number,
                    ) => {
                      if (updateType === UpdateType.NONE)
                        setValue({ onlinePrice: null }, sectionId);
                      else
                        setValue(
                          { onlinePrice: { updateType, operation, price } },
                          sectionId,
                        );
                    }}
                  />
                </Grid.Item>
                <Grid.Item {...MASS_UPDATE_PRICE_BREAKPOINTS}>
                  <MassUpdatePrice
                    data={(itemData as IMassUpdate).deliveryPrice}
                    title={t(
                      'menuScreen.itemDetails.pricingSection.deliveryPrice',
                    )}
                    onChange={(
                      updateType: UpdateType,
                      operation: OperationType | -1,
                      price: number,
                    ) => {
                      if (updateType === UpdateType.NONE)
                        setValue({ deliveryPrice: null }, sectionId);
                      else
                        setValue(
                          {
                            deliveryPrice: {
                              updateType,
                              operation,
                              price,
                            },
                          },
                          sectionId,
                        );
                    }}
                  />
                </Grid.Item>
                <Grid.Item {...MASS_UPDATE_PRICE_BREAKPOINTS}>
                  <MassUpdatePrice
                    data={(itemData as IMassUpdate).toGoPrice}
                    title={t('menuScreen.itemDetails.pricingSection.toGoPrice')}
                    onChange={(
                      updateType: UpdateType,
                      operation: OperationType | -1,
                      price: number,
                    ) => {
                      if (updateType === UpdateType.NONE)
                        setValue({ toGoPrice: null }, sectionId);
                      else
                        setValue(
                          {
                            toGoPrice: {
                              updateType,
                              operation,
                              price,
                            },
                          },
                          sectionId,
                        );
                    }}
                  />
                </Grid.Item>
              </Grid>
            </Grid.Item>
          )}

          {/* Checkboxes Row */}
          <Grid.Item>
            <Grid
              columnGap={isMassUpdate ? MASS_UPDATE_PRICES_GAP : 20}
              rowGap={20}
              columns={isMassUpdate ? undefined : 15}>
              <Grid.Item
                {...gridItemSizing}
                csx={{ justifyContent: 'center' }}
                {...(isMassUpdate ? MASS_UPDATE_PRICE_BREAKPOINTS : {})}>
                <CheckboxMassUpdate
                  label={
                    isCombo
                      ? t(
                          'menuScreen.comboDetails.pricingSection.isTaxInclusive',
                        )
                      : t(
                          'menuScreen.itemDetails.pricingSection.isTaxInclusive',
                        )
                  }
                  info={t(
                    'menuScreen.itemDetails.pricingSection.isTaxInclusivetoolTip',
                  )}
                  checked={itemData ? itemData?.taxInclusive : false}
                  onChange={taxInclusive =>
                    setValue({ taxInclusive }, sectionId, menuTypeVersionId)
                  }
                  onMassUpdateChange={taxInclusive =>
                    setValue({ taxInclusive }, sectionId, menuTypeVersionId)
                  }
                  {...isMassUpdateProp}
                />
              </Grid.Item>
              <Grid.Item
                {...gridItemSizing}
                csx={{ justifyContent: 'center' }}
                {...(isMassUpdate ? MASS_UPDATE_PRICE_BREAKPOINTS : {})}>
                <CheckboxMassUpdate
                  label={t(
                    'menuScreen.itemDetails.pricingSection.excludeFromSuggestedTip',
                  )}
                  info={t(
                    'menuScreen.itemDetails.pricingSection.excludeFromSuggestedTiptoolTip',
                  )}
                  checked={itemData ? itemData?.excludeFromSuggestedTip : false}
                  onChange={excludeFromSuggestedTip =>
                    setValue(
                      { excludeFromSuggestedTip },
                      sectionId,
                      menuTypeVersionId,
                    )
                  }
                  onMassUpdateChange={excludeFromSuggestedTip =>
                    setValue(
                      { excludeFromSuggestedTip },
                      sectionId,
                      menuTypeVersionId,
                    )
                  }
                  {...isMassUpdateProp}
                />
              </Grid.Item>
              <Grid.Item
                {...gridItemSizing}
                csx={{ justifyContent: 'center' }}
                {...(isMassUpdate ? MASS_UPDATE_PRICE_BREAKPOINTS : {})}>
                <CheckboxMassUpdate
                  label={t(
                    'menuScreen.itemDetails.pricingSection.excludeFromDiscounts',
                  )}
                  info={t(
                    'menuScreen.itemDetails.pricingSection.excludeFromDiscountstoolTip',
                  )}
                  checked={itemData ? itemData?.excludeFromDiscounts : false}
                  onChange={excludeFromDiscounts =>
                    setValue(
                      { excludeFromDiscounts },
                      sectionId,
                      menuTypeVersionId,
                    )
                  }
                  onMassUpdateChange={excludeFromDiscounts =>
                    setValue(
                      { excludeFromDiscounts },
                      sectionId,
                      menuTypeVersionId,
                    )
                  }
                  {...isMassUpdateProp}
                />
              </Grid.Item>
              {!isCombo && !isMassUpdate &&
                <Grid.Item
                  {...gridItemSizing}
                  csx={{ justifyContent: 'center' }}
                  {...(isMassUpdate ? MASS_UPDATE_PRICE_BREAKPOINTS : {})}>
                  <CheckboxMassUpdate
                    label={t(
                      'menuScreen.itemDetails.pricingSection.removeFromCheck',
                    )}
                    info={t(
                      'menuScreen.itemDetails.pricingSection.removeFromCheckToolTip',
                    )}
                    checked={itemData ? itemData?.removeFromCheck : false}
                    onChange={removeFromCheck =>
                      setValue(
                        { removeFromCheck },
                        sectionId,
                        menuTypeVersionId,
                      )
                    }
                    onMassUpdateChange={removeFromCheck =>
                      setValue(
                        { removeFromCheck },
                        sectionId,
                        menuTypeVersionId,
                      )
                    }
                    {...isMassUpdateProp}
                  />
                </Grid.Item>
              }
            </Grid>
          </Grid.Item>
        </Grid>
      </Card.SubCard>
    </>
  );
};

const PriceSubSection = ({
  name,
  info,
  keyPrefix: key,
  menuTypeVersionId,
  itemData,
  isCombo,
}: {
  name: string;
  info: string;
  keyPrefix: 'item' | 'combo' | 'online' | 'delivery' | 'toGo';
  menuTypeVersionId: number;
  itemData: IMenuTypeVersion | IComboMenuTypeVersion;
  isCombo: boolean | undefined;
}) => {
  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const { t } = useTranslation();

  const { dualPricing, dualPrice, basePriceLabel, dualPriceLabel } =
    useItemDualPricing(itemData as IMenuTypeVersion);

  const showDualPricingLabels = dualPricing && !isCombo;

  const priceError = useFormError(
    `menuTypeVersions.${menuTypeVersionId}.${key}Price`,
  );

  const handlePriceChange = (itemPrice: number) => {
    const changes: Record<string, number> = {};
    let previousPrice = 0; // (itemData as IMenuTypeVersion).itemPrice;

    if (isCombo) {
      previousPrice = (itemData as IComboMenuTypeVersion)?.comboBasePrice;
      changes.comboBasePrice = itemPrice;
    } else {
      previousPrice = (itemData as IMenuTypeVersion)?.itemPrice;
      changes.itemPrice = itemPrice;
    }

    SUB_PRICES.forEach(subPrice => {
      const value = (itemData as IMenuTypeVersion)[
        subPrice as keyof IMenuTypeVersion
      ];
      if (value === 0 || value === previousPrice) {
        changes[subPrice] = itemPrice;
      }
    });

    setValue(changes, sectionId, menuTypeVersionId);
    setValue({}, SectionId.REGISTER, menuTypeVersionId);
  };

  const price = useMemo(() => {
    if (key === 'combo') {
      return (
        (itemData as unknown as IComboMenuTypeVersion)?.comboBasePrice ?? 0
      );
    }
    if (key === 'item') {
      return (itemData as IMenuTypeVersion)?.itemPrice ?? 0;
    }

    return (
      (itemData as IMenuTypeVersion | IComboMenuTypeVersion)?.[`${key}Price`] ||
      0
    );
  }, [itemData, key]);

  return (
    <Grid.Item {...gridItemSizing} key={key}>
      <Box
        csx={{
          marginBottom: '10px',
        }}>
        <MoneyInput
          label={name}
          info={info}
          value={price}
          onValueChange={value => {
            if (key === 'item' || key === 'combo') {
              handlePriceChange(value);
            } else
              setValue(
                { [`${key}Price`]: value },
                sectionId,
                menuTypeVersionId,
              );
          }}
          error={!!priceError?.error}
          caption={priceError?.error || undefined}
          onFocus={() => {
            priceError && priceError?.clear();
          }}
        />
      </Box>
      {!isCombo && key !== 'combo' && (
        <>
          <Typography variant="caption" csx={{ marginBottom: '10px' }}>
            {name} + {t('app.modals.itemsDiff.pricing.modPrice')}
          </Typography>
          <Typography color="persistentSemanticBlue" fontWeight="medium">
            {`${showDualPricingLabels ? `${basePriceLabel}: ` : ''}${
              itemData
                ? formatMoney(
                    '',
                    (itemData as IMenuTypeVersion)?.[`${key}Price`] +
                      (itemData as IMenuTypeVersion)?.defaultModifiersPrice,
                  )
                : formatMoney('', 0)
            }${
              showDualPricingLabels
                ? ` | ${dualPriceLabel}: ${formatMoney(
                    '',
                    dualPrice[`${key}Price`],
                  )}`
                : ''
            }`}
          </Typography>
        </>
      )}
    </Grid.Item>
  );
};

export default PricingSubsection;

import { ConfirmationTypes } from '@app/state/app/types';
import { selectConfirmationModal } from '@app/state/selectors/appSelectors';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import BucketValidationConfirmationModal from './BucketValidationConfirmationModal';
import CustomersDiffConfirmationModal from './CustomersDiffConfirmationModal';
import CustomerValidationConfirmationModal from './CustomerValidationConfirmationModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import InfoConfirmationModal from './InfoConfirmationModal';
import MenuDiffConfirmationModal from './MenuDiffConfirmationModal';
import ReportsDiffConfirmationModal from './ReportsDiffConfirmationModal';
import SelectLocationsModal from './SelectLocationsModal';
import SettingsDiffConfirmationModal from './SettingsDiffConfirmationModal';
import SettingsValidationConfirmationModal from './SettingsValidationConfirmationModal';
import WarningConfirmationModal from './WarningConfirmationModal';

const ConfirmationModal = () => {
  const confirmationModal = useSelector(selectConfirmationModal);

  if (!confirmationModal.active) return null;

  switch (confirmationModal.type) {
    case ConfirmationTypes.info:
      return <InfoConfirmationModal {...confirmationModal} />;
    case ConfirmationTypes.delete:
      return <DeleteConfirmationModal {...confirmationModal} />;
    case ConfirmationTypes.warning:
      return <WarningConfirmationModal {...confirmationModal} />;
    case ConfirmationTypes.error:
      return <WarningConfirmationModal {...confirmationModal} showRed />;
    case ConfirmationTypes.orange:
      return <Fragment />;
    case ConfirmationTypes.permissionDenied:
      return <Fragment />;
    case ConfirmationTypes.unassociate:
      return <DeleteConfirmationModal {...confirmationModal} />;
    case ConfirmationTypes.refresh:
      return <WarningConfirmationModal {...confirmationModal} />;
    case ConfirmationTypes.diff:
      return <MenuDiffConfirmationModal {...confirmationModal} />;
    case ConfirmationTypes.settingsDiff:
      return <SettingsDiffConfirmationModal {...confirmationModal} />;
    case ConfirmationTypes.customersDiff:
      return <CustomersDiffConfirmationModal {...confirmationModal} />;
    case ConfirmationTypes.reportsDiff:
      return <ReportsDiffConfirmationModal {...confirmationModal} />;
    case ConfirmationTypes.validation:
      return <BucketValidationConfirmationModal {...confirmationModal} />;
    case ConfirmationTypes.validationDiscard:
      return (
        <BucketValidationConfirmationModal {...confirmationModal} canDiscard />
      );
    case ConfirmationTypes.settingsValidation:
      return <SettingsValidationConfirmationModal {...confirmationModal} />;
    case ConfirmationTypes.settingsValidationDiscard:
      return (
        <SettingsValidationConfirmationModal
          {...confirmationModal}
          canDiscard
        />
      );
    case ConfirmationTypes.customerValidation:
      return <CustomerValidationConfirmationModal {...confirmationModal} />;
    case ConfirmationTypes.customerValidationDiscard:
      return (
        <CustomerValidationConfirmationModal
          {...confirmationModal}
          canDiscard
        />
      );
    case ConfirmationTypes.locationSelection:
      return <SelectLocationsModal variant="menu" {...confirmationModal} />;
    case ConfirmationTypes.locationSettingsSelection:
      return <SelectLocationsModal variant="settings" {...confirmationModal} />;
    case ConfirmationTypes.locationReportsSelection:
      return <SelectLocationsModal variant="reports" {...confirmationModal} />;
    case ConfirmationTypes.tableOptions:
      return <Fragment />;
    case ConfirmationTypes.prepStations:
      return <Fragment />;
    case ConfirmationTypes.prepStationsReprint:
      return <Fragment />;
    case ConfirmationTypes.transferOrderOwner:
      return <Fragment />;
    case ConfirmationTypes.drawerSelection:
      return <Fragment />;
    case ConfirmationTypes.drawerActions:
      return <Fragment />;
    case ConfirmationTypes.reprintTicketElement:
      return <Fragment />;
    case ConfirmationTypes.eightySixed:
      return <Fragment />;
    default:
      return null;
  }
};

export default ConfirmationModal;

import useRootSelector from '@app/hooks/useRootSelector';
import ToastElement from './ToastElement';
import { AnimatePresence } from 'framer-motion';
import Box from '../Box';
import { useEffect, useRef, useState } from 'react';
import { toastManagerStyles } from './styles';

const ToastManager = () => {
  const toastsList = useRootSelector(state => state.app.toastManager);
  const [isScrollable, setIsScrollable] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => () => {
      document.addEventListener('resize', () => {
        if (!containerRef.current) return;
        setIsScrollable(
          containerRef.current?.scrollHeight + 90 > window.innerHeight,
        );
      });
    },
    [],
  );
  useEffect(() => {
    if (!containerRef.current) return;
    setIsScrollable(
      containerRef.current?.scrollHeight + 90 > window.innerHeight,
    );
  }, [toastsList]);

  return (
    <Box ref={containerRef} csx={toastManagerStyles(isScrollable)}>
      <AnimatePresence initial={false} mode="popLayout">
        {toastsList.map(item => (
          <ToastElement key={`toast-${item.id}`} {...item} />
        ))}
      </AnimatePresence>
    </Box>
  );
};

export default ToastManager;

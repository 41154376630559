import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import {
  OPERATION_TYPES_TRANSLATIONS,
  UPDATE_TYPES_TRANSLATIONS,
} from '@app/components/LoggedIn/Menu/Items/ItemDetails/BasicSection/PricingSubsection/MassUpdatePrice/types';
import { UpdateType } from '@app/components/LoggedIn/Menu/Items/ItemDetails/BasicSection/PricingSubsection/PricingModal/types';
import { TChange } from '@app/types';
import { TCsx, Theme, useTheme } from '@emotion/react';
import { isValidElement } from 'react';
import EmptyField from '../../../common/EmptyField';
import TextAreaChange from '../../../common/TextAreaChange';

type ChangeType = {
  change: TChange;
  formatValue: (
    value: unknown,
    field: string,
    textValue?: string,
  ) => string | undefined;
  textCsx?: TCsx;
  isImage?: boolean;
  showLabel?: boolean;
  hasChanged?: boolean;
  height?: string;
  autoWidth?: boolean;
  autoHeight?: boolean;
  renderAsString?: boolean;
};

type ChangeTypeWithoutColors = ChangeType & {
  colorFields?: undefined;
  defaultColor?: undefined;
};

type ChangeTypeWithColors = ChangeType & {
  colorFields: string[];
  defaultColor: {
    [key: string]: string;
  };
};

function RenderChange(props: ChangeTypeWithoutColors): React.ReactElement;
function RenderChange(props: ChangeTypeWithColors): React.ReactElement;

function RenderChange({
  change,
  colorFields = [],
  defaultColor = {},
  formatValue,
  textCsx,
  isImage,
  showLabel = true,
  hasChanged = true,
  height = '100%',
  autoWidth = false,
  autoHeight = false,
  renderAsString = false,
}: ChangeTypeWithoutColors | ChangeTypeWithColors) {
  const isColorField = colorFields.includes(change.field);
  const theme = useTheme();

  const itemChangeColor = theme.colors.black;
  const boxTextAndBorderColor = theme.colors.semanticBlue;

  const boxColor = theme.colors.lighterBlue;

  const boxRemovedColor = theme.colors.lighterRed;
  const boxRemovedTextColor = theme.colors.darkRed;

  if (!change.field) return null;

  return (
    <Box key={`change_${change.field}`} csx={{ width: '100%' }}>
      {isValidElement(change.value) ? (
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height,
            justifyContent: 'center',
            backgroundColor: hasChanged
              ? theme.colors.lighterBlue
              : 'transparent',
            borderRadius: '8px',
            border: hasChanged ? `1px solid ${boxTextAndBorderColor}` : 'none',
            padding: '10px',
          }}>
          {change.value}
        </Box>
      ) : (isColorField && change.value) ||
        (typeof change.value !== 'object' && !Array.isArray(change.value)) ||
        renderAsString ? (
        change.field === 'suggestiveOrderingPrompt' ? (
          change.value ? (
            <TextAreaChange change={change} />
          ) : (
            <EmptyField label={change.label} />
          )
        ) : (
          <Box
            csx={{
              display: 'flex',
              alignItems: 'flex-start',
              marginBlock: '8px',
              marginRight: 0,
            }}>
            {showLabel && (
              <Box
                csx={{
                  width: '350px',
                }}>
                <Typography
                  csx={{ marginRight: '5px' }}
                  color={itemChangeColor}>
                  {change.label}
                </Typography>
              </Box>
            )}
            {isImage ? (
              <Box
                csx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  minWidth: autoWidth ? 'auto' : '130px',
                  height: autoHeight ? 'auto' : '40px',
                  border: `1px solid ${
                    hasChanged ? boxTextAndBorderColor : theme.colors.lightGrey
                  }`,
                }}>
                <img
                  src={change.value as string}
                  css={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '32px',
                  }}
                  alt="Image"
                  title="Image"
                />
              </Box>
            ) : colorFields.includes(change.field) ? (
              <Box
                csx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  minWidth: autoWidth ? 'auto' : '130px',
                  backgroundColor: hasChanged
                    ? change.value === false && change.field === 'active'
                      ? boxRemovedColor
                      : boxColor
                    : 'transparent',
                  height: autoHeight ? 'auto' : '40px',
                  borderRadius: '8px',
                  border: hasChanged
                    ? `1px solid ${
                        change.value === false && change.field === 'active'
                          ? boxRemovedTextColor
                          : boxTextAndBorderColor
                      }`
                    : 'none',
                }}>
                <Box
                  csx={{
                    width: '60px',
                    height: '30px',
                    backgroundColor:
                      change.value &&
                      typeof change.value === 'string' &&
                      change.value.length > 0
                        ? change.value
                        : defaultColor[change.field] in theme.colors
                        ? theme.colors[
                            defaultColor[change.field] as keyof Theme['colors']
                          ]
                        : defaultColor[change.field],
                    borderRadius: '8px',
                    border: `1px solid ${theme.colors.lightGrey}`,
                  }}
                />
              </Box>
            ) : typeof change.value === 'boolean' ? (
              <>
                <Box
                  csx={{
                    width: '100%',
                    minWidth: autoWidth ? 'auto' : '130px',
                    height: autoHeight ? 'auto' : '40px',
                    backgroundColor: hasChanged
                      ? change.value === false && change.field === 'active'
                        ? boxRemovedColor
                        : boxColor
                      : 'transparent',
                    borderRadius: '8px',
                    border: hasChanged
                      ? `1px solid ${
                          change.value === false && change.field === 'active'
                            ? boxRemovedTextColor
                            : boxTextAndBorderColor
                        }`
                      : 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Typography
                    color={
                      hasChanged
                        ? change.value === false && change.field === 'active'
                          ? boxRemovedTextColor
                          : boxTextAndBorderColor
                        : itemChangeColor
                    }
                    variant="body"
                    align="center"
                    csx={textCsx}>
                    {formatValue(change.value, change.field, change.textValue)}
                  </Typography>
                </Box>
              </>
            ) : typeof change.value === 'string' && !change.value ? (
              <Box
                csx={{
                  width: '100%',
                  minWidth: autoWidth ? 'auto' : '130px',
                }}>
                <EmptyField />
              </Box>
            ) : (
              <Box
                csx={{
                  width: '100%',
                  minWidth: autoWidth ? 'auto' : '130px',
                  height: autoHeight ? 'auto' : '40px',
                  backgroundColor: hasChanged
                    ? change.value && change.value !== 'remove'
                      ? boxColor
                      : boxRemovedColor
                    : 'transparent',
                  borderRadius: '8px',
                  border: hasChanged
                    ? `1px solid ${boxTextAndBorderColor}`
                    : 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Typography
                  color={hasChanged ? boxTextAndBorderColor : itemChangeColor}
                  variant="body"
                  align="center"
                  csx={textCsx}>
                  {formatValue(change.value, change.field, change.textValue)}
                </Typography>
              </Box>
            )}
          </Box>
        )
      ) : Array.isArray(change.value) ? (
        change.value.length > 0 && (
          <Box
            csx={{
              display: 'flex',
              alignItems: 'center',
              marginBlock: '10px',
            }}>
            {showLabel && (
              <Box csx={{ width: '210px' }}>
                <Typography fontWeight="medium" variant="body">
                  {change.label}:
                </Typography>
              </Box>
            )}
            <Box
              csx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                backgroundColor: theme.colors.lightestExtraGrey,
                borderRadius: '8px',
                border: `1px solid ${theme.colors.lightGrey}`,
                padding: '15px',
                gap: '10px',
              }}>
              {change.value.map((value, index: number) => (
                <Box key={`${change.field}_${index}`}>
                  <Box
                    csx={{
                      padding: '10px',
                      backgroundColor:
                        value.action === 'remove' ? boxRemovedColor : boxColor,
                      borderRadius: '8px',
                      border: `1px solid ${
                        value.action === 'remove'
                          ? boxRemovedTextColor
                          : boxTextAndBorderColor
                      }`,
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexGrow: 1,
                    }}>
                    <Typography
                      color={
                        value.action === 'remove'
                          ? boxRemovedTextColor
                          : boxTextAndBorderColor
                      }
                      variant="body"
                      align="center"
                      csx={textCsx}>
                      {value.value}
                    </Typography>
                    {value.menuTypeValue && (
                      <Typography
                        color={
                          value.action === 'remove'
                            ? boxRemovedTextColor
                            : boxTextAndBorderColor
                        }
                        variant="body"
                        align="center"
                        csx={textCsx}>
                        ({value.menuTypeValue})
                      </Typography>
                    )}
                  </Box>
                  {(change.field === 'itemIds' ||
                    change.field === 'categoriesSubcategories') &&
                    value.subcategoryValue !== undefined && (
                      <Box
                        csx={{
                          alignItems: 'center',
                          backgroundColor: 'white',
                          padding: '10px',
                          marginTop: '10px',
                          borderRadius: '8px',
                        }}>
                        <Typography csx={textCsx}>
                          {value.subcategoryValue
                            ? value.subcategoryValue
                            : 'N/A'}
                        </Typography>
                      </Box>
                    )}
                </Box>
              ))}
            </Box>
          </Box>
        )
      ) : change.value &&
        typeof change.value === 'object' &&
        'updateType' in change.value ? (
        <Box
          csx={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBlock: '8px',
            marginRight: 0,
          }}>
          <Box
            csx={{
              width: '350px',
            }}>
            <Typography csx={{ marginRight: '5px' }} color={itemChangeColor}>
              {change.label}
            </Typography>
            <Typography
              csx={{ marginRight: '5px' }}
              color={itemChangeColor}
              variant="caption">
              {`${UPDATE_TYPES_TRANSLATIONS[change.value.updateType]}${
                OPERATION_TYPES_TRANSLATIONS[change.value.operation]
                  ? ' - ' + OPERATION_TYPES_TRANSLATIONS[change.value.operation]
                  : ''
              }`}
            </Typography>
          </Box>

          <Box
            csx={{
              width: '100%',
              minWidth: autoWidth ? 'auto' : '130px',
              height: autoHeight ? 'auto' : '40px',
              backgroundColor: boxColor,
              borderRadius: '8px',
              border: `1px solid ${boxTextAndBorderColor}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Typography
              color={hasChanged ? boxTextAndBorderColor : itemChangeColor}
              variant="body"
              align="center"
              csx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}>
              {formatValue(
                change.value.price,
                change.field,
                change.value.updateType === UpdateType.PERCENT
                  ? 'percent'
                  : undefined,
              )}
            </Typography>
          </Box>
        </Box>
      ) : (
        !change.value && (
          <Box
            csx={{
              display: 'flex',
              alignItems: 'flex-start',
              marginBlock: '10px',
            }}>
            {showLabel && (
              <Box
                csx={{
                  width: '350px',
                }}>
                <Typography
                  csx={{ marginRight: '5px' }}
                  color={itemChangeColor}>
                  {change.label}
                </Typography>
              </Box>
            )}
            <Box
              csx={{
                width: '100%',
                minWidth: autoWidth ? 'auto' : '130px',
              }}>
              <EmptyField />
            </Box>
          </Box>
        )
      )}
    </Box>
  );
}

export default RenderChange;

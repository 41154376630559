import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import { settingsErrorsSelector } from '@app/state/selectors/settingsSelectors';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  ILicenseSettingsStore,
  actionCreatorsSettingsChangeData,
  getLicensesSettingsId,
} from '@westondev/tableturn-core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface ILicenseBasicSettings extends IAccordionSection {
  data: ILicenseSettingsStore;
  updateInfo: (
    field: keyof ILicenseSettingsStore,
    newValue: boolean | number | string | null,
  ) => void;
}

const LicensePaymentSettings = ({
  data,
  updateInfo,
  ...sectionProps
}: ILicenseBasicSettings) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isExpanded, ...otherProps } = sectionProps;

  // Redux
  const settingsErrors = useSelector(settingsErrorsSelector);
  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    useDispatch(),
  );

  return (
    <AccordionSection
      isExpanded={true}
      isExpandable={false}
      expandedHeight="70px"
      title={t('settingsModule.licenseSettings.paymentSettings.title')}
      description={t(
        'settingsModule.licenseSettings.paymentSettings.description',
      )}
      titleContainerCsx={theme => ({
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
      })}
      {...otherProps}>
      <Grid columnGap={20} rowGap={20}>
        <Grid.Item sm={6} lg={4} xl={3} csx={displayFlexEndColumnStyles}>
          <Input
            required
            label={t(
              'settingsModule.licenseSettings.paymentSettings.eGiftCardTerminalId',
            )}
            placeholder={t(
              'settingsModule.licenseSettings.paymentSettings.eGiftCardTerminalId',
            )}
            error={Boolean(
              settingsErrors[getLicensesSettingsId('eGiftCardTerminalId')],
            )}
            value={data.eGiftCardTerminalId || ''}
            onChange={e => {
              const value = e.target.value;
              if (value.length > 4) return;
              updateInfo('eGiftCardTerminalId', value);
            }}
            onFocus={() =>
              clearSettingsError(getLicensesSettingsId('eGiftCardTerminalId'))
            }
          />
        </Grid.Item>
      </Grid>
    </AccordionSection>
  );
};

export default LicensePaymentSettings;

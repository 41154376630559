import { MQ_MIN_SMALL } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const userDropdownStyles = (theme: Theme) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '170px',
    '> button,a': {
      width: '100%',
      justifyContent: 'flex-start',
      fontSize: theme.fontSizes.body,
      ':hover': {
        backgroundColor: theme.colors.lighterBlue,
        filter: 'none !important',
        color: theme.colors.semanticBlue,
        svg: {
          fill: theme.colors.semanticBlue,
        },
      },
      svg: {
        borderRadius: '50%',
        padding: '5px',
      },
    },
    '> button:last-of-type': {
      color: theme.colors.persistentSemanticRed,
      svg: {
        fill: theme.colors.persistentSemanticRed,
      },
      ':hover': {
        backgroundColor: theme.colors.lightestRed,
        color: theme.colors.persistentSemanticRed,

        svg: {
          fill: theme.colors.persistentSemanticRed,
        },
      },
    },
    '.profileButton': {
      display: 'none',
    },
    [MQ_MIN_SMALL]: {
      '.navBarUserButton': {
        display: 'none',
      },
      '.navBarUserButtonDivider': {
        display: 'none',
      },
      '.profileButton': {
        display: 'flex',
      },
    },
  });

export const languageContainerStyles = () =>
  css({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '12px',
    width: '100%',
    overflow: 'hidden',
  });

export const dropdownInputStyles = (theme: Theme) =>
  css({
    paddingInline: '10px',
    border: 'none',
    color: `${theme.colors.black} !important`,
    fontWeight: `${theme.fontWeights.regular} !important`,
    filter: 'none !important',
    boxShadow: 'none !important',
    ':hover': {
      backgroundColor: `${theme.colors.lighterBlue} !important`,
      color: `${theme.colors.persistentSemanticBlue} !important`,
      svg: {
        fill: `${theme.colors.persistentSemanticBlue} !important`,
      },
    },
  });

import ActionDropdown from '@app/components/common/ActionDropdown';
import ActionHeader from '@app/components/common/ActionDropdown/ActionHeader';
import ActionItem from '@app/components/common/ActionDropdown/ActionItem';
import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Dropdown from '@app/components/common/Dropdown';
import Icon from '@app/components/common/Icon';
import ThreeDots from '@app/components/common/Icon/custom/ThreeDots';
import Typography from '@app/components/common/Typography';
import DashboardCreator from '@app/components/LoggedIn/Home/DashboardCreator/DashboardCreator';
import Filters from '@app/components/LoggedIn/Home/DashboardCreator/Filters';
import {
  cancelDashboardChanges,
  createDashboard,
  initializeCurrentEditDashboard,
  initializeNewDashboard,
  removeDashboard,
  updateDashboard,
  setCurrentEditSelectedDashboard,
} from '@app/state/home/actions';
import { EDashboardModes, TDashboardModes } from '@app/state/home/types';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { DashboardTopBarStyles } from './styles';
import Input from '@app/components/common/Input';
import {
  selectCurrentDashboard,
  selectCurrentEditDashboard,
} from '@app/state/selectors/homeSelectors';
import { useAppDispatch } from '@app/state/store';
import { DateTime } from 'luxon';
import Divider from '@app/components/common/Divider';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { selectDashboards } from '@app/state/selectors/settingsSelectors';
import useRootSelector from '@app/hooks/useRootSelector';
import { selectLoggedInRole } from '@app/state/users/usersSelectors';
import useGetDashboard from '@app/hooks/useGetDashboard';
import useWindowSize from '@app/hooks/useWindowSize';
import { MQ_MIN_SMALL } from '@app/theme/types';
import { IDashboardFilters } from '@app/components/LoggedIn/Home/DashboardCreator/types';

const Home = () => {
  //Redux
  const dispatch = useAppDispatch();
  const role = useRootSelector(selectLoggedInRole);
  const defaultSelectedDashboard = role.defaultDashboardId;
  const currentDashboard = useSelector(selectCurrentDashboard);

  // Local state
  const dashboardIds = role.dashboardIds;
  const [mode, setMode] = useState<TDashboardModes>('view');
  const [selectedDashboard, setSelectedDashboard] = useState<number | null>(
    null,
  );

  const [filters, setFilters] = useState<IDashboardFilters>({
    timePeriod: 'yesterday',
    startDate: DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd'),
    endDate: DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd'),
  });

  const shouldFetchData = dashboardIds.length > 0;
  const { fetchDashboard, isLoadingDashboard } = useGetDashboard(
    selectedDashboard,
    shouldFetchData,
  );

  useEffect(() => {
    if (selectedDashboard) return;

    setSelectedDashboard(defaultSelectedDashboard);
  }, [defaultSelectedDashboard, selectedDashboard]);

  useEffect(() => {
    if (
      selectedDashboard &&
      (!currentDashboard || currentDashboard.id !== selectedDashboard)
    ) {
      console.info('fetching dashboard');
      fetchDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDashboard, currentDashboard]);

  const handleAddNewDashboard = () => {
    dispatch(initializeNewDashboard());
    setMode(EDashboardModes.CREATE);
  };

  const handleRefreshDashboard = () => {
    if (!selectedDashboard) return;
    fetchDashboard();
  };

  return (
    <>
      <Box
        csx={{
          padding: '15px 15px 0px 15px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}>
        {mode === EDashboardModes.VIEW ? (
          <>
            {(dashboardIds.length > 0 || mode !== 'view') && (
              <ViewModeTopBar
                setMode={setMode}
                dashboardIds={dashboardIds}
                selectedDashboard={selectedDashboard}
                setSelectedDashboard={setSelectedDashboard}
                onRefreshClick={handleRefreshDashboard}
                isLoadingDashboard={isLoadingDashboard}
              />
            )}
            <Filters filters={filters} setFilters={setFilters} />
          </>
        ) : (
          (dashboardIds.length > 0 || mode === 'create' || mode == 'edit') && (
            <EditModeTopBar
              mode={mode}
              setMode={setMode}
              setSelectedDashboard={setSelectedDashboard}
            />
          )
        )}
      </Box>
      {dashboardIds.length > 0 || mode !== 'view' ? (
        <DashboardCreator
          enableEdit={
            mode === EDashboardModes.EDIT || mode === EDashboardModes.CREATE
              ? true
              : false
          }
          onEditDashboardClick={() => {
            dispatch(initializeCurrentEditDashboard());
            setMode(EDashboardModes.EDIT);
          }}
          isPreviewMode={mode === EDashboardModes.PREVIEW ? true : false}
          filters={filters}
        />
      ) : (
        <DashboardCreator
          enableEdit={false}
          hasNoDashboard
          onAddNewDashboard={handleAddNewDashboard}
          filters={filters}
        />
      )}
    </>
  );
};
interface IViewModeTopBar {
  setMode: Dispatch<SetStateAction<TDashboardModes>>;
  dashboardIds: number[];
  selectedDashboard: number | null;
  setSelectedDashboard: Dispatch<SetStateAction<number | null>>;
  onRefreshClick: () => void;
  isLoadingDashboard: boolean;
}
const ViewModeTopBar = ({
  setMode,
  dashboardIds,
  selectedDashboard,
  setSelectedDashboard,
  onRefreshClick,
  isLoadingDashboard,
}: IViewModeTopBar) => {
  // Redux state
  const dispatch = useAppDispatch();
  const locationDashboards = useSelector(selectDashboards);
  const theme = useTheme();
  const { isMobile } = useWindowSize();

  const dashBoardsList = useMemo(() => {
    return Object.values(locationDashboards)
      .filter(dashboard => dashboardIds.includes(dashboard.id))
      .map(dashboard => {
        return {
          label: dashboard.name,
          value: dashboard.id,
        };
      });
  }, [locationDashboards, dashboardIds]);
  const handleRefreshDashboard = async () => {
    if (!selectedDashboard) return;
    onRefreshClick();
  };

  const handleEnableEditMode = () => {
    dispatch(initializeCurrentEditDashboard());
    setMode(EDashboardModes.EDIT);
  };

  const handleAddNewDashboard = () => {
    dispatch(initializeNewDashboard());
    setMode(EDashboardModes.CREATE);
  };

  const handleRemoveDashboard = () => {
    if (!selectedDashboard) return;
    dispatch(
      removeDashboard(() => {
        setSelectedDashboard(null);
      }),
    );
  };

  return (
    <Box
      csx={{
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: '5px',
        [MQ_MIN_SMALL]: { flexWrap: 'nowrap' },
      }}>
      <Box
        csx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          flexGrow: 1,
          order: 2,
          [MQ_MIN_SMALL]: { order: 1 },
        }}>
        <Dropdown
          data={[dashBoardsList]}
          placeholder="No Dashboards"
          value={selectedDashboard || 0}
          onChange={dashboardId => setSelectedDashboard(dashboardId)}
          csx={{ width: '200px' }}
          isLoading={isLoadingDashboard}
        />

        <Button
          variant="secondary"
          icon={<Icon name="MdAdd" />}
          onClick={handleAddNewDashboard}
          csx={{ width: '50px', height: '50px' }}
        />
      </Box>
      <Box
        csx={{
          order: 1,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          [MQ_MIN_SMALL]: { order: 2, width: 'auto', marginRight: '10px' },
        }}>
        {isLoadingDashboard ? (
          <Typography>...Loading Dashboard</Typography>
        ) : (
          <Box
            csx={{
              display: 'flex',
              gap: '5px',
              [MQ_MIN_SMALL]: { flexDirection: 'column' },
            }}>
            <Typography align="center">Last Update:</Typography>
            <Typography align="center">
              {DateTime.now().toLocaleString({
                weekday: 'short',
                month: 'short',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        csx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          justifyContent: 'flex-end',
          order: 3,
        }}>
        {!isMobile && <Divider direction="vertical" csx={{ height: '45px' }} />}

        <Box csx={{ width: '50px' }}>
          <ActionDropdown
            tooltipCsx={{ minWidth: '180px', padding: '10px' }}
            trigger={
              <Button
                variant="secondary"
                csx={{ width: '50px' }}
                icon={
                  <ThreeDots
                    color="textBlack"
                    csx={{ transform: 'rotate(90deg)', marginRight: '2px' }}
                  />
                }
              />
            }>
            <ActionHeader>Dashboard Options</ActionHeader>
            <ActionItem
              icon={<Icon name="MdEdit" />}
              onClick={handleEnableEditMode}>
              Edit
            </ActionItem>
            <ActionItem
              icon={<Icon name="MdRefresh" />}
              onClick={handleRefreshDashboard}>
              Refresh
            </ActionItem>
            <Divider />
            <ActionItem
              icon={<Icon name="MdDeleteForever" />}
              csx={{
                color: `${theme.colors.semanticRed} !important`,
                svg: { fill: `${theme.colors.semanticRed} !important` },
              }}
              onClick={handleRemoveDashboard}>
              Remove
            </ActionItem>
          </ActionDropdown>
        </Box>
      </Box>
    </Box>
  );
};

interface IEditModeTopBar {
  mode: TDashboardModes;
  setMode: Dispatch<SetStateAction<TDashboardModes>>;
  setSelectedDashboard: Dispatch<SetStateAction<number | null>>;
}

const EditModeTopBar = ({
  mode,
  setMode,
  setSelectedDashboard,
}: IEditModeTopBar) => {
  //Redux
  const dispatch = useAppDispatch();

  // Local state
  const [showInput, setShowInput] = useState(false);
  const [dashboardName, setDashboardName] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const currentEditDashboard = useSelector(selectCurrentEditDashboard);

  useEffect(() => {
    if (currentEditDashboard) {
      setDashboardName(currentEditDashboard.selectedDashboard.name);
    } else {
      setDashboardName('');
    }
  }, [currentEditDashboard]);

  const handleCreateDashboard = () => {
    return dispatch(
      createDashboard(() => {
        setMode(EDashboardModes.VIEW);
      }),
    );
  };

  const handleSaveAllChanges = () => {
    dispatch(
      updateDashboard(() => {
        setMode(EDashboardModes.VIEW);
      }),
    );
  };

  const handleDiscardChanges = () => {
    dispatch(cancelDashboardChanges());
    setMode(EDashboardModes.VIEW);
    setSelectedDashboard(null);
  };

  return (
    <Box csx={DashboardTopBarStyles}>
      {!showInput ? (
        mode === EDashboardModes.PREVIEW ? (
          <Dropdown
            data={[
              [
                {
                  label: dashboardName,
                  value: 1,
                },
              ],
            ]}
            placeholder="Select Dashboard"
            value={1}
            onChange={() => null}
            csx={{ width: '200px' }}
          />
        ) : (
          <Box
            className="dashboardName"
            onClick={() => {
              setShowInput(true);
            }}>
            <Typography variant="subtitle">{dashboardName}</Typography>
            <Icon name="MdEdit" />
          </Box>
        )
      ) : (
        <Box
          csx={{ width: '300px' }}
          onBlur={() => {
            setShowInput(false);
            if (dashboardName !== currentEditDashboard?.selectedDashboard.name)
              dispatch(
                setCurrentEditSelectedDashboard({ name: dashboardName }),
              );
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setShowInput(false);
              if (
                dashboardName !== currentEditDashboard?.selectedDashboard.name
              )
                dispatch(
                  setCurrentEditSelectedDashboard({ name: dashboardName }),
                );
            }
          }}>
          <Input
            ref={inputRef}
            autoFocus
            value={dashboardName}
            placeholder="Dashboard Name"
            onChange={({ currentTarget: { value } }) => {
              setDashboardName(value);
            }}
          />
        </Box>
      )}

      <Box
        csx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          justifyContent: 'flex-end',
        }}>
        <Button
          variant={mode === EDashboardModes.PREVIEW ? 'active' : 'secondary'}
          icon={<Icon name="MdVisibility" />}
          onClick={() =>
            mode === EDashboardModes.PREVIEW
              ? setMode(EDashboardModes.EDIT)
              : setMode(EDashboardModes.PREVIEW)
          }>
          {t('commonButtons.preview')}
        </Button>
        <Divider direction="vertical" csx={{ height: '40px' }} />
        <Button variant="secondary" onClick={handleDiscardChanges}>
          {t('commonButtons.cancel')}
        </Button>
        <Button
          variant="primary"
          icon={<Icon name="MdSave" />}
          onClick={() => {
            if (mode === EDashboardModes.CREATE) {
              handleCreateDashboard();
            } else {
              handleSaveAllChanges();
            }
          }}>
          {t('commonButtons.save')}
        </Button>
      </Box>
    </Box>
  );
};

export default Home;

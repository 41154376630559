import HeaderTitle from '@app/components/LoggedIn/HeaderTitle';
import CheckDisplay from '@app/components/LoggedIn/Settings/Printing/Ticket/GuestReceipt/CheckDisplay';
import FooterDescription from '@app/components/LoggedIn/Settings/Printing/Ticket/GuestReceipt/FooterDescription';
import Items from '@app/components/LoggedIn/Settings/Printing/Ticket/GuestReceipt/Items';
import SuggestedTips from '@app/components/LoggedIn/Settings/Printing/Ticket/GuestReceipt/SuggestedTips';
import TaxIndicators from '@app/components/LoggedIn/Settings/Printing/Ticket/GuestReceipt/TaxIndicators';
import CustomizingReceipts from '@app/components/LoggedIn/Settings/Printing/Ticket/KitchenTicket/CustomizingReceipts';
import Accordion from '@app/components/common/Accordion';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Card from '@app/components/common/Card';
import Grid from '@app/components/common/Grid';
import { getSettingsVersion } from '@app/helpers/settings/settings';
import { currentGuestReceiptSettingsSelector } from '@app/state/selectors/settingsSelectors';
import { RootState, useAppDispatch } from '@app/state/store';
import {
  FormMode,
  SettingsSectionId,
  actionCreatorsSettingsChangeData,
} from '@westondev/tableturn-core';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomOrderTypeNames from '../GuestCheck/CustomOrderTypeNames';
import ItemSortPriority from '../GuestCheck/ItemSortPriority';
import ModifierDisplay from '../KitchenTicket/ModifierDisplay';
import PrintTestTicket from '../KitchenTicket/PrintTestTicket';

const GuestReceipt = () => {
  const dispatch = useAppDispatch();

  const isSet = useSelector(
    (state: RootState) =>
      state.settings.changeData.settingsSelected === 'guestReceipt',
  );

  const guestReceiptSettings = useSelector(currentGuestReceiptSettingsSelector);

  const { setSettingsChangeData } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    dispatch,
  );

  useEffect(() => {
    if (!isSet) {
      setSettingsChangeData({
        id: 0,
        settingsSelected: 'guestReceipt',
        data: cloneDeep(guestReceiptSettings),
        originalData: cloneDeep(guestReceiptSettings),
        hasChanges: false,
        differences: [],
        finalDiffObject: null,
        errors: {},
        sectionId: SettingsSectionId.ALL,
        settingsVersion: getSettingsVersion() || 0,
        selectedLocations: [],
        mode: FormMode.UPDATE,
      });
    }
  }, [guestReceiptSettings, isSet, setSettingsChangeData]);

  return isSet ? (
    <>
      <HeaderTitle
        title={t('loggedIn.settingsModule.guestReceiptSettings.title')}
        options={<PrintTestTicket />}
      />
      <Accordion>
        <AccordionSection
          title={t('loggedIn.commonTitles.basic')}
          isExpanded
          titleTextContainerCsx={{ minWidth: '150px' }}>
          <Grid columnGap={15} rowGap={15}>
            <Grid.Item sm={12}>
              <Card.SubCard
                title={t(
                  'loggedIn.settingsModule.guestCheckSettings.checkDisplay.title',
                )}
                csx={{ height: '100%' }}>
                <CheckDisplay />
              </Card.SubCard>
            </Grid.Item>
            <Grid.Item sm={12}>
              <SuggestedTips />
            </Grid.Item>
            <Grid.Item sm={12}>
              <FooterDescription />
            </Grid.Item>
          </Grid>
        </AccordionSection>
        <AccordionSection
          title={t(
            'loggedIn.settingsModule.guestCheckSettings.customizingReceipts.title',
          )}
          isTitleUpperCase={false}
          titleTextContainerCsx={{ minWidth: '150px' }}>
          <Grid columnGap={15} rowGap={15}>
            <Grid.Item sm={12}>
              <CustomizingReceipts
                hiddenElements={['ticketBody']}
                titles={{
                  ticketHeader: t(
                    'loggedIn.settingsModule.guestReceiptSettings.customizingReceipts.receiptHeader',
                  ),
                  ticketFooter: t(
                    'loggedIn.settingsModule.guestReceiptSettings.customizingReceipts.receiptFooter',
                  ),
                }}
              />
            </Grid.Item>
          </Grid>
        </AccordionSection>
        <AccordionSection
          title={t('loggedIn.settingsModule.guestReceiptSettings.items.title')}
          titleTextContainerCsx={{ minWidth: '150px' }}>
          <Grid columnGap={15} rowGap={15}>
            <Grid.Item mb={12}>
              <Items />
            </Grid.Item>
            <Grid.Item mb={12}>
              <ModifierDisplay
                columns={3}
                hiddenElements={[
                  'consolidateByItem',
                  'groupBySeat',
                  'changeNoSeatTextInToGoOrders',
                ]}
              />
            </Grid.Item>
          </Grid>
        </AccordionSection>
        <AccordionSection
          title={t('menuScreen.commonFields.basic.advancedOptions')}
          titleTextContainerCsx={{ minWidth: '150px' }}>
          <Grid columnGap={15} rowGap={15}>
            <Grid.Item mb={12}>
              <CustomOrderTypeNames bucket="guestCheck" />
            </Grid.Item>
            <Grid.Item mb={12}>
              <TaxIndicators />
            </Grid.Item>
            <Grid.Item mb={12}>
              <ItemSortPriority />
            </Grid.Item>
          </Grid>
        </AccordionSection>
      </Accordion>
    </>
  ) : (
    <div>loading ...</div>
  );
};

export default GuestReceipt;

import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Icon from '@app/components/common/Icon';
import List from '@app/components/common/List';
import Collapsible from '@app/components/common/List/Collapsible';
import ListItem from '@app/components/common/List/ListItem';
import TTLogo from '@app/components/common/TTLogo.tsx';
import { actionCreatorsApp } from '@app/state';
import { selectShouldRenderLocationDropdown } from '@app/state/selectors/locationsSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import { AnimatePresence, useAnimate, usePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import LocationDropdown from './LocationDropdown';
import NavigateToTableLayoutCreator from './NavigateToTableLayoutCreator';
import {
  locationContainerStyles,
  sideBarOverlayStyles,
  sideBarStyles,
} from './styles';
import { TCollapsibleLists, TCollapsibleListsState } from './types';

import { MODULE_PATH_INDEX } from '@app/constants';
import { getMainBreadcrumbBucket } from '@app/helpers/factories/menu';
import { getPathWithOrgData } from '@app/helpers/navigation';
import { resetPathsByMainPath } from '@app/helpers/restoration';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRootSelector from '@app/hooks/useRootSelector';
import { initializeBucketListBreadcrumb } from '@app/state/app/actions';
import { selectAvailableModulesByRole } from '@app/state/selectors/settingsSelectors';
import { useAppDispatch } from '@app/state/store';
import { IBreadCrumbWeb } from '@app/types';
import { actionCreatorsMenu } from '@westondev/tableturn-core';
import MenuTabs from './MenuTabs';
import ReportsTabs from './ReportsTabs';
import SettingsTabs from './SettingsTabs';

const { setBreadCrumb } = actionCreatorsMenu;

interface ISideBar extends WithTranslation {
  isOpen: boolean;
  setIsSideBarOpen: (value: boolean) => void;
}

const SideBar = ({ t, isOpen, setIsSideBarOpen }: ISideBar) => {
  // Redux hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigateWithOrg();
  const checkForAllChangesAndNavigate = bindActionCreators(
    actionCreatorsApp.checkForAllChangesAndNavigate,
    dispatch,
  );
  const shouldRenderDropdown = useSelector(selectShouldRenderLocationDropdown);
  const virtualizedNavigation = useRootSelector(
    state => state.app.virtualizedNavigation,
  );

  const modulesAvailable = useSelector(selectAvailableModulesByRole);

  // Local state
  const location = useLocation();
  const [scope, animate] = useAnimate();
  const currentState = useRef(isOpen);

  const [collapsibleItems, setCollapsibleItems] =
    useState<TCollapsibleListsState>({
      menu: false,
      settings: false,
      reservations: false,
      customers: false,
      reports: false,
    });

  const currentPath = location.pathname;

  useEffect(() => {
    const currentModule = location.pathname.split('/')[MODULE_PATH_INDEX];
    if (currentModule in collapsibleItems) {
      handleCloseTheOtherCollapsibleItems(location.pathname);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualizedNavigation.index]);

  useEffect(() => {
    if (currentState.current === isOpen) {
      animate(scope.current, { x: isOpen ? 0 : -250 }, { duration: 0 });
    } else {
      animate(scope.current, { x: isOpen ? 0 : -250 }, { duration: 0.1 });
    }

    currentState.current = isOpen;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleNavigate = (path: string, openNewTab: boolean) => {
    path = getPathWithOrgData(path);

    checkForAllChangesAndNavigate(() => {
      if (openNewTab) return window.open(path, '_blank');

      if (currentPath !== path) {
        navigate(path, { state: { currentPath: path } });
        const currentModule = currentPath.split('/')[MODULE_PATH_INDEX];
        if (currentModule && path.includes(currentModule)) return;
        handleCloseTheOtherCollapsibleItems(path);
      } else {
        handleChangeCollapsibleItems(path as TCollapsibleLists);
      }
    });
  };

  const handleChangeCollapsibleItems = (
    key: TCollapsibleLists,
    forceToggle?: boolean,
  ) => {
    if ((key in collapsibleItems && !collapsibleItems[key]) || forceToggle)
      setCollapsibleItems(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const handleCloseTheOtherCollapsibleItems = (keyToNavigate: string) => {
    keyToNavigate = keyToNavigate.split('/')[
      MODULE_PATH_INDEX
    ] as TCollapsibleLists;

    const newCollapsibleItems = Object.keys(collapsibleItems).reduce(
      (acc, key) => {
        if (key === keyToNavigate) {
          acc[key as TCollapsibleLists] = true;
        } else {
          acc[key as TCollapsibleLists] = false;
        }
        return acc;
      },
      {} as TCollapsibleListsState,
    );
    setCollapsibleItems(newCollapsibleItems);
  };

  const handleResetMainPath = (mainPath: string, pathToNavigate: string) => {
    resetPathsByMainPath(mainPath, getPathWithOrgData(pathToNavigate));
  };

  return (
    <>
      <AnimatePresence>
        {isOpen ? <SideBarOverlay setIsSideBarOpen={setIsSideBarOpen} /> : null}
      </AnimatePresence>
      <Box ref={scope} csx={sideBarStyles} className="sideBar">
        {shouldRenderDropdown && (
          <>
            <Box csx={locationContainerStyles}>
              <LocationDropdown />
            </Box>
            <Divider />
          </>
        )}
        {!shouldRenderDropdown && <LocationDropdown />}
        <Box
          className="options"
          csx={{
            marginTop: '22px',
          }}>
          <List>
            <ListItem
              isActive={
                currentPath.split('/').length === 4 &&
                currentPath.includes('/home')
              }
              icon={<TTLogo withText={false} svgProps={{ width: '24px' }} />}
              text={t('loggedIn.sideBar.home')}
              onClick={openNewTab => {
                handleNavigate('home', openNewTab);
                dispatch(setBreadCrumb([]));
              }}
              isLink={getPathWithOrgData('home')}
            />
            {modulesAvailable.menuModuleAvailable && (
              <Collapsible
                listItemProps={{
                  isActive: currentPath.includes('menu'),
                  icon: <Icon name="MdRestaurant" />,
                  text: t('loggedIn.sideBar.menu.menu'),
                  onClick: openInAnotherTab => {
                    handleNavigate('menu/menus', openInAnotherTab);
                    handleResetMainPath('/menu/', 'menu/menus');
                    const navMain = getMainBreadcrumbBucket(
                      1,
                      null,
                      navigate,
                    ) as IBreadCrumbWeb;
                    dispatch(initializeBucketListBreadcrumb(navMain, 'click'));
                  },
                  onIconRightClick: () =>
                    handleChangeCollapsibleItems('menu', true),
                  isLink: getPathWithOrgData('menu/menus'),
                }}
                csx={{
                  marginTop: '5px',
                  gap: '5px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                isExpanded={collapsibleItems.menu}>
                <MenuTabs />
                <Divider />
                <ListItem
                  isSubItem
                  isActive={currentPath.includes('/menu/fresh-sheet')}
                  text={t('loggedIn.sideBar.menu.freshSheet')}
                  onClick={openNewTab => {
                    handleNavigate('menu/fresh-sheet', openNewTab);
                    dispatch(setBreadCrumb([]));
                  }}
                  isLink={getPathWithOrgData('menu/fresh-sheet')}
                />
              </Collapsible>
            )}
            {modulesAvailable.settingsModuleAvailable && (
              <Collapsible
                listItemProps={{
                  isActive: currentPath.includes('settings'),
                  icon: <Icon name="MdSettings" />,
                  text: t('loggedIn.sideBar.settings.settings'),
                  onClick: openNewTab => {
                    handleNavigate(
                      'settings/general/general-settings',
                      openNewTab,
                    );
                    handleResetMainPath(
                      '/settings/',
                      'settings/general/general-settings',
                    );
                    dispatch(setBreadCrumb([]));
                  },
                  onIconRightClick: () =>
                    handleChangeCollapsibleItems('settings', true),
                  isLink: getPathWithOrgData(
                    'settings/general/general-settings',
                  ),
                }}
                csx={{
                  marginTop: '5px',
                  gap: '5px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                isExpanded={collapsibleItems.settings}>
                <SettingsTabs />
                <Divider />
                <ListItem
                  isSubItem
                  isActive={currentPath.includes(
                    '/settings/devices-and-licenses',
                  )}
                  text={t('loggedIn.sideBar.settings.devicesAndLicenses')}
                  onClick={openNewTab =>
                    handleNavigate('settings/devices-and-licenses', openNewTab)
                  }
                  isLink={getPathWithOrgData('settings/devices-and-licenses')}
                />
                <NavigateToTableLayoutCreator
                  currentPath={currentPath}
                  handleNavigate={handleNavigate}
                />
              </Collapsible>
            )}
            {modulesAvailable.reportsModuleAvailable && (
              <Collapsible
                listItemProps={{
                  isActive: currentPath.includes('reports'),
                  icon: <Icon name="MdRequestQuote" />,
                  text: t('loggedIn.sideBar.reports.reports'),
                  onClick: openInAnotherTab => {
                    handleNavigate('reports/quick-report', openInAnotherTab);
                    handleResetMainPath('/reports/', 'reports/quick-report');
                  },
                  onIconRightClick: () =>
                    handleChangeCollapsibleItems('reports', true),
                  isLink: getPathWithOrgData('reports'),
                }}
                csx={{
                  marginTop: '5px',
                  gap: '5px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                isExpanded={collapsibleItems.reports}>
                <ReportsTabs />
              </Collapsible>
            )}

            <ListItem
              isActive={currentPath.includes('reservations')}
              icon={<Icon name="MdLocalPlay" />}
              text={t('loggedIn.sideBar.reservations')}
              onClick={openNewTab => {
                handleNavigate('reservations', openNewTab);
                dispatch(setBreadCrumb([]));
              }}
              isLink={getPathWithOrgData('reservations')}
            />
            {modulesAvailable.customersModuleAvailable && (
              <ListItem
                isActive={currentPath.includes('customers')}
                icon={<Icon name="MdSupervisedUserCircle" />}
                text={t('loggedIn.sideBar.customers')}
                onClick={openNewTab => {
                  handleNavigate('customers', openNewTab);
                  dispatch(setBreadCrumb([]));
                }}
                isLink={getPathWithOrgData('customers')}
              />
            )}
          </List>
        </Box>
      </Box>
    </>
  );
};

const SideBarOverlay = ({
  setIsSideBarOpen,
}: {
  setIsSideBarOpen: (value: boolean) => void;
}) => {
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(scope.current, { opacity: 0.2 }, { duration: 0 });
      };
      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate(scope.current, { opacity: 0 }, { duration: 0.1 });
        safeToRemove();
      };

      exitAnimation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent]);

  return (
    <Box
      ref={scope}
      className="sideBarOverlay"
      onClick={() => {
        localStorage.setItem('sideBarOpen', 'false');
        setIsSideBarOpen(false);
      }}
      csx={sideBarOverlayStyles}
    />
  );
};

export default SideBar;

/* eslint-disable react/no-unknown-property */
import {
  GridStackOptions,
  GridStack as GridStackType,
  GridStackWidget,
  GridStack as GS,
} from 'gridstack';
import 'gridstack/dist/gridstack.css';
import {
  createRef,
  forwardRef,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { BREAKPOINTS } from '@app/theme/themes';
import Box from '../Box';
import { gridStackStyles } from './styles';
import ActionDropdown from '../ActionDropdown';
import { useTheme } from '@emotion/react';
import Button from '../Button';
import ThreeDots from '../Icon/custom/ThreeDots';
import ActionItem from '../ActionDropdown/ActionItem';
import Icon from '../Icon';
import { useTranslation } from 'react-i18next';

interface IGridStack {
  elements: GridStackWidget[];
  onEditClick: (element: GridStackWidget | null) => void;
  enableEdit?: boolean;
  onUpdateData?: (data: any) => void;
  onRemoveElement?: (elementId: string) => void;
}

const GridStack = forwardRef(
  (
    {
      elements,
      enableEdit = false,
      onEditClick,
      onRemoveElement,
      onUpdateData,
    }: IGridStack,
    ref,
  ) => {
    const grid = useRef<GridStackType | null>(null);
    const widgetsRefs = useRef<Record<string, RefObject<HTMLDivElement>>>({});
    const theme = useTheme();
    const previousElements = useRef<GridStackWidget[]>([]);
    const { t } = useTranslation();

    if (Object.keys(widgetsRefs.current).length !== elements.length) {
      elements.forEach(({ id }) => {
        if (!id) return;
        widgetsRefs.current[id] = widgetsRefs.current[id] || createRef();
      });
    }

    const [selectedElement, setSelectedElement] =
      useState<GridStackWidget | null>(null);
    useImperativeHandle(
      ref,
      () => ({
        addWidget: () => {},
      }),
      [],
    );

    useEffect(() => {
      const options: GridStackOptions = {
        float: true,
        margin: 7.5,
        cellHeight: 100,
        cellHeightUnit: 'px',
        columnOpts: {
          breakpoints: [
            {
              w: parseFloat(BREAKPOINTS['mobile'].max),
              c: 1,
            },
            {
              w: parseFloat(BREAKPOINTS['small'].max),
              c: 1,
            },
          ],
        },
        resizable: {
          handles: 'e, se, s, sw, w',
        },
      };
      grid.current = grid.current || GS.init(options, '.controlled');

      if (widgetsRefs.current) {
        grid.current.batchUpdate();
        grid.current.removeAll(false);
        grid.current.batchUpdate(false);
        elements.forEach(_element => {
          if (!_element.id) return;
          const widgetElement = widgetsRefs.current[_element.id]?.current; // FIXME: fix this when removing charts
          if (widgetElement) {
            grid.current?.makeWidget(widgetElement, _element);
          }
        });
        previousElements.current = elements;
        grid.current.batchUpdate(false);
      }
    }, [elements]);

    useEffect(() => {
      if (enableEdit) {
        grid.current?.enable();
      } else {
        grid.current?.disable();
      }
    }, [enableEdit]);

    useEffect(() => {
      if (enableEdit && grid.current) {
        grid.current.on('change', (e: any) => {
          const event = e as CustomEvent;
          const changes = event.detail;
          const changeData = changes.reduce((acc: any, change: any) => {
            const elementId = change.id;
            return {
              ...acc,
              [elementId]: {
                id: elementId,
                x: change.x,
                y: change.y,
                w: change.w,
                h: change.h,
                widgetId: change.widgetId,
              },
            };
          }, {});

          grid.current?.save();
          onUpdateData && onUpdateData(changeData);
        });
      } else grid.current?.off('change');

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enableEdit]);

    useEffect(() => {
      if (enableEdit && grid.current)
        grid.current.on('added', (e: any) => {
          const event = e as CustomEvent;
          const addedElement = event.detail[0];
          const changeData = {
            [addedElement.id]: {
              id: addedElement.id,
              x: addedElement.x,
              y: addedElement.y,
              w: addedElement.w,
              h: addedElement.h,
              widgetId: addedElement.widgetId,
            },
          };
          if (
            previousElements.current.every(
              element => element.id !== addedElement.id,
            )
          ) {
            grid.current?.save();
            onUpdateData && onUpdateData(changeData);
          }
        });
      else grid.current?.off('added');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enableEdit]);

    const handleRemoveElement = () => {
      const selectedElementId = selectedElement?.id;
      if (!selectedElementId) return;
      onRemoveElement && onRemoveElement(selectedElementId);
      setSelectedElement(null);
      delete widgetsRefs.current[selectedElementId];
    };

    const handleEdit = () => {
      onEditClick(selectedElement);
    };

    return (
      <Box csx={{ width: '100%', height: '100%', marginTop: '-2.5px' }}>
        <Box
          className="grid-stack controlled"
          csx={gridStackStyles}
          style={{ cursor: enableEdit ? 'move' : 'default' }}>
          {elements.map(element => (
            <div
              ref={widgetsRefs.current[element.id as string]}
              key={element.id}
              id={`widget-${element.id}`}
              className={`grid-stack-item ${
                selectedElement?.id === element.id && enableEdit
                  ? 'selected'
                  : ''
              }`}
              gs-x={element.x}
              gs-y={element.y}
              gs-w={element.w}
              gs-h={element.h}
              onPointerDown={() => {
                enableEdit && setSelectedElement(element);
              }}
              onClick={() => {
                enableEdit && setSelectedElement(element);
              }}>
              <div
                className={`grid-stack-item-content${
                  !enableEdit ? ' noBorder' : ''
                }`}>
                {element.content}
              </div>
              {enableEdit && (
                <>
                  <Box
                    className={
                      selectedElement?.id !== element.id
                        ? 'grid-stack-item-actions'
                        : ''
                    }
                    csx={{ position: 'absolute', top: 15, right: 15 }}>
                    <ActionDropdown
                      trigger={
                        <Button
                          variant="icon"
                          csx={{
                            width: '30px',
                            height: '30px',
                            ':hover': {
                              backgroundColor: '#f4f4f4',
                              borderRadius: '50%',
                            },
                          }}
                          icon={
                            <ThreeDots
                              color="black"
                              size="25px"
                              csx={{
                                transform: 'rotateZ(90deg)',
                              }}
                            />
                          }
                        />
                      }>
                      <ActionItem
                        icon={<Icon name="MdEdit" />}
                        onClick={handleEdit}>
                        {t('commonButtons.edit')}
                      </ActionItem>
                      <ActionItem
                        icon={<Icon name="MdDeleteForever" />}
                        csx={{
                          color: `${theme.colors.semanticRed} !important`,
                          svg: {
                            fill: `${theme.colors.semanticRed} !important`,
                          },
                        }}
                        onClick={handleRemoveElement}>
                        {t('commonButtons.remove')}
                      </ActionItem>
                    </ActionDropdown>
                  </Box>
                  <Box className="controls">
                    <Box
                      csx={{
                        position: 'absolute',
                        display: 'flex',
                        bottom: 5,
                        right: 5,
                        alignItems: 'center',
                        height: '10px',
                        width: '10px',
                        backgroundColor: theme.colors.semanticBlue,
                      }}
                    />
                    <Box
                      csx={{
                        position: 'absolute',
                        display: 'flex',
                        bottom: 5,
                        left: 5,
                        alignItems: 'center',
                        height: '10px',
                        width: '10px',
                        backgroundColor: theme.colors.semanticBlue,
                      }}
                    />
                    <Box
                      csx={{
                        visibility: 'hidden',
                        position: 'absolute',
                        display: 'flex',
                        top: 5,
                        left: 5,
                        alignItems: 'center',
                        height: '10px',
                        width: '10px',
                        backgroundColor: theme.colors.semanticBlue,
                      }}
                    />
                    <Box
                      csx={{
                        position: 'absolute',
                        display: 'flex',
                        top: 0,
                        bottom: 0,
                        left: 3,
                        alignSelf: 'center',
                        height: '10px',
                        width: '10px',
                        backgroundColor: theme.colors.semanticBlue,
                      }}
                    />
                    <Box
                      csx={{
                        position: 'absolute',
                        display: 'flex',
                        top: 0,
                        bottom: 0,
                        right: 3,
                        alignSelf: 'center',
                        height: '10px',
                        width: '10px',
                        backgroundColor: theme.colors.semanticBlue,
                      }}
                    />
                    <Box
                      csx={{
                        position: 'absolute',
                        display: 'flex',
                        bottom: 3,
                        left: 0,
                        right: 0,
                        justifySelf: 'center',
                        height: '10px',
                        width: '10px',
                        backgroundColor: theme.colors.semanticBlue,
                      }}
                    />
                  </Box>
                </>
              )}
            </div>
          ))}
        </Box>
      </Box>
    );
  },
);

GridStack.displayName = 'GridStack';

export default GridStack;

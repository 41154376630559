import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import DateTimePicker from '@app/components/common/DateTimePicker';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import { labelStyles } from '@app/components/common/Input/styles';
import Switch from '@app/components/common/Switch';
import Typography from '@app/components/common/Typography';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  actionCreatorsReports,
  fileTypeDropdownOptions,
  fileTypeToFileTypeValue,
  fileTypeValueToFileType,
  repeatTypeDropdownOptions,
  repeatTypeToRepeatTypeValue,
  repeatTypeValueToRepeatType,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReportSchedulingRecipientsSubsection from './ReportSchedulingRecipientsSubsection';

const ReportSchedulingSection = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  const dispatch = useDispatch();
  const setValues = bindActionCreators(
    actionCreatorsReports.handleReportsChange,
    dispatch,
  );

  const report = useRootSelector(state => state.reports.changeData.data);

  const scheduleTimeError = useRootSelector(
    state => state.reports.changeData.errors?.scheduleStartDateTime ?? '',
  );
  const recipientsError = useRootSelector(
    state => state.reports.changeData.errors?.recipients ?? '',
  );

  if (!report) return null;

  const activeSwitch = (
    <Switch
      checked={report.isSchedulingEnabled}
      onChange={v =>
        setValues(_values => ({
          ..._values,
          isSchedulingEnabled: v,
        }))
      }
      showDefaultLabel
    />
  );

  return (
    <AccordionSection
      title={t('reports.addEditScreen.scheduling')}
      required={report.isSchedulingEnabled}
      hasError={!!scheduleTimeError || !!recipientsError}
      titleTextContainerCsx={{ minWidth: '200px' }}
      collapsedHeaderComponent={
        <Box
          csx={[
            {
              display: 'flex',
              height: '100%',
              gap: '10px',
            },
          ]}>
          <Divider
            direction="vertical"
            lineCsx={theme => ({
              borderColor: theme.colors.lightGrey,
            })}
          />
          {activeSwitch}
        </Box>
      }
      {...props}>
      <Box
        csx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
        }}>
        {activeSwitch}

        <Divider
          lineCsx={theme => ({
            borderColor: theme.colors.lightGrey,
          })}
        />

        <Grid rowGap={20} columnGap={20}>
          <Grid.Item mb={12} sm={6} md={3}>
            <Typography csx={labelStyles}>
              {t('reports.fields.fileType')}

              {!!report.isSchedulingEnabled && (
                <span className="required">*</span>
              )}
            </Typography>
            <Dropdown
              data={[fileTypeDropdownOptions]}
              onChange={v =>
                setValues({
                  ...report,
                  fileType:
                    fileTypeValueToFileType[
                      v as keyof typeof fileTypeValueToFileType
                    ],
                })
              }
              value={fileTypeToFileTypeValue[report.fileType]}
              placeholder={t('reports.fields.fileType')}
              isDisabled={!report.isSchedulingEnabled}
              required
            />
          </Grid.Item>
          <Grid.Item mb={12} sm={6} md={3}>
            <Typography csx={labelStyles}>
              {t('reports.fields.scheduleStartDateTime')}

              {!!report.isSchedulingEnabled && (
                <span className="required">*</span>
              )}
            </Typography>
            <DateTimePicker
              required
              value={report.scheduleStartDateTime}
              onChange={v =>
                setValues(_values => ({
                  ..._values,
                  scheduleStartDateTime: v,
                }))
              }
              disabled={!report.isSchedulingEnabled}
              error={!!scheduleTimeError}
              caption={scheduleTimeError}
            />
          </Grid.Item>
          <Grid.Item mb={12} sm={6} md={3}>
            <Typography csx={labelStyles}>
              {t('reports.fields.repeatType')}

              {!!report.isSchedulingEnabled && (
                <span className="required">*</span>
              )}
            </Typography>
            <Dropdown
              required
              data={[repeatTypeDropdownOptions]}
              onChange={v =>
                setValues({
                  ...report,
                  repeatType:
                    repeatTypeValueToRepeatType[
                      v as keyof typeof repeatTypeValueToRepeatType
                    ],
                })
              }
              value={repeatTypeToRepeatTypeValue[report.repeatType]}
              placeholder={t('reports.fields.repeatType')}
              isDisabled={!report.isSchedulingEnabled}
            />
          </Grid.Item>
        </Grid>

        <ReportSchedulingRecipientsSubsection />
      </Box>
    </AccordionSection>
  );
};

export default ReportSchedulingSection;

import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import { ITypography } from '@app/components/common/Typography/Typography';
import { Theme } from '@emotion/react';
import {
  EPrinterJobStatus,
  usePrintJobStatusName,
} from '@westondev/tableturn-core';

interface IPrintJobStatusLabel {
  status: EPrinterJobStatus;
  color?: ITypography['color'];
}

const COLORS: Record<EPrinterJobStatus, keyof Theme['colors']> = {
  [EPrinterJobStatus.COMPLETE]: 'semanticGreen',
  [EPrinterJobStatus.ERROR]: 'semanticRed',
  [EPrinterJobStatus.IN_TRANSIT]: 'semanticYellow',
  [EPrinterJobStatus.PENDING]: 'text',
};

const PrintJobStatusLabel = ({ status, color }: IPrintJobStatusLabel) => {
  const statusName = usePrintJobStatusName(status);

  return (
    <Box
      csx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <Box
        csx={theme => ({
          height: 14,
          width: 14,
          borderRadius: 10,
          backgroundColor: theme.colors[COLORS[status]],
          marginRight: 6,
        })}
      />
      <Typography color={color}>{statusName}</Typography>
    </Box>
  );
};

export default PrintJobStatusLabel;

import React, { useEffect, useMemo } from 'react';
import Box from '@app/components/common/Box';
import Typography from '@app/components/common/Typography';
import PieChart from '@app/components/common/PieChart';
import DataTable from '@app/components/common/DataTable';
import BarChartForSalesTrend from '@app/components/common/BarChartForSalesTrend/BarChartForSalesTrend';
import Skeleton from '@app/components/common/Skeleton';
import { useWidgetData } from './useWidgetData';
import { useTranslation } from 'react-i18next';
import { ParseKeys } from 'i18next';
import { TWidgetTypes } from '@app/state/home/types';
import { IDashboardFilters } from '@app/components/LoggedIn/Home/DashboardCreator/types';
import { DEMO_DATA } from '@app/helpers/Home/home';

interface WidgetProps {
  widgetId: number | string;
  widgetType: TWidgetTypes;
  filters: IDashboardFilters;
  title?: string;
  width?: string | number;
  height?: string | number;
  isMoney?: boolean;
  showLegends?: boolean;
  color?: string;
  parameters: Record<string, any>;
}

const Widget: React.FC<WidgetProps> = ({
  widgetId,
  widgetType,
  filters,
  title,
  width = '100%',
  height = '100%',
  isMoney = true,
  showLegends = true,
  parameters,
}) => {
  const { t } = useTranslation();
  const dataType = parameters.dataType;
  const { data, loading, error, fetchData } = useWidgetData(
    widgetId,
    filters,
    dataType,
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters, filters]);

  const widgetTitle = useMemo(() => {
    if (widgetType !== 'barChart') return title;
    const { timePeriod, startDate, endDate } = filters;

    const preTitle = `loggedIn.dashboardsModule.widgetTitle.${timePeriod}`;

    return t(
      preTitle as ParseKeys,
      timePeriod === 'custom'
        ? {
            widgetType: title || '',
            startDate,
            endDate,
          }
        : { widgetType: title || '' },
    );
  }, [widgetType, title, filters, t]);

  const dataForChart = useMemo(() => {
    if (!data) return DEMO_DATA['barChart'];
    if (widgetType === 'barChart') {
      // Remove starting and ending zero values
      const firstValueNotZero = data?.findIndex(
        (item: any) => item.yValue !== '0',
      );
      const areAllValuesZero = data?.every((item: any) => item.yValue === '0');
      if (areAllValuesZero) return [];

      const newData = [...data];
      const lastValueNotZero = newData
        ?.reverse()
        .findIndex((item: any) => item.yValue !== '0');
      const filteredData = data?.filter((_: any, index: number) => {
        return (
          index >= firstValueNotZero &&
          index <= data.length - 1 - lastValueNotZero
        );
      });
      return filteredData.map((item: any) => ({
        xValue: item.xValue,
        yValue:
          typeof item.yValue === 'string'
            ? parseFloat(item.yValue)
            : item.yValue,
        moreData: item.moreData,
      }));
    }
    return [];
  }, [data, widgetType]);

  const dataForPieChart = useMemo(() => {
    if (widgetType === 'pieChart') {
      if (!data)
        return parameters.slices.map((slice: any, index: number) => ({
          label: slice.label,
          value: 10 * (index + 1),
          color: slice.color,
        }));
      return data.map((slice: any, index: number) => ({
        label: slice.label,
        value:
          typeof slice.value === 'string'
            ? parseFloat(slice.value)
            : slice.yValue,
        color: parameters.slices[index]?.color || undefined,
      }));
    }
    return [];
  }, [widgetType, data, parameters]);

  const dataDataTable = useMemo(() => {
    if (!data) return DEMO_DATA['dataTable'];

    if (widgetType === 'dataTable') {
      return data.map((item: any) => ({
        label: item.label,
        value:
          typeof item.value === 'string' ? parseFloat(item.value) : item.value,
      }));
    }
    return [];
  }, [widgetType, data]);

  if (loading) {
    return (
      <Skeleton csx={{ width, height }}>
        <Typography
          csx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {widgetTitle}
        </Typography>
      </Skeleton>
    );
  }

  if (error) {
    return (
      <Box
        csx={{
          width,
          height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography fontWeight="bold">
          {t('loggedIn.dashboardsModule.widgetErrorData')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      key={widgetId}
      csx={{
        width,
        height,
      }}>
      {widgetType === 'barChart' && (
        <BarChartForSalesTrend
          title={widgetTitle}
          data={dataForChart}
          filters={filters}
          width={width}
          height={height}
          customLabels={{
            xLabel: parameters?.xLabel,
            yLabel: parameters?.yLabel,
          }}
          color={parameters?.color}
        />
      )}

      {widgetType === 'pieChart' && (
        <PieChart
          title={widgetTitle}
          data={dataForPieChart}
          width={width}
          height={height}
          isMoney={isMoney}
          showLegends={showLegends}
        />
      )}

      {widgetType === 'dataTable' && (
        <DataTable
          title={widgetTitle}
          data={dataDataTable}
          isMoney={isMoney}
          width={width}
          height={height}
        />
      )}
    </Box>
  );
};

export default Widget;

import { useTheme } from '@emotion/react';
import { DisplayModes } from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import Icon from '../Icon';
import Switch from '../Switch';
import { ISwitchTheme } from './types';

const SwitchTheme = ({
  t,
  checked: checkedTheme,
  onChange,
  ...props
}: ISwitchTheme & WithTranslation) => {
  const theme = useTheme();

  const checked = useMemo(
    () => (checkedTheme === DisplayModes.LIGHT ? true : false),
    [checkedTheme],
  );

  const label = useMemo(
    () =>
      checkedTheme === DisplayModes.LIGHT
        ? t('components.switchTheme.label.light')
        : t('components.switchTheme.label.dark'),
    [checkedTheme, t],
  );
  const handleOnChange = (newChecked: boolean) => {
    onChange?.(newChecked ? DisplayModes.LIGHT : DisplayModes.DARK);
  };

  return (
    <Switch
      label={label}
      {...props}
      config={{
        activeIcon: <Icon name="IoIosSunny" size="20px" />,
        inactiveIcon: <Icon name="TbMoonFilled" size="20px" />,
        activeBgColor: theme.colors.lightestBlue,
        inactiveBgColor: theme.colors.lighterBlue,
        activeBorderColor: theme.colors.semanticBlue,
        inactiveBorderColor: theme.colors.darkerBlue,
        activeIconBgColor: theme.colors.semanticBlue,
        inactiveIconBgColor: theme.colors.darkerBlue,
      }}
      checked={checked}
      onChange={handleOnChange}
    />
  );
};

export default SwitchTheme;

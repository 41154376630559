import {
  SELECTION_MULTIPLE_BUTTON_ID,
  SELECTION_NONE_BUTTON_ID,
} from '@app/constants';
import { filter, SortOptions } from '@westondev/tableturn-core';

type TParams = {
  [key: string]:
    | number
    | string
    | {
        [key: string]: number | string;
      }
    | number[]
    | string[];
};

export function filterWithSpecialButtons<T>(
  dataArr: T[],
  params: TParams,
  sortOption?: SortOptions | null,
  onSortFunction?: (
    array: Array<T>,
    sortOption: SortOptions | null,
  ) => Array<T>,
  orParams?: string[] | null,
): T[] {
  const noneSelectedKeys: string[] = [];
  const multipleSelectedKeys: string[] = [];

  Object.entries(params).forEach(([key, value]) => {
    let isNoneSelected = false;
    let isMultipleSelected = false;
    let isValid = true;
    if (Array.isArray(value)) {
      (value as number[]).includes(SELECTION_NONE_BUTTON_ID) &&
        (isNoneSelected = true);
      (value as number[]).includes(SELECTION_MULTIPLE_BUTTON_ID) &&
        (isMultipleSelected = true);
    }

    if (Number(value) === SELECTION_NONE_BUTTON_ID) {
      isNoneSelected = true;
      isValid = false;
    }

    if (Number(value) === SELECTION_MULTIPLE_BUTTON_ID) {
      isMultipleSelected = true;
      isValid = false;
    }
    if (isNoneSelected) {
      noneSelectedKeys.push(key);
    }
    if (isMultipleSelected) {
      multipleSelectedKeys.push(key);
    }

    return isValid;
  });

  return filter<T>(
    dataArr,
    params,
    sortOption,
    onSortFunction,
    orParams,
    noneSelectedKeys,
    multipleSelectedKeys,
  );
}

import React, { useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import Box from '../Box';
import Typography from '../Typography';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

export type TBarChartDataPoint = {
  xValue: string;
  yValue: number;
  moreData?: {
    label: string;
    value: number;
  }[];
};
export interface IBarChartProps {
  data: TBarChartDataPoint[];
  customLabels?: {
    xLabel?: string;
    yLabel?: string;
    xLabelFormatter?(
      value: string,
      moreData?: {
        label: string;
        value: number;
      }[],
      opts?: any,
    ): string | string[];
    yLabelFormatter?(val: number, opts?: any): string | string[];
    xTooltipFormatter?(val: number, opts?: any): string;
    yTooltipFormatter?(
      val: number,
      moreData?: {
        label: string;
        value: number;
      }[],
      opts?: any,
    ): string;
  };
  showAxisLabels?: boolean;
  title?: string;
  width?: string | number;
  height?: string | number;
  color?: string;
  otherOptions?: Omit<ApexOptions, 'title'>;
}

const BarChart: React.FC<IBarChartProps> = ({
  data,
  width = '100%',
  height = '100%',
  showAxisLabels = false,
  title,
  customLabels,
  color,
  otherOptions,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [chartData, setChartData] = React.useState<{
    options: ApexOptions | undefined;
    series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
  }>({
    options: {},
    series: [],
  });

  const chartRef = useRef<HTMLDivElement>(null);
  const [chartBoundingBox, setChartBoundingBox] = React.useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  useEffect(() => {
    const getOptionsAndSeries = () => {
      const groupedData: {
        [key: string]: {
          yValue: number;
          moreData?: {
            [key: string]: number;
          }[];
        };
      } = {};

      data.forEach(({ xValue, yValue, moreData: extraFields }) => {
        const moreData = extraFields?.map(({ label, value }) => ({
          [label]: value,
        }));
        groupedData[xValue] = { yValue, moreData };
      });

      const chartCategories: string[] = Object.keys(groupedData);

      const yData = chartCategories.map(key => groupedData[key].yValue);

      const chartOptions: ApexOptions = {
        chart: {
          type: 'bar',
          height: chartBoundingBox.height,
          toolbar: {
            show: false,
          },
          events: {
            animationEnd: function () {
              if (!chartRef.current) return;
              setChartBoundingBox({
                width: chartRef.current?.getBoundingClientRect().width || 0,
                height: chartRef.current?.getBoundingClientRect().height || 0,
              });
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '50%',
          },
        },
        noData: {
          text: t('loggedIn.dashboardsModule.dataSummaryWidget.noData'),
          style: {
            color: theme.colors.textBlack,
            fontSize: '16px',
            fontFamily: 'Roboto',
          },
        },
        dataLabels: { enabled: false },
        ...(showAxisLabels
          ? {
              yaxis: {
                title: customLabels?.yLabel
                  ? {
                      text: customLabels?.yLabel,
                      style: {
                        color: theme.colors.textBlue,
                        fontSize: '16px',
                        fontFamily: 'Roboto',
                      },
                    }
                  : {},
                labels: {
                  formatter: customLabels?.yLabelFormatter
                    ? customLabels.yLabelFormatter
                    : undefined,
                  style: {
                    colors: [theme.colors.darkGrey],
                    fontSize: '14px',
                    fontFamily: 'Roboto',
                  },
                },
              },
              xaxis: {
                title: customLabels?.xLabel
                  ? {
                      text: customLabels?.xLabel,
                      style: {
                        color: theme.colors.textBlue,
                        fontSize: '16px',
                        fontFamily: 'Roboto',
                      },
                    }
                  : {},
                categories: chartCategories,
                labels: {
                  formatter: customLabels?.xLabelFormatter
                    ? (val, _, opts) => {
                        if (!opts || (opts && opts.i === undefined))
                          return '' as string | string[];
                        const index =
                          opts.dataPointIndex !== undefined
                            ? opts.dataPointIndex
                            : opts.i !== undefined
                            ? opts.i
                            : undefined;
                        if (index === undefined) return '' as string | string[];
                        return customLabels.xLabelFormatter?.(
                          val,
                          data[index].moreData,
                          opts,
                        ) as string | string[];
                      }
                    : val => `${val}`,
                  style: {
                    colors: theme.colors.darkGrey,
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                  },
                },
                axisTicks: {
                  show: false,
                },
                crosshairs: {
                  show: true,
                },
              },
            }
          : {
              yaxis: { min: 0, show: false },
              xaxis: {
                labels: { show: false },
                axisBorder: { show: false },
                axisTicks: { show: false },
              },
            }),
        fill: { opacity: 1 },
        grid: {
          show: yData.length > 0,
        },
        tooltip: {
          x: {
            formatter: customLabels?.xTooltipFormatter
              ? customLabels.xTooltipFormatter
              : undefined,
          },
          y: {
            title: {
              formatter: () => '',
            },
            formatter: customLabels?.yTooltipFormatter
              ? (val, opts) =>
                  customLabels?.yTooltipFormatter?.(
                    val,
                    data[opts.dataPointIndex].moreData,
                    opts,
                  ) || `${val}`
              : undefined,
          },
          style: {
            fontFamily: 'Roboto',
            fontSize: '14px',
          },
        },
        legend: { position: 'top' },
      };

      const chartSeries =
        yData.length > 0
          ? [
              {
                name: customLabels?.yLabel,
                color: color ?? theme.colors.darkBlue,
                data: yData,
              },
            ]
          : [];

      return {
        options: chartOptions,
        series: chartSeries,
      };
    };
    const { options, series } = getOptionsAndSeries();
    setChartData({
      options,
      series,
    });
  }, [
    color,
    customLabels,
    data,
    height,
    showAxisLabels,
    theme.colors,
    chartBoundingBox.height,
    t,
  ]);

  useEffect(() => {
    if (chartRef.current) {
      setChartBoundingBox({
        width: chartRef.current.getBoundingClientRect().width,
        height: chartRef.current.getBoundingClientRect().height,
      });
    }
  }, [chartData]);

  return (
    <Box
      csx={{
        width,
        height,
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box csx={{ flexGrow: 0, marginBottom: '5px' }}>
        <Typography color="textBlue" fontWeight="bold" variant="subtitle">
          {title}
        </Typography>
      </Box>
      <Box csx={{ flexGrow: 1 }} ref={chartRef}>
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={height}
          width={width}
          {...otherOptions}
        />
      </Box>
    </Box>
  );
};

export default BarChart;

import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Switch from '@app/components/common/Switch';
import { store } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IRoleSettingsStore,
  RoleDefaultModuleType,
  SettingsSectionId,
  actionCreatorsSettings,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const { makeSelectSettingsData } = settingsSelectors;

const SECTION_ID = SettingsSectionId.REPORTS;
const getMemoizedRoleData =
  makeSelectSettingsData<IRoleSettingsStore>(SECTION_ID);

const ReportsSettings = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );
  const { data } = useSelector(getMemoizedRoleData);

  const activeSwitch = (
    <Switch
      label={t('settingsModule.roleDetailsSettings.allowAccessToThisModule')}
      checked={data ? data.homeScreenPermissions.reportsModuleAvailable : false}
      onChange={isActive => {
        const currentData = store.getState().settings.changeData
          .data as IRoleSettingsStore;

        const currentDefaultModule = currentData.defaultModule;

        const newDefaultModule =
          !isActive &&
          currentDefaultModule === RoleDefaultModuleType.REPORTS_MODULE
            ? RoleDefaultModuleType.HOME_MODULE
            : currentDefaultModule;

        const newHomeScreenPermissions = {
          ...currentData?.homeScreenPermissions,
          reportsModuleAvailable: isActive,
        };

        setValue<IRoleSettingsStore>(
          {
            homeScreenPermissions: newHomeScreenPermissions,
            defaultModule: newDefaultModule,
          },
          SECTION_ID,
        );
        if (
          currentData?.homeScreenPermissions.reportsModuleDefault &&
          !isActive
        ) {
          setValue<IRoleSettingsStore>(
            {
              homeScreenPermissions: {
                ...newHomeScreenPermissions,
                reportsModuleDefault: false,
              },
            },
            SettingsSectionId.HOME,
          );
        } else {
          setValue<IRoleSettingsStore>({}, SettingsSectionId.HOME);
        }
      }}
      isDisabled={data?.superAdmin}
    />
  );

  return (
    <AccordionSection
      title={t('settingsModule.roleDetailsSettings.reports.title')}
      titleTextContainerCsx={{ minWidth: '190px' }}
      isExpandable={false}
      collapsedHeaderComponent={
        <Box
          csx={[
            {
              display: 'flex',
              height: '100%',
              gap: '10px',
            },
          ]}>
          <Divider direction="vertical" />
          {activeSwitch}
        </Box>
      }
      {...props}></AccordionSection>
  );
};

export default ReportsSettings;

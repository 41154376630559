import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import LoadingPDFCard from '@app/components/LoggedIn/Reports/LoadingPDFCard';
import ReportSettingsSection from '@app/components/LoggedIn/Reports/ReportForm/ReportSettingsSection';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  fetchReportPDFWeb,
  validateReportFormWeb,
} from '@app/state/reports/reportsWebActions';
import { useAppDispatch } from '@app/state/store';
import {
  actionCreatorsReports,
  IReportsStore,
  useLoadReportsChangeData,
} from '@westondev/tableturn-core';
import { useCallback, useState } from 'react';
import SaveQuickReport from './SaveQuickReport';
import { MQ_MAX_MEDIUM } from '@app/theme/types';
import { WithTranslation } from 'react-i18next';
import { bindActionCreators } from '@reduxjs/toolkit';

const QuickReports = ({ t }: WithTranslation) => {
  const dispatch = useAppDispatch();

  const setValues = bindActionCreators(
    actionCreatorsReports.handleReportsChange,
    dispatch,
  );

  const [bucket, _setBucket] = useState<keyof IReportsStore>(
    'itemComboSalesReports',
  );
  const [showReport, setShowReport] = useState(false);

  useLoadReportsChangeData<typeof bucket>(0, bucket, true);

  const pdfUrl = useRootSelector(state => state.reports.changeData.reportUrl);
  const runReport = useCallback(
    () => {
      const onContinue = (isValid: boolean) => {
        if (!isValid) return;
        setShowReport(true);
        dispatch(fetchReportPDFWeb(bucket));
      };
      dispatch(validateReportFormWeb(bucket)).then(onContinue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bucket],
  );

  const setBucket = useCallback(
    (newBucket: keyof IReportsStore) => {
      switch (newBucket) {
        case 'salesOverviewReports':
          setValues(prev => ({
            ...prev,
            showSalesByRole: true,
          }));
      }
      _setBucket(newBucket);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Box
      csx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        csx={{
          padding: '20px',
          gap: '15px',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Box
            csx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            {showReport && (
              <Button
                onClick={() => setShowReport(false)}
                variant="transparent">
                <Icon name="MdArrowBack" size="32px" />
              </Button>
            )}

            <Typography variant="heading" fontWeight="medium">
              Quick Report
            </Typography>
          </Box>
          <Box csx={{ display: 'flex', gap: 15 }}>
            {showReport ? (
              <>
                <Button
                  onClick={() => setShowReport(false)}
                  variant="secondary"
                  csx={{
                    width: 174,
                    [MQ_MAX_MEDIUM]: {
                      width: 60,
                      '> span.icon.icon-left': {
                        paddingRight: '0px',
                        position: 'relative',
                      },
                    },
                  }}
                  icon={<Icon name="MdEdit" />}>
                  {t('commonButtons.edit')}
                </Button>
                <Button
                  csx={{
                    width: 174,
                    [MQ_MAX_MEDIUM]: {
                      width: 60,
                      '> span.icon.icon-left': {
                        paddingRight: '0px',
                        position: 'relative',
                      },
                    },
                  }}
                  icon={<Icon name="MdOutlineRotateRight" />}
                  onClick={runReport}
                  variant="secondary">
                  {t('commonButtons.reload')}
                </Button>
                <SaveQuickReport />
              </>
            ) : (
              <>
                <Button
                  onClick={() =>
                    dispatch(actionCreatorsReports.resetReportsChangeData())
                  }
                  variant="secondary"
                  csx={{
                    width: 174,
                    [MQ_MAX_MEDIUM]: {
                      width: 60,
                      '> span.icon.icon-left': {
                        paddingRight: '0px',
                        position: 'relative',
                      },
                    },
                  }}>
                  Clear
                </Button>
                <Button
                  onClick={runReport}
                  variant="primary"
                  csx={{
                    width: 174,
                    [MQ_MAX_MEDIUM]: {
                      width: 60,
                      '> span.icon.icon-left': {
                        paddingRight: '0px',
                        position: 'relative',
                      },
                    },
                  }}>
                  Run
                </Button>
              </>
            )}
          </Box>
        </Box>
        {showReport ? (
          <>
            {!pdfUrl && <LoadingPDFCard />}

            {!!pdfUrl && (
              <object
                data={pdfUrl}
                type="application/pdf"
                width="100%"
                height="100%"
                style={{
                  flex: 1,
                }}>
                <LoadingPDFCard />
              </object>
            )}
          </>
        ) : (
          <ReportSettingsSection
            isQuickReport
            isExpanded
            bucket={bucket}
            onReportBucketChange={setBucket}
          />
        )}
      </Box>
    </Box>
  );
};

export default QuickReports;

import Box from '@app/components/common/Box';
import { selectHasLoggedInUserAccessToViewPrinterCenter } from '@app/state/users/usersSelectors';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PrinterCenterTrigger from '../../PrinterCenter/PrinterCenterTrigger';
import { navBarButtonsStyles } from './styles';
import UserDropdown from './UserDropdown';

const NavBarButtons = () => {
  const showPrinterCenter = useSelector(
    selectHasLoggedInUserAccessToViewPrinterCenter,
  );
  const location = useLocation();
  const isMyOrganizationsPage = location.pathname === '/my-organizations';

  const [showModal, setShowModal] = useState(false);

  return (
    <Box csx={navBarButtonsStyles}>
      <Box className="navBarButtons">
        {!isMyOrganizationsPage && showPrinterCenter && (
          <PrinterCenterTrigger
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )}
        <UserDropdown
          isMyOrganizationsPage={isMyOrganizationsPage}
          showPrinterCenter={showPrinterCenter}
          onClick={() => setShowModal(false)}
        />
      </Box>
    </Box>
  );
};

export default NavBarButtons;

import Dropdown from '@app/components/common/Dropdown';
import { createColumnHelper } from '@tanstack/react-table';
import {
  IGuestCheckCustomizingReceipt,
  IGuestCheckSettings,
  IKitchenTicketCustomizingReceipt,
  IKitchenTicketCustomizingReceiptRow,
  IWithPrepStationVersionId,
  KITCHEN_PRINTER_COLOR_OPTIONS,
  KITCHEN_TICKET_FONT_SIZE_OPTIONS,
  KITCHEN_TICKET_FONT_WEIGHT_OPTIONS,
  KITCHEN_TICKET_JUSTIFY_OPTIONS,
  KITCHEN_TICKET_LINE_OPTIONS,
  KitchenCustomizingReceiptsStatus,
  actionCreatorsSettings,
  kitchenPrinterColorOptionsFactory,
  kitchenTicketFontSizeOptionsFactory,
  kitchenTicketFontWeightOptionsFactory,
  kitchenTicketJustifyOptionsFactory,
  kitchenTicketLineOptionsFactory,
} from '@westondev/tableturn-core';
import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import Box from '@app/components/common/Box';
import Checkbox from '@app/components/common/Checkbox';
import Input from '@app/components/common/Input';
import Switch from '@app/components/common/Switch';
import Table from '@app/components/common/Table';
import { store } from '@app/state/store';
import { displayFlexRowCenterStyles } from '@app/theme/commonStyles';
import { useTheme } from '@emotion/react';
import {
  IKitchenTicketSettings,
  SettingsSectionId,
} from '@westondev/tableturn-core';
import cloneDeep from 'lodash/cloneDeep';
import FontsDropdown from '@app/components/common/Dropdown/FontsDropdown/FontsDropdown';

type ICustomizingReceiptsTableType = {
  id: keyof IKitchenTicketCustomizingReceipt;
  isJustifyDisabled: boolean;
} & IKitchenTicketCustomizingReceiptRow;

const columnHelper = createColumnHelper<ICustomizingReceiptsTableType>();

interface ICustomizingReceiptsTable extends WithTranslation {
  data: IKitchenTicketCustomizingReceipt;
  tableField: 'ticketHeader' | 'ticketFooter';
  sectionId: SettingsSectionId;
  title?: string;
  prepStationVersionId?: IWithPrepStationVersionId['prepStationVersionId'];
}

// eslint-disable-next-line react-refresh/only-export-components
const CustomizingReceiptsTable = ({
  t,
  data,
  tableField,
  sectionId,
  title,
  prepStationVersionId,
}: ICustomizingReceiptsTable) => {
  const theme = useTheme();
  const { updateGenericSettings: setValue, updateKitchenTicketSettings } =
    bindActionCreators(actionCreatorsSettings, useDispatch());

  const textColor = theme.colors.text;

  const kitchenTicketJustifyOptions = useMemo(
    () => kitchenTicketJustifyOptionsFactory(t),
    [t],
  );
  const kitchenTicketFontSizeOptions = useMemo(
    () => kitchenTicketFontSizeOptionsFactory(t),
    [t],
  );
  const kitchenTicketFontWeightOptions = useMemo(
    () => kitchenTicketFontWeightOptionsFactory(t),
    [t],
  );
  const kitchenPrinterColorOptions = useMemo(
    () => kitchenPrinterColorOptionsFactory(t),
    [t],
  );
  const kitchenTicketLineOptions = useMemo(
    () => kitchenTicketLineOptionsFactory(t),
    [t],
  );

  const updateRow = (
    newValue: boolean | string | number,
    id:
      | keyof IKitchenTicketCustomizingReceipt
      | keyof IGuestCheckCustomizingReceipt,
    field: keyof IKitchenTicketCustomizingReceiptRow,
  ) => {
    const tableObject = prepStationVersionId
      ? (store.getState().settings.changeData.data as IKitchenTicketSettings)
          ?.prepStationVersions[prepStationVersionId]?.[tableField]
      : (store.getState().settings.changeData.data as IGuestCheckSettings)?.[
          tableField
        ];

    if (prepStationVersionId) {
      return updateKitchenTicketSettings(
        {
          [tableField]: {
            ...tableObject,
            [id as keyof IKitchenTicketCustomizingReceipt]: {
              ...(tableObject as IKitchenTicketCustomizingReceipt)[
                id as keyof IKitchenTicketCustomizingReceipt
              ],
              [field]: newValue,
            },
          },
        },
        sectionId,
        prepStationVersionId,
      );
    }

    setValue<IGuestCheckSettings>(
      {
        [tableField]: {
          ...tableObject,
          [id as keyof IGuestCheckCustomizingReceipt]: {
            ...(tableObject as IGuestCheckCustomizingReceipt)[
              id as keyof IGuestCheckCustomizingReceipt
            ],
            [field]: newValue,
          },
        },
      },
      sectionId,
    );
  };

  const customizingReceiptTableArray = useMemo(() => {
    const orderedArray = orderBy(
      Object.keys(data || {}).map(key => ({
        ...data[key as keyof IKitchenTicketCustomizingReceipt],
        id: key as keyof IKitchenTicketCustomizingReceipt,
      })),
      'order',
    );

    return orderedArray.map((row, index) => {
      const hasPrevRow = index !== 0;
      const prevRow: ICustomizingReceiptsTableType = (
        hasPrevRow ? orderedArray[index - 1] : {}
      ) as ICustomizingReceiptsTableType;
      return {
        ...row,
        isJustifyDisabled: hasPrevRow ? prevRow.noLineBreak : false,
      };
    });
  }, [data]);

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('status', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.status',
        ),
        cell: info => (
          <Switch
            checked={
              info.getValue() === KitchenCustomizingReceiptsStatus.ACTIVE
            }
            onChange={isActive => {
              updateRow(
                isActive
                  ? KitchenCustomizingReceiptsStatus.ACTIVE
                  : KitchenCustomizingReceiptsStatus.INACTIVE,
                info.row.original.id,
                'status',
              );
            }}
          />
        ),
        size: 80,
      }),
      columnHelper.accessor('id', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.value.label',
        ),
        cell: info =>
          t(
            `loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.value.options.${info.getValue()}`,
          ),
        minSize: 200,
      }),
      columnHelper.accessor('prepend', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.prepend',
        ),
        cell: info => {
          const isDisabled =
            info.row.original.status ===
            KitchenCustomizingReceiptsStatus.INACTIVE;
          return (
            <Input
              placeholder=""
              required
              value={info.getValue()}
              onChange={newText =>
                updateRow(
                  newText.currentTarget.value,
                  info.row.original.id,
                  'prepend',
                )
              }
              disabled={isDisabled}
            />
          );
        },
        minSize: 200,
      }),
      columnHelper.accessor('append', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.append',
        ),
        cell: info => {
          const isDisabled =
            info.row.original.status ===
            KitchenCustomizingReceiptsStatus.INACTIVE;
          return (
            <Input
              placeholder=""
              required
              value={info.getValue()}
              onChange={newText =>
                updateRow(
                  newText.currentTarget.value,
                  info.row.original.id,
                  'append',
                )
              }
              disabled={isDisabled}
            />
          );
        },
        minSize: 200,
      }),
      columnHelper.accessor('justify', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.justify.label',
        ),
        cell: info => (
          <Dropdown
            placeholder={t('commonTexts.placeholderDropdown')}
            data={[kitchenTicketJustifyOptions]}
            value={
              info.getValue() === null
                ? 0
                : KITCHEN_TICKET_JUSTIFY_OPTIONS.indexOf(info.getValue()) + 1
            }
            onChange={newJustify =>
              updateRow(
                KITCHEN_TICKET_JUSTIFY_OPTIONS[newJustify - 1],
                info.row.original.id,
                'justify',
              )
            }
            isDisabled={
              info.row.original.status ===
              KitchenCustomizingReceiptsStatus.INACTIVE
            }
          />
        ),
        size: 150,
      }),
      columnHelper.accessor('fontSize', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.fontSize.label',
        ),
        cell: info => (
          <FontsDropdown
            optionsWidth={300}
            placeholder={t('commonTexts.placeholderDropdown')}
            data={[kitchenTicketFontSizeOptions]}
            value={
              info.getValue() === null
                ? 0
                : KITCHEN_TICKET_FONT_SIZE_OPTIONS.indexOf(info.getValue()) + 1
            }
            onChange={newFontSize =>
              updateRow(
                KITCHEN_TICKET_FONT_SIZE_OPTIONS[newFontSize - 1],
                info.row.original.id,
                'fontSize',
              )
            }
            isDisabled={
              info.row.original.status ===
                KitchenCustomizingReceiptsStatus.INACTIVE ||
              info.row.original.isJustifyDisabled
            }
          />
        ),
        size: 150,
      }),
      columnHelper.accessor('fontWeight', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.fontWeight.label',
        ),
        cell: info => (
          <Dropdown
            placeholder={t('commonTexts.placeholderDropdown')}
            data={[kitchenTicketFontWeightOptions]}
            value={
              info.getValue() === null
                ? 0
                : KITCHEN_TICKET_FONT_WEIGHT_OPTIONS.indexOf(info.getValue()) +
                  1
            }
            onChange={newFontWeight =>
              updateRow(
                KITCHEN_TICKET_FONT_WEIGHT_OPTIONS[newFontWeight - 1],
                info.row.original.id,
                'fontWeight',
              )
            }
            isDisabled={
              info.row.original.status ===
              KitchenCustomizingReceiptsStatus.INACTIVE
            }
          />
        ),
        size: 150,
      }),
      columnHelper.accessor('color', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.color',
        ),
        cell: info => (
          <Dropdown
            placeholder={t('commonTexts.placeholderDropdown')}
            data={[kitchenPrinterColorOptions]}
            value={
              info.getValue() === null
                ? 0
                : KITCHEN_PRINTER_COLOR_OPTIONS.indexOf(info.getValue()) + 1
            }
            onChange={newColor =>
              updateRow(
                KITCHEN_PRINTER_COLOR_OPTIONS[newColor - 1],
                info.row.original.id,
                'color',
              )
            }
            isDisabled={
              info.row.original.status ===
              KitchenCustomizingReceiptsStatus.INACTIVE
            }
          />
        ),
        size: 150,
      }),
      columnHelper.accessor('line', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.line.label',
        ),
        cell: info => (
          <Dropdown
            placeholder={t('commonTexts.placeholderDropdown')}
            data={[kitchenTicketLineOptions]}
            value={
              info.getValue() === null
                ? 0
                : KITCHEN_TICKET_LINE_OPTIONS.indexOf(info.getValue()) + 1
            }
            onChange={newLine =>
              updateRow(
                KITCHEN_TICKET_LINE_OPTIONS[newLine - 1],
                info.row.original.id,
                'line',
              )
            }
            isDisabled={
              info.row.original.status ===
              KitchenCustomizingReceiptsStatus.INACTIVE
            }
          />
        ),
        size: 150,
      }),
      columnHelper.accessor('noLineBreak', {
        header: t(
          'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.noLineBreak',
        ),
        cell: info => (
          <Box csx={[displayFlexRowCenterStyles, { width: '100%' }]}>
            <Checkbox
              checked={info.getValue()}
              onChange={noLineBreak =>
                updateRow(noLineBreak, info.row.original.id, 'noLineBreak')
              }
            />
          </Box>
        ),
        size: 150,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, sectionId, tableField, textColor, prepStationVersionId],
  );

  const handleOnSort = (newData: ICustomizingReceiptsTableType[]) => {
    const clonedCustomizingReceipts = cloneDeep(data);
    newData.forEach((item, index) => {
      clonedCustomizingReceipts[item.id].order = index + 1;
    });
    const newTable = {
      [tableField]: clonedCustomizingReceipts,
    };
    if (prepStationVersionId) {
      return updateKitchenTicketSettings(
        newTable,
        sectionId,
        prepStationVersionId,
      );
    }
    setValue<IGuestCheckSettings>(newTable, sectionId);
  };

  return (
    <Table
      mode="sort-vertically"
      renderEmptyValues
      nestedScrollEnabled
      title={
        title ||
        t(
          `loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.title.${tableField}`,
        )
      }
      columns={COLUMN_VALUES}
      data={data ? customizingReceiptTableArray : []}
      onSortEnd={handleOnSort}
      alignHeaders={{
        prepend: 'center',
        append: 'center',
        justify: 'center',
        fontSize: 'center',
        fontWeight: 'center',
        color: 'center',
        line: 'center',
        noLineBreak: 'center',
      }}
      cellCsx={{
        height: 70,
      }}
    />
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default React.memo(CustomizingReceiptsTable);

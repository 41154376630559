import { getDashboard } from '@app/state/home/actions';
import { useAppDispatch } from '@app/state/store';
import { useState } from 'react';

const useGetDashboard = (
  dashboardId: number | null,
  shouldFetchData: boolean,
) => {
  const dispatch = useAppDispatch();
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);

  const fetchDashboard = async () => {
    if (!dashboardId || !shouldFetchData) return;

    setIsLoadingDashboard(true);
    await dispatch(getDashboard(dashboardId));
    setIsLoadingDashboard(false);
  };

  return { isLoadingDashboard, fetchDashboard };
};

export default useGetDashboard;

import {
  selectGeneralSettings,
  selectCurrentTimeZone,
} from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import {
  EReportPeriod,
  getReportDateTimesForPeriod,
} from '@westondev/tableturn-core';

export function getReportDateTimesForPeriodWeb(period: EReportPeriod) {
  const state = store.getState();
  const endOfBusinessDay = selectGeneralSettings(state).endOfBusinessDay;
  const businessTimeZone = selectCurrentTimeZone(state);
  return getReportDateTimesForPeriod(
    period,
    endOfBusinessDay,
    businessTimeZone,
  );
}

import { currentPrepStationsSelector } from '@app/state/selectors/settingsSelectors';
import { menuPrepStationsFactory } from '@westondev/tableturn-core';
import without from 'lodash//without';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GenericSelectionModal from '../GenericSelectionModal';
import { ISelectionModal } from '../SelectionModal';

type IPrepStationsSelectionModal = Omit<
  ISelectionModal<unknown>,
  'buttons' | 'type'
> &
  WithTranslation & {
    prepStationIds: number[];
    selectedPrepStationIds?: number[];
  };

const PrepStationsSelectionModal = ({
  t,
  active,
  onModalClose,
  onAssociate,
  allowAssociateMultiple = true,
  btnSuccessText,
  prepStationIds,
  selectedPrepStationIds,
  showSelectedButtons = true,
  associateOnSelect,
  showSelectAll,
  ...others
}: IPrepStationsSelectionModal) => {
  //redux
  const prepStations = useSelector(currentPrepStationsSelector);

  const buttons = useMemo(() => {
    const availablePrepStations = without(
      Object.keys(prepStations).map(prepStationId => Number(prepStationId)),
      ...prepStationIds,
    );

    return menuPrepStationsFactory(
      availablePrepStations,
      prepStations,
      selectedPrepStationIds,
    );
  }, [prepStationIds, prepStations, selectedPrepStationIds]);

  return (
    <>
      {active && (
        <GenericSelectionModal
          onPress={() => null}
          onAssociate={onAssociate}
          onModalClose={onModalClose}
          active={active}
          buttons={buttons}
          title={t('menuScreen.selectionModal.prepStations.title')}
          selectedSectionTitle={t(
            'menuScreen.selectionModal.prepStations.selectedSectionTitle',
          )}
          emptySectionMessage={t(
            'menuScreen.selectionModal.prepStations.emptySectionText',
          )}
          noCardSelectedMessage={t(
            'menuScreen.selectionModal.prepStations.noCardSelectedText',
          )}
          noAvailableCardsMessage={t(
            'menuScreen.selectionModal.prepStations.noAvailableCardsText',
          )}
          allowAssociateMultiple={allowAssociateMultiple}
          showDescriptionPanel={false}
          showSelectedButtons={showSelectedButtons}
          btnSuccessText={btnSuccessText}
          associateOnSelect={associateOnSelect}
          showSelectAll={showSelectAll}
          showCommonFilter
          {...others}
        />
      )}
    </>
  );
};

export default PrepStationsSelectionModal;

import { css } from '@emotion/react';

export const dashboardEmptyStyles = () =>
  css({
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    '.dashboardEmptyBackdrop': {
      position: 'absolute',
      width: '100%',
      height: '100vh',
      opacity: 0.6,
      backgroundColor: 'white',
      zIndex: 0,
    },
    '.emptyMessage': {
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '5px',
      userSelect: 'none',
    },
  });

import { css, Theme } from '@emotion/react';

export const gridStackStyles = (theme: Theme) =>
  css({
    '.grid-stack-item > .ui-resizable-ne, .grid-stack-item > .ui-resizable-nw, .grid-stack-item > .ui-resizable-se, .grid-stack-item > .ui-resizable-sw':
      {
        backgroundImage: 'none',
      },
    '.grid-stack-item': {
      '&.selected': {
        '.grid-stack-item-content': {
          border: `1px solid ${theme.colors.semanticBlue}`,
        },
      },
      '.grid-stack-item-actions': {
        visibility: 'hidden',
      },
      '.controls': {
        display: 'none',
      },
      ':hover': {
        '.grid-stack-item-content': {
          border: `1px solid ${theme.colors.semanticBlue}`,
          '&.noBorder': {
            border: '1px solid transparent',
          },
        },
        '.grid-stack-item-actions': {
          visibility: 'visible',
        },
        '.controls': {
          display: 'flex',
        },
      },
    },
    '.grid-stack-item-content': {
      backgroundColor: theme.colors.white,
      boxShadow: theme.shadows[1],
      border: '1px solid transparent',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px',
      overflow: 'hidden !important',
    },
    '.grid-stack-placeholder > .placeholder-content': {
      backgroundColor: theme.colors.lightestGrey,
      borderRadius: '5px',
    },
  });

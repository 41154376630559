import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import { setDashboardBottomSheetOpen } from '@app/state/home/actions';
import { IDashboardGridStackElement } from '@app/state/home/types';
import { selectDashboardBottomSheetOpen } from '@app/state/selectors/homeSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardEmptyStyles } from './styles';

interface IDashboardEmpty {
  elements: IDashboardGridStackElement[];
  enableEdit?: boolean;
  hasNoDashboard?: boolean;
  onEditWidget?: () => void;
  onEditDashboardClick?: () => void;
  onAddNewDashboard?: () => void;
}
const DashboardEmpty = (props: IDashboardEmpty) => {
  // Redux
  const dispatch = useDispatch();
  const isDashboardBottomSheetOpen = useSelector(
    selectDashboardBottomSheetOpen,
  );

  // Local state
  const handleOpenBottomSheet = () => {
    if (isDashboardBottomSheetOpen) return;
    dispatch(setDashboardBottomSheetOpen(true));
  };

  return (
    <>
      <Box id="dashboardEmpty" csx={dashboardEmptyStyles}>
        {(Object.values(props.elements).length === 0 ||
          props.hasNoDashboard) && <Box className="dashboardEmptyBackdrop" />}
        {(Object.values(props.elements).length === 0 ||
          props.hasNoDashboard) && (
          <Box className="emptyMessage">
            <Icon name="MdDashboard" size="50px" />
            <Typography fontWeight="medium" variant="subtitle">
              {props.hasNoDashboard
                ? 'No Dashboard Assigned'
                : 'Dashboard is empty'}
            </Typography>
            {props.enableEdit ? (
              <Typography color="darkGrey" variant="subtitle">
                Start by adding some Widgets to your Dashboard
              </Typography>
            ) : (
              <Typography color="darkGrey" variant="subtitle">
                {props.hasNoDashboard
                  ? 'You can start by adding a new Dashboard'
                  : // eslint-disable-next-line quotes
                    "You haven't added any Widgets to this Dashboard"}
              </Typography>
            )}
            {props.enableEdit ? (
              <Button
                variant="primary"
                icon={<Icon name="MdWidgets" />}
                csx={{ marginTop: '15px' }}
                onClick={handleOpenBottomSheet}>
                Add Widgets
              </Button>
            ) : !props.hasNoDashboard ? (
              <Button
                variant="primary"
                csx={{ marginTop: '15px' }}
                onClick={props.onEditDashboardClick}>
                Edit Dashboard
              </Button>
            ) : (
              <Button
                variant="primary"
                csx={{ marginTop: '15px' }}
                onClick={props.onAddNewDashboard}>
                Add New Dashboard
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default DashboardEmpty;

import Grid from '@app/components/common/Grid';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import { settingsDifferencesSelector } from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import { useTheme } from '@emotion/react';
import { TChange, diffRolesFactory } from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HeaderDiffModal from '../../common/HeaderDiffModal';
import RenderChange from '../common/RenderChange/RenderChange';

const RolesDiffModal = ({ t }: WithTranslation) => {
  // Redux
  const differences = useSelector(settingsDifferencesSelector);

  const formattedDifferences = useMemo(() => {
    const diffObject = diffRolesFactory(differences, store.getState());
    return diffObject;
  }, [differences]);

  const theme = useTheme();

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    if (textValue) {
      return textValue;
    }
    return _formatValue(value, field);
  };

  const renderChange = (_change: TChange) => {
    let change = _change;

    if (change.field === 'defaultDashboardId' && !change.value) {
      change = {
        ...change,
        value: 'None',
      };
    }
    return (
      <RenderChange
        change={change}
        formatValue={formatValue}
        colorFields={['backgroundColor', 'textColor']}
        defaultColor={{
          backgroundColor: theme.colors.cardWhite,
          textColor: theme.colors.black,
        }}
      />
    );
  };

  return (
    <>
      {formattedDifferences && (
        <>
          {formattedDifferences.basic.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.basic')}
                color="black"
              />

              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.basic.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`rolesSettings_basic_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
          {formattedDifferences.home.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.settings.home')}
                color="black"
              />

              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.home.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`rolesSettings_home_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
          {formattedDifferences.endOfDay.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.settings.endOfDay')}
                color="black"
              />

              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.endOfDay.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`rolesSettings_endOfDay_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
          {formattedDifferences.register.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.settings.register')}
                color="black"
              />

              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.register.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`rolesSettings_register_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
          {formattedDifferences.menu.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.settings.menu')}
                color="black"
              />

              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.menu.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`rolesSettings_menu_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
          {formattedDifferences.settings.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.settings.settings')}
                color="black"
              />

              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.settings.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`rolesSettings_settings_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
          {formattedDifferences.management.length > 0 && (
            <>
              <HeaderDiffModal
                name={t(
                  'app.modals.diffModal.sectionTitles.settings.management',
                )}
                color="black"
              />

              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.management.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`rolesSettings_management_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
          {formattedDifferences.reports.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.settings.reports')}
                color="black"
              />

              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.reports.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`rolesSettings_reports_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
          {formattedDifferences.webHomeScreen.length > 0 && (
            <>
              <HeaderDiffModal
                name={t(
                  'settingsModule.roleDetailsSettings.webHomeScreen.title',
                )}
                color="black"
              />

              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.webHomeScreen.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`rolesSettings_web_home_screen_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};

export default RolesDiffModal;

import {
  currentPrintersSelector,
  selectLocationPrinters,
} from '@app/state/selectors/settingsSelectors';
import { CoreRootState, printersModalOptions } from '@westondev/tableturn-core';
import pickBy from 'lodash/pickBy';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GenericSelectionModal from '../GenericSelectionModal';
import { ISelectionModal } from '../SelectionModal';

type IPrintersSelectionModal = Omit<
  ISelectionModal<unknown>,
  'buttons' | 'type'
> &
  WithTranslation & {
    printerIds: number[];
    selectedPrinterIds?: number[];
  };

const PrintersSelectionModal = ({
  t,
  active,
  associateOnSelect = true,
  showSelectedButtons = false,
  printerIds,
  selectedPrinterIds,
  ...others
}: IPrintersSelectionModal) => {
  //redux
  const printers = useSelector(
    (state: CoreRootState) =>
      currentPrintersSelector(state) || selectLocationPrinters(state),
  );

  const buttons = useMemo(() => {
    const availablePrinters = pickBy(
      printers,
      (_, printerId) => !printerIds.includes(Number(printerId)),
    );

    return printersModalOptions(availablePrinters, 0).map(printerOption => ({
      id: printerOption.value,
      title: printerOption.label,
      isSelected: selectedPrinterIds?.includes?.(printerOption.value) || false,
    }));
  }, [printerIds, printers, selectedPrinterIds]);

  return (
    <>
      {active && (
        <GenericSelectionModal
          onPress={() => null}
          active={active}
          buttons={buttons}
          title={t('settingsModule.selectionModal.printers.title')}
          selectedSectionTitle={t(
            'settingsModule.selectionModal.printers.selectedSectionTitle',
          )}
          emptySectionMessage={t(
            'settingsModule.selectionModal.printers.emptySectionText',
          )}
          noCardSelectedMessage={t(
            'settingsModule.selectionModal.printers.noCardSelectedText',
          )}
          noAvailableCardsMessage={t(
            'settingsModule.selectionModal.printers.noAvailableCardsText',
          )}
          showSelectedButtons={showSelectedButtons}
          associateOnSelect={associateOnSelect}
          {...others}
        />
      )}
    </>
  );
};

export default PrintersSelectionModal;

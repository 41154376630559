import Tabs from '@app/components/common/Tabs';
import { ITab } from '@app/components/common/Tabs/types';
import {
  EPrinterJobStatus,
  IPrintJobFilterParams,
} from '@westondev/tableturn-core';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';

interface IPrintJobStatusFilter extends WithTranslation {
  filterParams: IPrintJobFilterParams;
  onChange: (newStatus: EPrinterJobStatus | EPrinterJobStatus[]) => void;
  totalPrintJobs: number;
}

const PrintJobStatusFilter = ({
  t,
  filterParams,
  onChange,
  totalPrintJobs,
}: IPrintJobStatusFilter) => {
  const [activeTab, setSelectedTab] = useState<{
    tabId: number;
    subTabId: number | null;
  }>({ tabId: 1, subTabId: null });

  const buttonDescription = useMemo(
    () =>
      totalPrintJobs > 0
        ? `(${totalPrintJobs.toString()} ${t('commonTexts.total')})`
        : undefined,
    [totalPrintJobs, t],
  );

  const statusFilters: (ITab & {
    value: EPrinterJobStatus | EPrinterJobStatus[];
  })[] = useMemo(
    () => [
      {
        id: 1,
        name: `${t('navBar.common.printerCenter.printJobs.status.pending')}/${t(
          'navBar.common.printerCenter.printJobs.status.error',
        )}`,
        value: [EPrinterJobStatus.PENDING, EPrinterJobStatus.ERROR],
        description: buttonDescription,
      },
      {
        id: 2,
        name: t('navBar.common.printerCenter.printJobs.status.inTransit'),
        value: EPrinterJobStatus.IN_TRANSIT,
        description: buttonDescription,
      },
      {
        id: 3,
        name: t('navBar.common.printerCenter.printJobs.status.complete'),
        value: EPrinterJobStatus.COMPLETE,
        description: buttonDescription,
      },
    ],
    [t, buttonDescription],
  );

  useEffect(() => {
    const selectedTabObject = statusFilters.find(
      tab => tab.id === activeTab.tabId,
    );

    if (!selectedTabObject) {
      return;
    }

    if (!isEqual(selectedTabObject.value, filterParams.status)) {
      const newSelectedTabObject = statusFilters.find(tab =>
        isEqual(tab.value, filterParams.status),
      );
      newSelectedTabObject &&
        setSelectedTab({ tabId: newSelectedTabObject.id, subTabId: null });
    }
  }, [filterParams.status, statusFilters, activeTab.tabId]);

  const handleOnSelectedTabChange = (
    tabId: number,
    subTabId: number | null,
  ) => {
    if (tabId === activeTab.tabId) {
      return;
    }
    const selectedTabObject = statusFilters.find(tab => tab.id === tabId);

    if (!selectedTabObject) {
      return;
    }

    onChange(selectedTabObject.value);

    setSelectedTab({ tabId, subTabId });
  };
  return (
    <Tabs
      data={statusFilters}
      activeTab={activeTab}
      onSelectedTabChange={handleOnSelectedTabChange}
    />
  );
};

export default PrintJobStatusFilter;

import { BREAKPOINTS } from '@app/theme/themes';
import { actionCreatorsApp, IToastData } from '@westondev/tableturn-core';
import {
  MotionProps,
  VariantLabels,
  m,
  useAnimate,
  usePresence,
} from 'framer-motion';
import { ParseKeys } from 'i18next';
import React, {
  memo,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toastIconStyles, toastStyles, toastTypes } from './styles';
import { TOAST_ANIMATIONS, TToastAnimation } from './types';
import Box from '../../Box';
import Typography from '../../Typography';
import Divider from '../../Divider';
import Button from '../../Button';
import Icon from '../../Icon';
import { IBox } from '../../Box/Box';

interface CustomToastType extends IToastData, WithTranslation {
  actions?: React.ReactNode;
  showCloseIcon?: boolean;
  onHideToast?: () => void;
  containerProps?: MotionProps & IBox;
}

export const CustomToast = withTranslation()(({
  t,
  type,
  title,
  description,
  actions,
  showCloseIcon = true,
  onHideToast,
  containerProps,
  isActive,
}: CustomToastType) => {
  const [animationType, setAnimationType] = React.useState<TToastAnimation>(
    TOAST_ANIMATIONS.desktop,
  );

  const handleResize = () => {
    if (window.innerWidth < parseFloat(BREAKPOINTS.small.min)) {
      setAnimationType(TOAST_ANIMATIONS.mobile);
    } else {
      setAnimationType(TOAST_ANIMATIONS.desktop);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    handleResize();
  }, []);

  return isActive ? (
    <ToastAnimationContainer
      type={type}
      containerProps={containerProps}
      animationType={animationType}>
      <Box className="infoContainer">
        <Box csx={{ display: 'flex', alignItems: 'center' }}>
          <Box csx={toastIconStyles(type)}>{toastTypes[type].icon}</Box>
        </Box>
        <Box
          csx={{
            width: '100%',
            ...(description
              ? undefined
              : {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }),
          }}>
          <Typography
            fontWeight="medium"
            color={type === 'warning' ? 'textBlack' : 'textWhite'}>
            {t(title as ParseKeys)}
          </Typography>
          {description && (
            <Box csx={{ marginTop: '4px' }}>
              <Typography
                color={type === 'warning' ? 'textBlack' : 'textWhite'}>
                {typeof description === 'string'
                  ? t(description as ParseKeys)
                  : description}
              </Typography>
            </Box>
          )}
        </Box>
        {showCloseIcon && (
          <Box
            csx={{
              display: 'flex',
            }}>
            <Divider
              direction="vertical"
              csx={{
                height: '100%',
                marginRight: '8px',
                opacity: 0.5,
              }}
            />
            <Button
              variant="icon"
              onClick={() => onHideToast && onHideToast()}
              icon={
                <Icon
                  name="MdClear"
                  color={type === 'warning' ? 'textBlack' : 'textWhite'}
                  csx={{ marginTop: '3px' }}
                />
              }
            />
          </Box>
        )}
      </Box>
      {actions && (
        <Box>
          <Divider csx={{ marginBlock: '10px' }} />
          {actions}
        </Box>
      )}
    </ToastAnimationContainer>
  ) : null;
});

const ToastAnimationContainer = ({
  children,
  type,
  containerProps,
  animationType,
}: {
  children: ReactNode;
  type: CustomToastType['type'];
  containerProps?: CustomToastType['containerProps'];
  animationType: TToastAnimation;
}) => {
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (!animationType) return;

    if (isPresent) {
      const enterAnimation = async () => {
        await animate(scope.current, animationType.enter, {
          duration: 0.2,
        });
      };
      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate(scope.current, animationType.exit, {
          duration: 0.2,
        });
        safeToRemove();
      };
      exitAnimation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent, animationType]);

  return (
    <m.div
      ref={scope}
      layout
      initial={
        animationType ? (animationType.initial as VariantLabels) : undefined
      }
      css={toastStyles(type)}
      {...containerProps}>
      {children}
    </m.div>
  );
};

const ToastElement = (toastData: IToastData) => {
  //Redux
  const {
    id,
    type,
    title,
    description,
    isActive,
    timeout: toastTimeout,
    autoRemove = true,
  } = toastData;

  const { removeToastElement } = bindActionCreators(
    actionCreatorsApp,
    useDispatch(),
  );

  const timeOut: { current: NodeJS.Timeout | null } = useRef(null);

  useEffect(() => {
    if (isActive && autoRemove)
      timeOut.current = setTimeout(() => {
        removeToastElement(id as string);
      }, toastTimeout || 3000);
    else {
      clearTimeout(timeOut.current as NodeJS.Timeout);
    }
    return () => {
      timeOut.current && clearTimeout(timeOut.current);
    };
  }, [removeToastElement, isActive, toastTimeout, id, autoRemove]);

  return (
    <CustomToast
      isActive={isActive}
      type={type}
      title={title}
      description={description}
      onHideToast={() => removeToastElement(id as string)}
      containerProps={{ style: { marginBottom: '10px' } }}
    />
  );
};

const ToastElementMemo = memo(ToastElement);
export default ToastElementMemo;

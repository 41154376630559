import { MQ_MIN_SMALL } from '@app/theme/types';
import { css } from '@emotion/react';

export const toastManagerStyles = (isScrollable: boolean) =>
  css({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '80px',
    left: '0px',
    right: '20px',
    width: '100%',
    maxHeight: 'calc(100vh - 100px)',
    overflowY: isScrollable ? 'auto' : 'hidden',
    overflowX: 'hidden',
    zIndex: 9999,
    paddingTop: '10px',
    [MQ_MIN_SMALL]: {
      paddingInline: '10px',
      width: 'auto',
      left: 'auto',
      right: '20px',
    },
  });

import { MQ_MIN_SMALL } from '@app/theme/types';
import { css } from '@emotion/react';

export const layoutGridStyles =
  (gridSize: number, containerHeight: number) => () => {
    return css({
      position: 'absolute',
      width: '100%',
      minHeight: containerHeight || '100%',
      flex: 1,
      display: 'flex',
      marginLeft: '-7.5px',
      marginTop: '5px',
      backgroundImage:
        'linear-gradient(90deg, #f6f6f6 15px,transparent 0),linear-gradient(0deg, #f6f6f6 15px,white 0)!important',
      backgroundSize: `100% ${gridSize}px`,
      zIndex: 0,
      pointerEvents: 'none',
      [MQ_MIN_SMALL]: {
        backgroundImage:
          'linear-gradient(90deg, #f6f6f6 15px,transparent 0),linear-gradient(0deg, #f6f6f6 15px,white 0)!important',
        backgroundSize: `${gridSize / 12}% ${gridSize}px`,
      },
    });
  };

import { MQ_MAX_MOBILE } from '@app/theme/types';
import { Theme, css } from '@emotion/react';

export const navBarButtonsStyles = (theme: Theme) =>
  css({
    display: 'flex',
    gap: '2px',
    '.navBarButtons': {
      display: 'flex',
      gap: '2px',
      marginRight: '-10px',

      [MQ_MAX_MOBILE]: {
        button: {
          width: '40px',
          height: '40px',
          gap: '0px',
          backgroundColor: 'transparent',
          borderRadius: '50%',
          ':hover': {
            ...hoverStyles(theme),
          },
        },
      },
      '.userName': {
        display: 'flex',
        textAlign: 'left',
      },
      '.roleName': {
        display: 'block',
        fontSize: '12px',
        fontWeight: theme.fontWeights.regular,
        color: theme.colors.textWhite,
        textAlign: 'left',
      },
    },
    '.navBarUserButton': {
      '.userDropdownChevronIcon': {
        display: 'flex',
      },
      [MQ_MAX_MOBILE]: {
        '.userDropdownChevronIcon': {
          display: 'none',
        },
        '> span:first-of-type': {
          paddingRight: '0 !important',
        },
        '> span:last-of-type': {
          display: 'none',
        },
        '> .userName': {
          display: 'none',
        },
        ':hover': {
          ...hoverStyles(theme),
        },
      },
    },
  });

export const buttonStyles = css({
  border: 'none',
  borderRadius: 0,
});

export const hoverStyles = (theme: Theme) =>
  css({
    backgroundColor: theme.colors.persistentDarkerBlue,
    svg: {
      fill: theme.colors.textWhite,
    },
  });

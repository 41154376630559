import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Divider from '@app/components/common/Divider';
import { labelStyles } from '@app/components/common/Input/styles';
import Typography from '@app/components/common/Typography';
import { Theme } from '@emotion/react';
import { IDashboardFilters } from './types';
import { DateTime } from 'luxon';
import Input from '@app/components/common/Input';

interface IFilters {
  filters: IDashboardFilters;
  setFilters: (filters: IDashboardFilters) => void;
}
const Filters = ({ filters, setFilters }: IFilters) => {
  const startDate = filters.startDate
    ? filters.startDate
    : DateTime.now().toFormat('yyyy-MM-dd');
  const endDate = filters.endDate
    ? filters.endDate
    : DateTime.now().toFormat('yyyy-MM-dd');

  const todayDate = DateTime.now().toFormat('yyyy-MM-dd');
  return (
    <Box
      csx={(theme: Theme) => ({
        width: '100%',
        height: '100px',
        backgroundColor: theme.colors.white,
        boxShadow: theme.shadows[1],
        display: 'flex',
        justifyContent: 'center',
        padding: '20px',
        flexDirection: 'column',
        borderRadius: '8px',
        overflowY: 'hidden',
        overflowX: 'auto',
        zIndex: 1,
      })}>
      <Box csx={{ display: 'flex', gap: '15px' }}>
        <Box>
          <Typography fontWeight="medium" align="center" csx={labelStyles}>
            Time Period
          </Typography>
          <Box
            csx={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}>
            <Button
              csx={{ minWidth: '100px' }}
              variant={
                filters.timePeriod === 'yesterday' ? 'active' : 'secondary'
              }
              onClick={() =>
                setFilters({
                  startDate: DateTime.now()
                    .minus({ days: 1 })
                    .toFormat('yyyy-MM-dd'),
                  endDate: DateTime.now()
                    .minus({ days: 1 })
                    .toFormat('yyyy-MM-dd'),
                  timePeriod: 'yesterday',
                })
              }>
              Yesterday
            </Button>
            <Button
              csx={{ minWidth: '100px' }}
              variant={filters.timePeriod === 'today' ? 'active' : 'secondary'}
              onClick={() =>
                setFilters({
                  startDate: todayDate,
                  endDate: todayDate,
                  timePeriod: 'today',
                })
              }>
              Today
            </Button>
            <Button
              csx={{ minWidth: '100px' }}
              variant={filters.timePeriod === 'week' ? 'active' : 'secondary'}
              onClick={() => {
                // const dayOfTheWeek = DateTime.now().weekday;
                // const finalDayOfWeek = DateTime.now()
                //   .plus({ days: 7 - dayOfTheWeek })
                //   .toFormat('yyyy-MM-dd');
                setFilters({
                  startDate: DateTime.now()
                    .startOf('week')
                    .toFormat('yyyy-MM-dd'),
                  endDate: DateTime.now().endOf('week').toFormat('yyyy-MM-dd'),
                  timePeriod: 'week',
                });
              }}>
              Week
            </Button>
            <Button
              csx={{ minWidth: '100px' }}
              variant={filters.timePeriod === 'month' ? 'active' : 'secondary'}
              onClick={() =>
                setFilters({
                  startDate: DateTime.now()
                    .startOf('month')
                    .toFormat('yyyy-MM-dd'),
                  endDate: DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
                  timePeriod: 'month',
                })
              }>
              Month
            </Button>
            <Button
              csx={{ minWidth: '100px' }}
              variant={filters.timePeriod === 'year' ? 'active' : 'secondary'}
              onClick={() =>
                setFilters({
                  startDate: DateTime.now()
                    .startOf('year')
                    .toFormat('yyyy-MM-dd'),
                  endDate: DateTime.now().endOf('year').toFormat('yyyy-MM-dd'),
                  timePeriod: 'year',
                })
              }>
              Year
            </Button>
            <Button
              csx={{ minWidth: '100px' }}
              variant={filters.timePeriod === 'custom' ? 'active' : 'secondary'}
              onClick={() =>
                setFilters({
                  ...filters,
                  timePeriod: 'custom',
                })
              }>
              Custom
            </Button>
          </Box>
        </Box>
        <Divider direction="vertical" />
        <Box csx={{ display: 'flex', gap: '10px' }}>
          <Box csx={{ width: '220px' }}>
            <Input
              type="date"
              width={220}
              containerCsx={{
                maxWidth: '230px',
              }}
              onChange={({ currentTarget: { value } }) => {
                setFilters({
                  ...filters,
                  startDate: value,
                });
              }}
              label="Start Date"
              value={startDate}
            />
          </Box>
          <Box csx={{ width: '220px' }}>
            <Input
              type="date"
              width={220}
              containerCsx={{
                maxWidth: '230px',
              }}
              onChange={({ currentTarget: { value } }) => {
                setFilters({
                  ...filters,
                  endDate: value,
                });
              }}
              label="End Date"
              value={endDate}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Filters;

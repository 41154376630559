import { Theme, css } from '@emotion/react';
import { IToastData } from '@westondev/tableturn-core';

import { MQ_MIN_SMALL } from '@app/theme/types';
import Icon from '../../Icon';
import InfoIcon from '../../Icon/custom/InfoIcon';

export const toastTypes = {
  success: {
    background: 'semanticGreen',
    border: 'darkerGreen',
    icon: <Icon name="MdCheck" color="textWhite" size="24px" />,
  },
  info: {
    background: 'semanticBlue',
    border: 'darkBlue',
    icon: <InfoIcon outline color="textWhite" size="18px" />,
  },
  error: {
    background: 'semanticRed',
    border: 'darkerRed',
    icon: <Icon name="MdWarning" color="textWhite" size="24px" />,
  },
  warning: {
    background: 'semanticYellow',
    border: 'darkestYellow',
    icon: <Icon name="MdWarning" color="textWhite" size="24px" />,
  },
};

export const toastStyles = (type: IToastData['type']) => (theme: Theme) =>
  css({
    marginInline: '10px',
    border: `1px solid ${
      theme.colors[toastTypes[type].border as keyof typeof theme.colors]
    }`,
    boxShadow: theme.shadows[1.1],
    backgroundColor:
      theme.colors[toastTypes[type].background as keyof typeof theme.colors],
    borderRadius: '10px',
    padding: '8px',
    zIndex: 9999,
    gap: '15px',
    '.infoContainer': {
      display: 'flex',
      height: '100%',
      gap: '15px',
    },
    width: 'auto',
    userSelect: 'none',
    [MQ_MIN_SMALL]: {
      top: 90,
      right: 15,
      left: 'auto',
      width: '310px',
      marginInline: 0,
    },
  });

export const toastIconStyles = (type: IToastData['type']) => (theme: Theme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '55px',
    minHeight: '55px',
    backgroundColor:
      theme.colors[toastTypes[type].border as keyof typeof theme.colors],
    borderRadius: '10px',
  });

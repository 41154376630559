import Grid from '@app/components/common/Grid';
import ReportCategoriesSubcategoriesFilters from './ReportCategoriesSubcategoriesFilters';
import ReportReportingGroupsFilter from './ReportReportingGroupsFilter';
import ReportOrderTypesFilters from './ReportOrderTypesFilters';

const ReportFiltersSubsection = () => {
  return (
    <Grid rowGap={20} columnGap={20}>
      <ReportCategoriesSubcategoriesFilters />
      <ReportOrderTypesFilters />
      <ReportReportingGroupsFilter />
    </Grid>
  );
};

export default ReportFiltersSubsection;

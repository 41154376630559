import {
  actionCreatorsLocationSettings,
  apiEndpoints,
  EToastTypes,
  generateTabletGeneratedId,
  IDashboardLayout,
  IDashboardLayoutElement,
} from '@westondev/tableturn-core';
import { AppThunk } from '../store';
import {
  ICurrentEditDashboard,
  IDashboard,
  IWidget,
  SET_CURRENT_DASHBOARD,
  SET_CURRENT_EDIT_DASHBOARD,
  SET_CURRENT_EDIT_DASHBOARD_LAYOUT,
  SET_CURRENT_EDIT_DASHBOARD_WIDGET,
  SET_CURRENT_EDIT_SELECTED_DASHBOARD,
  SET_DASHBOARD_BOTTOM_SHEET_OPEN,
} from './types';
import {
  setIsLoadingModal,
  setLoggedInUserAndRole,
  setShowToast,
  showToast,
} from '../app/actions';
import { apiCall } from '@app/helpers/apiCall';
import { CallMethods } from '@app/constants';
import {
  selectLocationId,
  selectOrganizationId,
} from '../selectors/appSelectors';
import { selectDashboards } from '../selectors/settingsSelectors';
import i18n from '../../i18n.config';
import { selectLoggedInRole } from '../users/usersSelectors';

export const setCurrentDashboard = (dashboard: IDashboard | null) => ({
  type: SET_CURRENT_DASHBOARD,
  payload: dashboard,
});

export const setCurrentEditDashboard = (
  currentEditDashboard: ICurrentEditDashboard | null,
) => ({
  type: SET_CURRENT_EDIT_DASHBOARD,
  payload: currentEditDashboard,
});

export const setDashboardBottomSheetOpen = (isOpen: boolean) => ({
  type: SET_DASHBOARD_BOTTOM_SHEET_OPEN,
  payload: isOpen,
});

export const setCurrentEditDashboardLayout = (
  layout: Partial<IDashboardLayout>,
) => ({
  type: SET_CURRENT_EDIT_DASHBOARD_LAYOUT,
  payload: layout,
});

export const setCurrentEditDashboardWidget = (widget: Partial<IWidget>) => ({
  type: SET_CURRENT_EDIT_DASHBOARD_WIDGET,
  payload: widget,
});

export const setCurrentEditSelectedDashboard = (
  selectedDashboard: Partial<IDashboard>,
) => ({
  type: SET_CURRENT_EDIT_SELECTED_DASHBOARD,
  payload: selectedDashboard,
});

export const getDashboard = (dashboardId: number): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();
    const organizationId = selectOrganizationId(state);
    const locationId = selectLocationId(state);
    const locationDashboards = selectDashboards(state);

    if (!organizationId || !locationId) return;
    await apiCall(
      CallMethods.GET,
      apiEndpoints.home.getDashboard(organizationId, locationId, dashboardId),
      true,
    )
      .then(response => {
        const dashboardData: IDashboard = {
          name: locationDashboards[dashboardId].name,
          id: dashboardId,
          layout: response.layout || {},
          widgets: response.widgets || {},
        };
        dispatch(setCurrentDashboard(dashboardData));
      })
      .catch(() => {
        dispatch(
          setShowToast({
            title: 'Error',
            description: 'Error getting dashboard. Please try again.',
            type: 'error',
          }),
        );
      });
  };
};

export const initializeCurrentEditDashboard = (): AppThunk<void> => {
  return (dispatch, getState) => {
    const currentDashboard = getState().home.currentDashboard;
    if (!currentDashboard) return;

    dispatch(
      setCurrentEditDashboard({
        selectedDashboard: currentDashboard,
        isBottomSheetOpen: false,
        selectedElements: [],
      }),
    );
  };
};

export const initializeNewDashboard = (): AppThunk<void> => {
  return dispatch => {
    const dashboardName = 'Untitled';
    const dashboardId = generateTabletGeneratedId();
    dispatch(
      setCurrentDashboard({
        id: dashboardId,
        name: dashboardName,
        layout: {},
        widgets: {},
      }),
    );
    dispatch(
      setCurrentEditDashboard({
        selectedDashboard: {
          id: dashboardId,
          name: dashboardName,
          layout: {},
          widgets: {},
        },
        isBottomSheetOpen: false,
        selectedElements: [],
      }),
    );
  };
};

export const addDashboardElement = (
  newElement: IDashboardLayoutElement,
  neWidget: IWidget,
): AppThunk<void> => {
  return (dispatch, getState) => {
    const currentEditDashboard = getState().home.currentEditDashboard;
    if (!currentEditDashboard) return;

    const newWidgetWidId: IWidget = {
      ...neWidget,
      id: generateTabletGeneratedId(),
    };
    const elements = Object.values(
      currentEditDashboard.selectedDashboard.layout,
    );
    const lastElementId =
      elements.length > 0 ? Number(elements[elements.length - 1].id) : 0;

    const newElementWithId: any = {
      ...newElement,
      id: lastElementId + 1,
      widgetId: newWidgetWidId.id,
    };

    dispatch(
      setCurrentEditSelectedDashboard({
        layout: {
          ...currentEditDashboard.selectedDashboard.layout,
          [newElementWithId.id]: newElementWithId,
        },
        widgets: {
          ...currentEditDashboard.selectedDashboard.widgets,
          [newWidgetWidId.id]: newWidgetWidId,
        },
      }),
    );
  };
};

export const updateDashboardElements = (
  elements: IDashboardLayout,
): AppThunk<void> => {
  return (dispatch, getState) => {
    const currentEditDashboard = getState().home.currentEditDashboard;

    if (!currentEditDashboard) return;

    const currentElements = currentEditDashboard.selectedDashboard.layout;

    dispatch(
      setCurrentEditSelectedDashboard({
        layout: {
          ...currentElements,
          ...elements,
        },
      }),
    );
  };
};

export const removeDashboardElement = (elementId: string): AppThunk<void> => {
  return (dispatch, getState) => {
    const currentEditDashboard = getState().home.currentEditDashboard;
    if (!currentEditDashboard) return;

    const currentElement =
      currentEditDashboard.selectedDashboard.layout[elementId];

    if (!currentElement) {
      return dispatch(
        setShowToast({
          title: 'Error',
          description: 'Error removing element. Please try again.',
          type: 'error',
        }),
      );
    }
    const newLayout = { ...currentEditDashboard.selectedDashboard.layout };
    delete newLayout[elementId];

    const newWidgets = { ...currentEditDashboard.selectedDashboard.widgets };
    if (currentElement.widgetId.toString().includes('-')) {
      delete newWidgets[currentElement.widgetId];
    } else {
      newWidgets[currentElement.widgetId] = null;
    }

    dispatch(
      setCurrentEditSelectedDashboard({
        layout: newLayout,
        widgets: newWidgets,
      }),
    );
  };
};

export const updateWidget = (widget: IWidget): AppThunk<void> => {
  return (dispatch, getState) => {
    const currentEditDashboard = getState().home.currentEditDashboard;
    if (!currentEditDashboard) return;
    const currentWidgets = currentEditDashboard.selectedDashboard.widgets;

    dispatch(
      setCurrentEditSelectedDashboard({
        widgets: {
          ...currentWidgets,
          [widget.id]: {
            ...currentWidgets[widget.id],
            ...widget,
          },
        },
      }),
    );
  };
};

export const createDashboard = (onSuccess: () => void): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();
    const organizationId = selectOrganizationId(state);
    const locationId = selectLocationId(state);
    const role = selectLoggedInRole(state);
    const currentEditDashboard = state.home.currentEditDashboard;
    const dashboards = selectDashboards(state);
    const defaultDashboardId = role.defaultDashboardId;

    if (!currentEditDashboard || !role.roleId) return;

    const layout = Object.values(
      currentEditDashboard.selectedDashboard.layout,
    ).reduce((acc, element) => {
      return {
        ...acc,
        [element.id]: {
          ...element,
          id: element.id.toString().includes('-')
            ? element.id
            : Number(element.id),
        },
      };
    }, {});

    const newDashboard = {
      ...currentEditDashboard.selectedDashboard,
      layout,
      roleId: role.roleId,
    };

    dispatch(
      setIsLoadingModal({
        message: 'Creating Dashboard. Please wait...',
        active: true,
      }),
    );

    await apiCall(
      CallMethods.POST,
      apiEndpoints.home.createUpdateDeleteDashboard(organizationId, locationId),
      true,
      newDashboard,
    )
      .then(response => {
        if (defaultDashboardId === response.id)
          dispatch(
            setCurrentDashboard({
              id: response.id,
              name: response.name,
              layout: response.layout,
              widgets: response.widgets,
            }),
          );
        dispatch(
          actionCreatorsLocationSettings.setDashboards({
            ...dashboards,
            [response.id]: {
              id: response.id,
              name: response.name,
              layout: response.layout,
              organizationId: organizationId,
              locationId: locationId,
            },
          }),
        );

        const newDashboardIds = [...role.dashboardIds, response.id];
        const newDefaultDashboardId = defaultDashboardId
          ? defaultDashboardId
          : newDashboardIds[0];
        dispatch(
          actionCreatorsLocationSettings.setRoleDashboards({
            roleId: role.roleId,
            dashboardIds: newDashboardIds,
            defaultDashboardId: newDefaultDashboardId,
          }),
        );
        dispatch(setLoggedInUserAndRole(role.roleId));
        dispatch(setCurrentDashboard(null));
        dispatch(setCurrentEditDashboard(null));
        dispatch(
          showToast({
            type: EToastTypes.SUCCESS,
            title: 'Dashboard Created',
            description: 'Dashboard has been created successfully.',
            timeout: 4000,
          }) as AppThunk<void>,
        );
        dispatch(
          setIsLoadingModal({
            active: false,
          }),
        );
        onSuccess();
      })
      .catch(() => {
        dispatch(
          setShowToast({
            title: 'Error',
            description: 'Error creating dashboard. Please try again.',
            type: 'error',
          }),
        );
        dispatch(
          setIsLoadingModal({
            active: false,
          }),
        );
      });
  };
};

export const removeDashboard = (onSuccess: () => void): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();
    const organizationId = selectOrganizationId(state);
    const locationId = selectLocationId(state);
    const currentDashboard = state.home.currentDashboard;
    const dashboards = selectDashboards(state);
    const role = selectLoggedInRole(state);
    const roleId = role.id;
    const dashboardIds = role.dashboardIds;
    const defaultDashboardId = role.defaultDashboardId;
    if (!currentDashboard) return;

    const dashboardId = Number(currentDashboard.id);

    dispatch(
      setIsLoadingModal({
        message: 'Removing Dashboard. Please wait...',
        active: true,
      }),
    );
    const url = apiEndpoints.home.createUpdateDeleteDashboard(
      organizationId,
      locationId,
    );
    const body = {
      dashboardId: currentDashboard.id,
    };
    await apiCall(CallMethods.DELETE, url, true, body)
      .then(() => {
        const newDashboards = { ...dashboards };
        delete newDashboards[dashboardId];
        const newDashboardIds = dashboardIds.filter(
          (id: number) => id !== dashboardId,
        );

        const newDefaultDashboardId =
          newDashboardIds.length > 0
            ? newDashboardIds.includes(defaultDashboardId as number)
              ? defaultDashboardId
              : newDashboardIds[0]
            : null;
        dispatch(actionCreatorsLocationSettings.setDashboards(newDashboards));

        dispatch(
          actionCreatorsLocationSettings.setRoleDashboards({
            roleId,
            dashboardIds: newDashboardIds,
            defaultDashboardId: newDefaultDashboardId,
          }),
        );
        dispatch(setLoggedInUserAndRole(role.roleId));
        if (newDashboardIds.length === 0) dispatch(setCurrentDashboard(null));

        dispatch(setCurrentEditDashboard(null));

        dispatch(
          showToast({
            type: EToastTypes.SUCCESS,
            title: 'Dashboard Removed',
            description: 'Dashboard has been removed successfully.',
            timeout: 4000,
          }) as AppThunk<void>,
        );
        onSuccess();
        dispatch(
          setIsLoadingModal({
            active: false,
          }),
        );
      })
      .catch(() => {
        dispatch(
          setShowToast({
            title: 'Error',
            description: 'Error removing dashboard. Please try again.',
            type: 'error',
          }),
        );
        dispatch(
          setIsLoadingModal({
            active: false,
          }),
        );
      });
  };
};

export const updateDashboard = (onSuccess: () => void): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();
    const currentEditDashboard = state.home.currentEditDashboard;
    const organizationId = selectOrganizationId(state);
    const locationId = selectLocationId(state);
    const dashboards = selectDashboards(state);
    const role = selectLoggedInRole(state);
    const roleId = role.id;
    if (!currentEditDashboard || !roleId) return;

    const selectedDashboard = currentEditDashboard.selectedDashboard;

    const layout = Object.values(selectedDashboard.layout).reduce(
      (acc, element) => {
        return {
          ...acc,
          [element.id]: {
            ...element,
            id: element.id.toString().includes('-')
              ? element.id
              : Number(element.id),
          },
        };
      },
      {},
    );
    const body = {
      ...selectedDashboard,
      layout,
      roleId,
    };

    dispatch(
      setIsLoadingModal({
        message: 'Updating Dashboard. Please wait...',
        active: true,
      }),
    );

    await apiCall(
      CallMethods.POST,
      apiEndpoints.home.createUpdateDeleteDashboard(organizationId, locationId),
      true,
      body,
    )
      .then(response => {
        dispatch(
          setCurrentDashboard({
            id: response.id,
            name: response.name,
            layout: response.layout,
            widgets: response.widgets,
          }),
        );
        dispatch(
          actionCreatorsLocationSettings.setDashboards({
            ...dashboards,
            [response.id]: {
              id: response.id,
              name: response.name,
              layout: response.layout,
              organizationId: organizationId,
              locationId: locationId,
            },
          }),
        );
        dispatch(setCurrentEditDashboard(null));
        dispatch(
          showToast({
            type: EToastTypes.SUCCESS,
            title: i18n.t('validations.allChangesSaved'),
            description: i18n.t(
              'settingsModule.tableLayoutCreator.messages.changesSaved',
            ),
            timeout: 4000,
          }) as AppThunk<void>,
        );
        dispatch(
          setIsLoadingModal({
            active: false,
          }),
        );
        onSuccess();
      })
      .catch(() => {
        dispatch(
          setShowToast({
            title: 'Error',
            description: 'Error saving dashboard. Please try again.',
            type: 'error',
          }),
        );
        dispatch(
          setIsLoadingModal({
            active: false,
          }),
        );
      });
  };
};

export const cancelDashboardChanges = (): AppThunk<void> => {
  return dispatch => {
    dispatch(setCurrentEditDashboard(null));
  };
};

import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import CardsContainer from '@app/components/common/CardsContainer';
import Icon from '@app/components/common/Icon';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import Typography from '@app/components/common/Typography';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshSettingsWeb from '@app/hooks/useRefreshSettingsWeb';
import { actionCreatorsSettingsWeb } from '@app/state';
import { selectRoleCardsList } from '@app/state/selectors/settingsSelectors';
import { selectLoggedInRole } from '@app/state/users/usersSelectors';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

interface IRolesSubsection extends WithTranslation {
  roleIds: number[];
  isCreationMode: boolean;
  onAssociate: (roleIds: IItemSelection[]) => void;
  onDisassociate: (id: number) => void;
}

const RolesSubsection = ({
  roleIds,
  onAssociate,
  onDisassociate,
  isCreationMode,
  t,
}: IRolesSubsection) => {
  const { checkForSettingsChangesAndNavigateWeb } = bindActionCreators(
    actionCreatorsSettingsWeb,
    useDispatch(),
  );

  const cardRolesList = useSelector(selectRoleCardsList);
  const loggedInRole = useSelector(selectLoggedInRole);

  const navigate = useNavigateWithOrg();
  const [isEditMode, setEditMode] = useState(false);
  const [openAddExistingRole, setOpenAddExistingRole] = useState(false);

  const userRoles = useMemo(
    () => cardRolesList.filter(role => roleIds.includes(role.id)),
    [cardRolesList, roleIds],
  );

  const handleLocationsAssociation = (selectedRoles: IItemSelection[]) => {
    onAssociate(selectedRoles);
    setOpenAddExistingRole(false);
  };

  return (
    <>
      <SelectionModal
        type="roles"
        onAssociate={handleLocationsAssociation}
        onModalClose={() => setOpenAddExistingRole(false)}
        active={openAddExistingRole}
        allowAssociateMultiple
        showDescriptionPanel={false}
        btnSuccessText={t('commonButtons.associate')}
        showSelectedButtons={true}
      />
      <Card.SubCardOptions
        title={t('settingsModule.tabs.accounts.roles')}
        subtitle={t('settingsModule.usersSettings.description')}
        required
        onEditMode={userRoles.length ? setEditMode : undefined}
        actionOptions={[
          {
            text: 'Add existing Role',
            icon: false,
            handler: () => setOpenAddExistingRole(true),
          },
        ]}>
        <CardsContainer
          data={userRoles}
          noElementsMessage={t(
            'settingsModule.userDetailsSettings.associations.emptyRolesMessage',
          )}
          renderItem={role => {
            const pathURL = `/settings/accounts/roles/${role.id}`;
            return (
              <Card.Item
                isActive={role.active}
                title={role.title}
                showRemoveIcon={
                  (isEditMode && !role.superAdmin) ||
                  (isEditMode && isCreationMode) ||
                  (isEditMode && loggedInRole.superAdmin)
                }
                onClick={openInNewTab => {
                  if (openInNewTab) return openNewTabWithOrgData(pathURL);

                  checkForSettingsChangesAndNavigateWeb(
                    () => navigate(pathURL),
                    useRefreshSettingsWeb,
                    () => null,
                  );
                }}
                onRemoveClick={() => onDisassociate(role.id)}
                isLink={getPathWithOrgData(pathURL)}
                topTag={
                  role.superAdmin ? (
                    <Box
                      csx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '3px',
                      }}>
                      <Icon
                        name="FaCrown"
                        size="12px"
                        color="darkestYellow"
                        csx={{ marginTop: '-1px' }}
                      />
                      <Typography variant="caption2">
                        {t('commonTexts.superAdmin')}
                      </Typography>
                    </Box>
                  ) : undefined
                }
              />
            );
          }}
        />
      </Card.SubCardOptions>
    </>
  );
};

export default RolesSubsection;

import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import Icon from '@app/components/common/Icon';
import MainScreenContainer from '@app/components/common/MainScreenContainer';
import { IMainScreenContainerDropdown } from '@app/components/common/MainScreenContainer/MainScreenContainer';
import Typography from '@app/components/common/Typography';
import {
  EntityTypes,
  getItemCardTag,
} from '@app/helpers/components/itemCardHelpers';
import {
  getPathWithOrgData,
  openNewTabWithOrgData,
} from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { IRoleCard, settingsSelectors } from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const RolesList = ({ t }: WithTranslation) => {
  const navigate = useNavigateWithOrg();

  const originalList = useSelector(settingsSelectors.selectRoleCardsList);

  const dropdownOptions = useMemo(
    () => [
      [{ label: 'All', value: 0 }],
      [
        { label: t('commonTexts.active'), value: 1 },
        { label: t('commonTexts.inactive'), value: 2 },
      ],
    ],
    [t],
  );

  const dropdowns = useMemo(
    (): IMainScreenContainerDropdown<IRoleCard>[] => [
      {
        id: 'activeFilter',
        label: t('commonTexts.status'),
        data: dropdownOptions,
        defaultValue: 1,
        dataExample: 1,
      },
    ],
    [dropdownOptions, t],
  );

  return (
    <MainScreenContainer
      sortAvailable
      data={originalList}
      dropdowns={dropdowns}
      optionList={
        <Button
          variant="primary"
          icon={<Icon name="MdAddCircle" />}
          onClick={() => navigate('/settings/accounts/roles/add')}
        />
      }
      noItemsText={t('commonTexts.noneFound', {
        elementName: t('screenNames.UsersSettingsScreen'),
      })}
      renderItem={role => {
        const pathURL = `/settings/accounts/roles/${role.id}`;
        return (
          <Card.Item
            title={role.title}
            tag={getItemCardTag(EntityTypes.USER, role.count)}
            showStatus
            isActive={role.active}
            onClick={openInNewTab => {
              if (openInNewTab) return openNewTabWithOrgData(pathURL);
              navigate(pathURL);
            }}
            count={role.count}
            topTag={
              role.superAdmin ? (
                <Box
                  csx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                  <Icon
                    name="FaCrown"
                    size="12px"
                    color="darkestYellow"
                    csx={{ marginTop: '-1px' }}
                  />
                  <Typography variant="caption2">
                    {t('commonTexts.superAdmin')}
                  </Typography>
                </Box>
              ) : undefined
            }
            isLink={getPathWithOrgData(pathURL)}
          />
        );
      }}
    />
  );
};

export default RolesList;

import { IDropdownItemCore, IFontDropdownItemCore } from '@westondev/tableturn-core';
import { IDropdownBase, TFontDropdownData } from '../types';

interface IDropdownExtended extends IDropdownBase {
  value?: number;
  caption?: string;
  showCaptionSpace?: boolean;
  labelVariant?: TLabelVariants;
  showOptionsModal?: boolean;
}
interface IFontDropdownExtended extends Omit<IDropdownExtended, 'data'> {
  data: TFontDropdownData;
}

export type TLabelVariants = 'inside' | 'outside';

export enum ELabelVariants {
  INSIDE = 'inside',
  OUTSIDE = 'outside',
}

export type TOnChangeValue = (value: number) => void;
export type TOnChangeItem = (value: IDropdownItemCore) => void;

export type TOnChangeItemFont = (value: IFontDropdownItemCore) => void;

interface OnChangeReturnNumberFont extends IFontDropdownExtended {
  optionsWidth?: number;
  returnItemOnChange?: false;
  onChange: (value: number) => void;
}

interface OnChangeReturnItemFont extends IFontDropdownExtended {
  optionsWidth?: number;
  returnItemOnChange: true;
  onChange: (value: IFontDropdownItemCore) => void;
}

export type IFontsDropdownExtended = OnChangeReturnNumberFont | OnChangeReturnItemFont;

interface OnChangeReturnNumber extends IDropdownExtended {
  returnItemOnChange?: false;
  onChange: (value: number) => void;
}
interface OnChangeReturnItem extends IDropdownExtended {
  returnItemOnChange: true;
  onChange: (value: IDropdownItemCore) => void;
}

export type TSingleSelectDropdown = OnChangeReturnNumber | OnChangeReturnItem;

export type TFontsDropdown = IFontsDropdownExtended;
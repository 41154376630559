import { AnimatePresence, useAnimate, usePresence } from 'framer-motion';
import { ReactNode, useEffect } from 'react';
import Box from '../../Box';
import DisabledBlockLayer from '../../DisabledBlockLayer';
import SectionHeader from './SectionHeader';
import { accordionSectionStyles } from './styles';
import { EAccordionSectionOptionType, IAccordionSection } from './types';

const AccordionSection = ({
  id,
  index,
  title,
  isExpanded = false,
  isExpandable = true,
  description,
  children,
  onToggleExpanded,
  expandedHeight,
  required,
  collapsedHeaderComponent,
  titleTextContainerCsx: titleTextContainerProps,
  variant = 'default',
  hasError = false,
  disabledLayer,
  optionType = EAccordionSectionOptionType.ELLIPSIS,
  options,
  optionsDisabled,
  onOptionsClickDisabled,
  isTitleUpperCase = true,
  noPadding = false,
  isEditButtonDisabled,
  onEditAction,
  onEditClickDisabled,
  info,
  titleContainerCsx,
}: IAccordionSection) => {
  const handleToggleOnClick = () => {
    onToggleExpanded && index !== undefined && onToggleExpanded(index);
  };

  return (
    <Box csx={accordionSectionStyles(variant, noPadding)} id={id}>
      <SectionHeader
        csx={titleContainerCsx}
        isExpandable={isExpandable}
        isExpanded={isExpanded}
        title={title}
        description={description}
        onToggleExpanded={handleToggleOnClick}
        isTitleUpperCase={isTitleUpperCase}
        expandedHeight={expandedHeight}
        required={required}
        collapsedHeaderComponent={collapsedHeaderComponent}
        titleTextContainerCsx={titleTextContainerProps}
        hasError={hasError}
        optionType={optionType}
        options={options}
        optionsDisabled={optionsDisabled}
        onOptionsClickDisabled={onOptionsClickDisabled}
        isEditButtonDisabled={isEditButtonDisabled}
        onEditAction={onEditAction}
        onEditClickDisabled={onEditClickDisabled}
        info={info}
      />
      <AnimatePresence>
        {(isExpanded && isExpandable) || (isExpanded && !isExpandable) ? (
          <AccordionSectionContainer disabledLayer={disabledLayer}>
            {children}
          </AccordionSectionContainer>
        ) : null}
      </AnimatePresence>
    </Box>
  );
};

const AccordionSectionContainer = ({
  children,
  disabledLayer,
}: {
  children: ReactNode;
  disabledLayer?: ReactNode;
}) => {
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          {
            height: 'auto',
          },
          {
            duration: 0,
          },
        );
      };
      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          {
            height: '0',
          },
          {
            duration: 0,
          },
        );
        safeToRemove();
      };
      exitAnimation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent]);

  return (
    <Box ref={scope} className="contentContainer">
      <Box className="content">{children}</Box>
      {disabledLayer && (
        <DisabledBlockLayer>{disabledLayer}</DisabledBlockLayer>
      )}
    </Box>
  );
};

export default AccordionSection;

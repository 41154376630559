import GenericSelectionModal from '../GenericSelectionModal';
import { ICustomSelectionModal } from '../types';

const DashboardsSelectionModal = ({
  t,
  active,
  buttons,
  onModalClose,
  onAssociate,
  allowAssociateMultiple = true,
  showDescriptionPanel = true,
  btnSuccessText,
  showSelectedButtons,
  showSelectAll = true,
  ...others
}: ICustomSelectionModal<unknown>) => {
  return (
    <GenericSelectionModal
      {...others}
      onPress={() => null}
      onAssociate={onAssociate}
      onModalClose={onModalClose}
      active={active}
      buttons={buttons}
      title={t('menuScreen.selectionModal.dashboards.title')}
      selectedSectionTitle={t(
        'menuScreen.selectionModal.dashboards.selectedSectionTitle',
      )}
      emptySectionMessage={t(
        'menuScreen.selectionModal.dashboards.emptySectionText',
      )}
      noCardSelectedMessage={t(
        'menuScreen.selectionModal.dashboards.noCardSelectedText',
      )}
      noAvailableCardsMessage={t(
        'menuScreen.selectionModal.dashboards.noAvailableCardsText',
      )}
      allowAssociateMultiple={allowAssociateMultiple}
      showDescriptionPanel={showDescriptionPanel}
      btnSuccessText={btnSuccessText}
      showSelectedButtons={showSelectedButtons}
      showSelectAll={showSelectAll}
      showCommonFilter={false}
    />
  );
};

export default DashboardsSelectionModal;

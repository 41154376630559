import React, { useMemo } from 'react';
import { format, addDays } from 'date-fns';
import BarChart, { IBarChartProps } from '../BarChart/BarChart';
import { IGraphTimeFilters } from '@app/views/LoggedIn/Home/Widget/types';

interface IBarChartForSTProps extends IBarChartProps {
  filters: IGraphTimeFilters;
}

const BarChartForSalesTrend: React.FC<IBarChartForSTProps> = ({
  data,
  filters,
  width = '100%',
  height = '100%',
  title,
  customLabels,
  color,
  otherOptions,
}) => {
  const customLabelsExtended = useMemo(() => {
    const { timePeriod } = filters;

    const yLabel = customLabels?.yLabel || '';
    const getXLabel = (
      value: string,
      moreData: { label: string; value: number }[],
    ) => {
      if (timePeriod === 'year' || timePeriod === 'custom') {
        const start = moreData.find(item => item.label === 'Start');
        const end = moreData.find(item => item.label === 'End');
        if (!start || !end) return value;
        const startDate = format(start.value, 'MM/dd');
        const endDate = format(end.value, 'MM/dd');
        return `${startDate} - ${endDate}`;
      } else if (timePeriod === 'month') {
        return format(value, ' MMM dd');
      } else {
        const formatToUse =
          timePeriod === 'today' || timePeriod === 'yesterday'
            ? 'h a'
            : 'MMM dd';
        return format(value, formatToUse);
      }
    };

    const xTooltipFormatter = (val: number, { dataPointIndex }: any) =>
      timePeriod === 'year'
        ? `Week ${dataPointIndex + 1}: ${format(val, 'MM/dd/yy')} - ${format(
            addDays(val, 7),
            'MM/dd/yy',
          )}`
        : getXLabel(`${val}`, []);
    const yTooltipFormatter = (
      val: number,
      moreData?: { label: string; value: number }[],
    ) =>
      `${
        yLabel ? `${yLabel}: ` : 'Value: '
      }$${val.toLocaleString()} \n${moreData
        ?.map(item => {
          return `${item.label}: ${
            item.label === 'Start' || item.label === 'End'
              ? format(item.value, 'MM/dd/yyy')
              : item.value
          } \n`;
        })
        .join('')}`;
    const xLabelFormatter = (
      value: string,
      moreData: { label: string; value: number }[],
    ) => {
      return getXLabel(value, moreData);
    };

    return {
      ...customLabels,
      yLabel,
      yLabelFormatter: (val: number) => `$${val.toLocaleString('en-US')}`,
      xTooltipFormatter,
      yTooltipFormatter,
      xLabelFormatter,
    };
  }, [filters, customLabels]);

  return (
    <BarChart
      data={data}
      width={width}
      height={height}
      title={title}
      customLabels={customLabelsExtended}
      color={color}
      showAxisLabels={data.length > 0}
      {...otherOptions}
    />
  );
};

export default BarChartForSalesTrend;

export const DEFAULT_ELEMENT_SIZES = {
  widget: {
    width: 300,
    height: 200,
    maxSize: -1,
  },
};

export type TDataTypes =
  | 'netSales'
  | 'totalRevenue'
  | 'totalDiscount'
  | 'totalSpecials'
  | 'tips'
  | 'voidedItems'
  | 'transactions'
  | 'creditCardType'
  | 'grossSalesGroup'
  | 'netSalesByRole'
  | 'revenueSummary';

export const DATA_TYPES: Record<number, string> = {
  11: 'netSales',
  12: 'totalRevenue',
  13: 'totalDiscount',
  14: 'totalSpecials',
  15: 'tips',
  16: 'voidedItems',
  21: 'transactions',
  22: 'creditCardType',
  23: 'grossSalesGroup',
  24: 'netSalesByRole',
  31: 'revenueSummary',
};

export const DATA_TYPES_NAMES: Record<string, number> = {
  netSales: 11,
  totalRevenue: 12,
  totalDiscount: 13,
  totalSpecials: 14,
  tips: 15,
  voidedItems: 16,
  transactions: 21,
  creditCardType: 22,
  grossSalesGroup: 23,
  netSalesByRole: 24,
  revenueSummary: 31,
};

export const FORM_DATA_INITIAL_STATE: {
  title: string;
  parameters: Record<string, any>;
} = {
  title: '',
  parameters: {
    dataType: DATA_TYPES[11],
    xLabel: '',
    yLabel: '',
    color: '#348DFC',
  },
};

export interface IDashboardFilters {
  timePeriod: TTimePeriodTypes;
  startDate: string;
  endDate: string;
}

export type TTimePeriodTypes =
  | 'today'
  | 'yesterday'
  | 'week'
  | 'month'
  | 'year'
  | 'custom';

export type TDateTypes =
  | 'yesterday'
  | 'today'
  | 'this_week'
  | 'this_month'
  | 'this_year'
  | 'custom';

export const EDateTypes = {
  yesterday: 'yesterday',
  today: 'today',
  week: 'this_week',
  month: 'this_month',
  year: 'this_year',
  custom: 'custom',
};

import { Theme, css } from '@emotion/react';
import { MQ_MIN_MEDIUM } from './types';

export const scrollBarStyles = (theme: Theme, alwaysVisible = true) =>
  css({
    ':hover': {
      '::-webkit-scrollbar': {
        width: '5px',
        height: '5px',
      },
    },
    ...(alwaysVisible && {
      '::-webkit-scrollbar': {
        width: '5px',
        height: '5px',
      },
    }),
    '::-webkit-scrollbar-track': {
      background: theme.colors.lightestExtraGrey,
    },
    '::-webkit-scrollbar-thumb': {
      background: '#d0d0d0',
      borderRadius: '10px',
    },
  });

export const displayFlexRowStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const displayFlexRowCenterStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

export const displayFlexEndColumnStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
});

export const displayFlexColumnStyles = css({
  display: 'flex',
  flexDirection: 'column',
});

export const globalStyles = (theme: Theme) =>
  css({
    '[data-hide-on-mobile="true"]': {
      display: 'none !important',
    },
    '[data-show-on-mobile="true"]': {
      display: 'block !important',
    },
    [MQ_MIN_MEDIUM]: {
      '[data-hide-on-mobile="true"]': {
        display: 'block !important',
      },
      '[data-show-on-mobile="true"]': {
        display: 'none !important',
      },
    },
    '.sketch-picker': {
      input: {
        fontSize: `${theme.fontSizes.body} !important`,
        height: '30px !important',
      },
      label: {
        fontSize: `${theme.fontSizes.body} !important`,
      },
    },
    '.apexcharts-tooltip': {
      border: 'none !important',
      '.apexcharts-tooltip-title': {
        borderBottom: 'none !important',
        background: '#f1f1f1 !important',
      },
      '.apexcharts-tooltip-series-group': {
        whiteSpace: 'break-spaces',
        '.apexcharts-tooltip-text-goals-value, .apexcharts-tooltip-text-y-value, .apexcharts-tooltip-text-z-value':
          {
            fontWeight: '400 !important',
          },
      },
    },
  });

import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import Checkbox from '@app/components/common/Checkbox';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  CLOCK_IN_MODES_OPTIONS,
  EPinPadLayouts,
  IGeneralSettingsStore,
  SettingsSectionId,
  TPinPadLayouts,
  actionCreatorsSettings,
  clockInModeFactory,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const { makeSelectSettingsData } = settingsSelectors;

const SECTION_ID = SettingsSectionId.GENERAL;
const getMemoizedItemData =
  makeSelectSettingsData<IGeneralSettingsStore>(SECTION_ID);

const PIN_PAD_LAYOUT_OPTIONS: TPinPadLayouts[] = [
  EPinPadLayouts.TELEPHONE,
  EPinPadLayouts.CALCULATOR,
];

const GeneralSettings = ({ t }: WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedItemData);

  const clockInModeOptions = useMemo(() => clockInModeFactory(t), [t]);

  const pinPadLayoutOptions = useMemo(
    () => [
      {
        label: t(
          'settingsModule.generalSettings.generalSettings.pinpadLayout.values.telephone',
        ),
        value: PIN_PAD_LAYOUT_OPTIONS.indexOf(EPinPadLayouts.TELEPHONE) + 1,
      },
      {
        label: t(
          'settingsModule.generalSettings.generalSettings.pinpadLayout.values.calculator',
        ),
        value: PIN_PAD_LAYOUT_OPTIONS.indexOf(EPinPadLayouts.CALCULATOR) + 1,
      },
    ],
    [t],
  );

  const pinpadLayoutValue = PIN_PAD_LAYOUT_OPTIONS.indexOf(data?.pinpadLayout);

  return (
    <Grid columnGap={35} rowGap={20}>
      <Grid.Item
        mb={12}
        sm={6}
        md={12}
        lg={6}
        xl={3}
        csx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
        <DetailsSubtitle>
          {t(
            'settingsModule.generalSettings.generalSettings.clockInMode.title',
          )}
        </DetailsSubtitle>
        <Dropdown
          label={t(
            'settingsModule.generalSettings.generalSettings.clockInMode.label',
          )}
          info={t(
            'settingsModule.generalSettings.generalSettings.clockInMode.toolTip',
          )}
          data={[clockInModeOptions]}
          value={CLOCK_IN_MODES_OPTIONS.indexOf(data?.clockInMode) + 1}
          onChange={newClockInMode =>
            setValue<IGeneralSettingsStore>(
              {
                clockInMode: CLOCK_IN_MODES_OPTIONS[newClockInMode - 1],
              },
              SECTION_ID,
            )
          }
        />
      </Grid.Item>
      <Grid.Item
        mb={12}
        sm={6}
        md={12}
        lg={6}
        xl={3}
        csx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
        <DetailsSubtitle>
          {t(
            'settingsModule.generalSettings.generalSettings.pinpadLayout.title',
          )}
        </DetailsSubtitle>
        <Dropdown
          label={t(
            'settingsModule.generalSettings.generalSettings.pinpadLayout.label',
          )}
          placeholder={t('commonTexts.placeholderDropdown')}
          data={[pinPadLayoutOptions]}
          value={pinpadLayoutValue > 0 ? pinpadLayoutValue + 1 : 1}
          onChange={newPinPadLayout =>
            setValue<IGeneralSettingsStore>(
              {
                pinpadLayout: PIN_PAD_LAYOUT_OPTIONS[newPinPadLayout - 1],
              },
              SECTION_ID,
            )
          }
        />
      </Grid.Item>
      <Grid.Item
        mb={12}
        sm={6}
        md={12}
        lg={6}
        xl={3}
        csx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <DetailsSubtitle>
          {t(
            'settingsModule.generalSettings.generalSettings.usersLogin.title',
          )}
        </DetailsSubtitle>
        <Checkbox
          label={t(
            'settingsModule.generalSettings.generalSettings.usersLogin.allowUserToBeLoggedIntoMultipleTablets.title',
          )}
          checked={data?.allowUserToBeLoggedIntoMultipleTablets}
          onChange={newValue =>
            setValue<IGeneralSettingsStore>(
              {
                allowUserToBeLoggedIntoMultipleTablets: newValue,
              },
              SECTION_ID,
            )
          }
        />
      </Grid.Item>
    </Grid>
  );
};

export default GeneralSettings;

import {
  DATA_TYPES_NAMES,
  EDateTypes,
  IDashboardFilters,
} from '@app/components/LoggedIn/Home/DashboardCreator/types';
import { CallMethods } from '@app/constants';
import { apiCall } from '@app/helpers/apiCall';
import {
  selectLocationId,
  selectOrganizationId,
} from '@app/state/selectors/appSelectors';
import { apiEndpoints } from '@westondev/tableturn-core';
import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useWidgetData = (
  widgetId: number | string,
  filters: IDashboardFilters,
  dataType: string,
) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const organizationId = useSelector(selectOrganizationId);
  const locationId = useSelector(selectLocationId);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const widgetIsInvalid = widgetId.toString().includes('-');
    const isNotNetSales = !Object.keys(DATA_TYPES_NAMES).includes(dataType);

    if (widgetIsInvalid || !organizationId || !locationId || isNotNetSales) {
      setLoading(false);
      return;
    }

    setError(null);

    try {
      const url = apiEndpoints.home.getWidget(organizationId, locationId);

      const result = await apiCall(CallMethods.POST, url, true, {
        widgetId,
        timeRange: {
          dateType: EDateTypes[filters.timePeriod],
          startDate: filters.startDate,
          endDate: filters.endDate,
        },
      });

      setData(result);
    } catch (err) {
      setError('Error: ' + err);
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [organizationId, locationId, filters, dataType, widgetId]);

  return { data, loading, error, fetchData };
};

import Box from '@app/components/common/Box';
import LayoutGrid from './LayoutGrid/LayoutGrid';
import { useMemo, useRef } from 'react';
import DashboardBottomSheet from './DashboardBottomSheet/DashboardBottomSheet';
import LayoutContent from '../../LoggedInLayout/LayoutContent';
import { GridStackWidget } from 'gridstack';
import { IGridStackRef } from '@app/components/common/GridStack/types';
import DashboardEmpty from './DashboardEmpty/DashboardEmpty';
import DashboardGridStack from './DashboardGridStack/DashboardGridStack';
import { shallowEqual, useSelector } from 'react-redux';
import {
  selectCurrentDashboard,
  selectCurrentEditDashboard,
} from '@app/state/selectors/homeSelectors';
import {
  addDashboardElement,
  removeDashboardElement,
} from '@app/state/home/actions';
import { IWidget, IWidgets } from '@app/state/home/types';
import { useAppDispatch } from '@app/state/store';
import { IDashboardLayoutElement } from '@westondev/tableturn-core';
import Widget from '@app/views/LoggedIn/Home/Widget';
import { IDashboardFilters } from './types';

interface IDashboardCreator {
  filters: IDashboardFilters;
  enableEdit?: boolean;
  isPreviewMode?: boolean;
  hasNoDashboard?: boolean;
  onEditDashboardClick?: () => void;
  onAddNewDashboard?: () => void;
}
const DashboardCreator = ({
  filters,
  enableEdit = false,
  isPreviewMode = false,
  hasNoDashboard = false,
  onEditDashboardClick,
  onAddNewDashboard,
}: IDashboardCreator) => {
  // Redux
  const dispatch = useAppDispatch();
  const currentDashboard = useSelector(selectCurrentDashboard, shallowEqual);

  const currentEditDashboard = useSelector(selectCurrentEditDashboard);
  const containerRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<IGridStackRef | null>(null);

  const elements = useMemo(() => {
    const returnWidget = (
      widgets: IWidgets,
      element: IDashboardLayoutElement,
    ) => {
      const widgetId = element.widgetId;
      const widget = widgets[widgetId];
      if (!widget) return <></>;

      return (
        <Widget
          key={widgetId}
          widgetId={widgetId}
          filters={filters}
          widgetType={widget.type}
          title={widget.title}
          parameters={widget.parameters}
        />
      );
    };

    if (enableEdit && !isPreviewMode && currentEditDashboard) {
      return Object.values(currentEditDashboard.selectedDashboard.layout).map(
        element => ({
          ...element,
          id: element.id.toString(),
          content: returnWidget(
            currentEditDashboard.selectedDashboard.widgets,
            element,
          ) as any,
        }),
      );
    } else if (!enableEdit && currentDashboard) {
      return Object.values(currentDashboard.layout).map(element => ({
        ...element,
        id: element.id.toString(),
        content: returnWidget(currentDashboard.widgets, element) as any,
      }));
    }
    return [];
  }, [
    currentDashboard,
    currentEditDashboard,
    enableEdit,
    isPreviewMode,
    filters,
  ]);

  const handleCreateNewElement = ({
    element,
    widget,
  }: {
    element: GridStackWidget;
    widget: IWidget;
  }) => {
    dispatch(addDashboardElement(element as IDashboardLayoutElement, widget));
  };

  const handleEditElement = (element: GridStackWidget | null) => {
    console.info('Edit element', element);
  };

  const handleRemoveElement = (elementId: string) => {
    dispatch(removeDashboardElement(elementId));
  };

  return (
    <>
      <LayoutContent noPadding>
        <Box
          csx={{
            width: '100%',
            height: '100%',
            padding: hasNoDashboard ? '15px' : '15px 7.5px',
          }}>
          <Box
            id="dashboardCreatorContainer"
            ref={containerRef}
            csx={theme => ({
              width: '100%',
              minHeight: '100%',
              position: 'relative',
              borderRadius: '8px',
              overflow: 'hidden',
              boxShadow: hasNoDashboard ? theme.shadows[1] : 'none',
            })}>
            <>
              {enableEdit && <LayoutGrid height={0} />}
              {!hasNoDashboard && (
                <DashboardGridStack
                  ref={gridRef}
                  elements={elements}
                  onEditPress={handleEditElement}
                  onRemoveElement={handleRemoveElement}
                  enableEdit={enableEdit}
                />
              )}
              <DashboardEmpty
                elements={elements}
                enableEdit={enableEdit}
                hasNoDashboard={hasNoDashboard}
                onEditDashboardClick={onEditDashboardClick}
                onAddNewDashboard={onAddNewDashboard}
              />
            </>
          </Box>
        </Box>
      </LayoutContent>
      {enableEdit && (
        <DashboardBottomSheet onCreateNewElement={handleCreateNewElement} />
      )}
    </>
  );
};

export default DashboardCreator;

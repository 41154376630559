import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import ColorPicker from '@app/components/common/ColorPicker';
import Dropdown from '@app/components/common/Dropdown';
import Input from '@app/components/common/Input';
import Modal from '@app/components/common/Modal';
import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import { ParseKeys } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EDashboardModes, IWidget, TWidgetTypes } from '@app/state/home/types';
import { DATA_TYPES_NAMES, FORM_DATA_INITIAL_STATE } from '../types';
import Typography from '@app/components/common/Typography';
import {
  getInitialDataByDataType,
  getWidgetInitialFormData,
} from '@app/helpers/Home/home';

interface IWidgetCreateOrEditModal {
  isActive: boolean;
  onModalClose: () => void;
  widgetType: TWidgetTypes | null;
  mode: 'create' | 'edit';
  onCreateWidget?: (newWidget: IWidget) => void;
  onUpdatedWidget?: (updatedWidget: IWidget) => void;
  currentData?: IWidget;
}

const WidgetCreateOrEditModal = ({
  isActive,
  onModalClose,
  widgetType,
  mode,
  onCreateWidget,
  onUpdatedWidget,
  currentData,
}: IWidgetCreateOrEditModal) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState(FORM_DATA_INITIAL_STATE);
  const showBarChartLabels = false;

  useEffect(() => {
    const initialFormData = getWidgetInitialFormData(
      mode,
      widgetType,
      currentData,
    );
    if (Object.keys(initialFormData).length === 0) return;
    setFormData(initialFormData);
  }, [currentData, mode, widgetType]);

  const modalTitle = useMemo(() => {
    const widgetTypeName = t(
      `loggedIn.dashboardsModule.widgetTypes.${widgetType}` as ParseKeys,
    );
    const createOrEdit = `loggedIn.dashboardsModule.${
      mode === EDashboardModes.EDIT ? 'editWidget' : 'createWidget'
    }` as ParseKeys;
    return t(createOrEdit, {
      widgetType: widgetTypeName,
    });
  }, [widgetType, t, mode]);

  const handleAddWidget = () => {
    if (!widgetType) return;
    let newWidget: IWidget = {} as IWidget;
    if (widgetType === 'barChart') {
      newWidget = {
        id: '0',
        title: formData.title,
        type: widgetType,
        parameters: formData.parameters,
      };
    } else {
      newWidget = {
        id: '0',
        title: formData.title,
        type: widgetType,
        parameters: formData.parameters,
      };
    }

    onCreateWidget && onCreateWidget(newWidget);
    onModalClose();
    setFormData(FORM_DATA_INITIAL_STATE);
  };

  const handleUpdateWidget = () => {
    if (!widgetType || !currentData) return;

    const updatedWidget: IWidget = {
      id: currentData.id,
      title: formData.title,
      parameters: formData.parameters,
      type: widgetType,
    };
    onUpdatedWidget && onUpdatedWidget(updatedWidget);
    onModalClose();
    setFormData(FORM_DATA_INITIAL_STATE);
  };

  const dropdownData =
    widgetType === 'barChart'
      ? [
          [
            { label: 'Net Sales', value: 11 },
            { label: 'Total Revenue', value: 12 },
            { label: 'Total Discounts', value: 13 },
            { label: 'Total Specials', value: 14 },
            { label: 'Tips', value: 15 },
            { label: 'Voided Items', value: 16 },
          ],
        ]
      : widgetType === 'pieChart'
      ? [
          [
            { label: 'Transactions By Type', value: 21 },
            { label: 'Credit Card By Type', value: 22 },
            { label: 'Gross Sales by Reporting Group', value: 23 },
            { label: 'Net Sales By Role', value: 24 },
          ],
        ]
      : [[{ label: 'Revenue Summary', value: 31 }]];

  const optionSelected = useMemo(() => {
    return DATA_TYPES_NAMES[formData.parameters.dataType];
  }, [formData]);

  return (
    <Modal
      isActive={isActive}
      size={500}
      title={modalTitle}
      onModalClose={onModalClose}
      footer={
        <Button
          variant="primary"
          csx={{ width: '100px' }}
          onClick={() => {
            if (!currentData) {
              handleAddWidget();
            } else {
              handleUpdateWidget();
            }
          }}>
          {!currentData ? t('commonButtons.create') : t('commonButtons.save')}
        </Button>
      }>
      <Box csx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Box>
          <DetailsSubtitle>Widget Settings</DetailsSubtitle>

          <Box csx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <Input
              label="Title"
              placeholder="Insert title"
              value={formData.title}
              onChange={({ currentTarget: { value: title } }) => {
                setFormData({ ...formData, title });
              }}
            />
            <Dropdown
              label="Data Type"
              data={dropdownData}
              value={optionSelected}
              onChange={dataTypeValue => {
                setFormData(prev => ({
                  ...prev,
                  ...getInitialDataByDataType(dataTypeValue),
                }));
              }}
            />
          </Box>
        </Box>
        <Box>
          {widgetType === 'barChart' || widgetType === 'pieChart' ? (
            <DetailsSubtitle>Chart Settings</DetailsSubtitle>
          ) : (
            <DetailsSubtitle>Settings</DetailsSubtitle>
          )}

          <Box csx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            {widgetType === 'barChart' ? (
              <>
                {showBarChartLabels && (
                  <>
                    <Input
                      label="X Label"
                      placeholder="Insert X label"
                      value={formData.parameters.xLabel}
                      onChange={({ currentTarget: { value: xLabel } }) => {
                        setFormData(prev => ({
                          ...prev,
                          parameters: {
                            ...prev.parameters,
                            xLabel,
                          },
                        }));
                      }}
                    />
                    <Input
                      label="Y Label"
                      placeholder="Insert Y label"
                      value={formData.parameters.yLabel}
                      onChange={({ currentTarget: { value: yLabel } }) => {
                        setFormData(prev => ({
                          ...prev,
                          parameters: {
                            ...prev.parameters,
                            yLabel,
                          },
                        }));
                      }}
                    />
                  </>
                )}
                <ColorPicker
                  label="Chart color"
                  color={formData.parameters.color}
                  onChange={color => {
                    setFormData(prev => ({
                      ...prev,
                      parameters: {
                        ...prev.parameters,
                        color,
                      },
                    }));
                  }}
                />
              </>
            ) : widgetType === 'pieChart' ? (
              <Box
                csx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}>
                <Typography fontWeight="medium">Slices</Typography>
                {formData.parameters.slices?.map(
                  (slice: { label: string; color: string }, index: number) => (
                    <Box
                      key={`slice-${index}`}
                      csx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                      }}>
                      <Input
                        placeholder="Insert X label"
                        disabled
                        value={slice.label}
                        onChange={({ currentTarget: { value: xLabel } }) => {
                          setFormData(prev => ({
                            ...prev,
                            parameters: {
                              ...prev.parameters,
                              slices: prev.parameters.slices.map(
                                (s: any, i: any) => {
                                  if (i === index) {
                                    return {
                                      ...s,
                                      label: xLabel,
                                    };
                                  }
                                  return s;
                                },
                              ),
                            },
                          }));
                        }}
                      />
                      <ColorPicker
                        color={slice.color}
                        onChange={color => {
                          setFormData(prev => ({
                            ...prev,
                            parameters: {
                              ...prev.parameters,
                              slices: prev.parameters.slices.map(
                                (s: any, i: any) => {
                                  if (i === index) {
                                    return {
                                      ...s,
                                      color,
                                    };
                                  }
                                  return s;
                                },
                              ),
                            },
                          }));
                        }}
                      />
                    </Box>
                  ),
                )}
              </Box>
            ) : (
              <>
                <Input
                  placeholder="Service Charge to Restaurant"
                  disabled
                  value=""
                  onChange={() => null}
                />
                <Input
                  placeholder="Service Charge as Tip"
                  disabled
                  value=""
                  onChange={() => null}
                />
                <Input
                  placeholder="Tips"
                  disabled
                  value=""
                  onChange={() => null}
                />
                <Input
                  placeholder="Tax Collected"
                  disabled
                  value=""
                  onChange={() => null}
                />
                <Input
                  placeholder="Total Amount"
                  disabled
                  value=""
                  onChange={() => null}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default WidgetCreateOrEditModal;
